import React from "react";
import '../css/bootstrap.css';
import { Row, Col, Form, FormGroup, Input, Button } from 'reactstrap';
//import { MDBFooter } from "mdbreact";
import Const from '../components/Const';
import Nav from '../components/nav';
import * as RiIcons from 'react-icons/ri';
import * as BsIcons from "react-icons/bs";
import { invokeService, validatePasswordStrength, deleteLocalCookie, deleteAllButTokenLocalCookies, getUserJsonFromDBwithCookie, 
         messageModalWindow, sleepingCat } from './Utils.js';
import { isiPhone } from '../utils/AppUtils.js';

const initialState = {
	password: "",
	passwordError: "",
	hide : true, 
	ocaLoginUser : null,
	messageModal : false,
	deletionConfiramtion : '',
	confirmationOpacity : Const.REDUCED_OPACITY,
	passwordOpacity : Const.REDUCED_OPACITY,
	disable : false,
	//
	serviceInvocationStatus : Const.SERVICE_IS_NOT_INVOKED_YET,
	serviceStatusCode : Const.INIT_VALUE,
	serviceErrorLevel : Const.NO_ERROR_LEVEL,
	serviceInvocationError : false,
	flag : Const.INIT_VALUE
};

export default class DeleteAccount extends React.Component {
  	constructor(props) {
		super(props);
		this.state = initialState;
		this.handleConfirmatiOnChange = this.handleConfirmatiOnChange.bind(this);
		this.handlePasswordOnChange = this.handlePasswordOnChange.bind(this);
		this.onFocusHandler = this.onFocusHandler.bind(this);
        this.onBlurHandler = this.onBlurHandler.bind(this);
  	}

	handleConfirmatiOnChange(e) {
		let deletionConfiramtion = e.target.value.trim();
		let confirmationOpacity = (deletionConfiramtion.length > 0) ? Const.FULL_OPACITY : Const.REDUCED_OPACITY;
		let password = '';
		let passwordOpacity = this.state.passwordOpacity;

		if (deletionConfiramtion === 'delete') {
			password = this.state.password;
		}
		else {
			passwordOpacity = Const.REDUCED_OPACITY;
		}

		this.setState({  deletionConfiramtion : deletionConfiramtion,  
								confirmationOpacity : confirmationOpacity,
								password : password, passwordOpacity : passwordOpacity });
	}

	handlePasswordOnChange(e) {
		let password = e.target.value.trim();
		let passwordOpacity = (password.length > 0) ? Const.FULL_OPACITY : Const.REDUCED_OPACITY;

		this.setState({ password : password,  passwordOpacity : passwordOpacity, passwordError : "" });
	}
  
	hideSwitch = ev => {
		//password show hide
		this.setState({ hide: !this.state.hide })
	}

	validate = () => {
		let passwordError = "";
		let ret;
		let passwordStrength = validatePasswordStrength(this.state.password);

		if (passwordStrength < Const.GOOD){
			passwordError = Const.VALID_PASSWORD_REQUIRED;
			this.setState({ passwordError });
			ret = false;
		}
		else {
			ret = true;
		}
		return ret;
	};

	handleSubmit = async event => {
		let isValid;

		event.preventDefault();
		isValid = this.validate();
		if (isValid) {
			this.toggleDisableEnable();
			deleteAllButTokenLocalCookies();
			await this.deleteAccountInDB();
			this.toggleDisableEnable();
			if (this.state.serviceErrorLevel === Const.NO_ERROR_LEVEL) {
				deleteLocalCookie(Const.DELETE_TOKEN_COOKIE);
				window.location = '/';
			}
			else if (this.state.flag === Const.TOO_MANY_TRIES) {
			this.setState({ 
				passwordError : Const.TOO_MANY_TRIES_NEED_SIGN_OUT_MESSAGE,
				serviceInvocationStatus : Const.SERVICE_IS_NOT_INVOKED_YET
			});
			} 
			else if (this.state.serviceErrorLevel === Const.USER_ERROR_LEVEL) {
			this.setState({ 
				passwordError : Const.INVALID_PASSWORD,
				serviceInvocationStatus : Const.SERVICE_IS_NOT_INVOKED_YET
			});
			}
		}
	};

	async componentDidMount() {
		let ocaLoginUser = this.getOcaUserFromPropsParamsOrState();
		let result;

		if (!ocaLoginUser) {
				result = await  getUserJsonFromDBwithCookie(this);
				if ((this.state.serviceErrorLevel === Const.NO_ERROR_LEVEL)) {
					ocaLoginUser = result;
				}
			}
		if (ocaLoginUser) {
			this.setState({ ocaLoginUser : ocaLoginUser, serviceInvocationStatus : Const.SERVICE_IS_NOT_INVOKED_YET });
		}
	}

	getOcaUserFromPropsParamsOrState() {
		let ocaLoginUser;
	
		if (this.props.location.params) {
			ocaLoginUser = this.props.location.params.ocaLoginUser;
		}
		else if (this.state.ocaLoginUser) {
			ocaLoginUser = this.state.ocaLoginUser;
		}
		else {
			ocaLoginUser = null;
		}
		return ocaLoginUser;
	}

	async deleteAccountInDB() {
		let  data = {
				// Delete account service
				"password": this.state.password,
				"mode" : Const.DELETE_OCA_USER_ACCOUNT_MODE
				};

		await invokeService(Const.LOGIN_URL, data, this);
	}

	toggleDisableEnable() { 
		this.setState({disable : !this.state.disable})
	}

	onFocusHandler(e) {
		if (isiPhone()) {
		  // iPhone doesn't honor fixed navigation bar, so we remove it when typing
		  e.preventDefault();
		  this.setState({ showNav : false})
		}
	  }
	
	onBlurHandler(e) {
		if (isiPhone()) {
			e.preventDefault();
			this.setState({ showNav : true})
		}
	}

	render() {
		const { title } = this.props; //password show hide
		const { serviceInvocationError, serviceInvocationStatus, serviceErrorLevel, serviceStatusCode, 
				hide, ocaLoginUser, confirmationOpacity, passwordOpacity, deletionConfiramtion, disable } = this.state;
		let className = "after-app-wrap-center-div-gotop";
    
		if (isiPhone()) {
		   className += " posFixed";
		}

		if (ocaLoginUser) {
			ocaLoginUser.whereIam = Const.I_AM_IN_DELETE_ACCOUNT;
		}
		else {
			return (
				<> 
					{sleepingCat()}
				</>
			);
		}
		if (serviceErrorLevel === Const.USER_ERROR_CRITICAL_LEVEL) {
			return (
			  messageModalWindow(this, this.state.header, this.state.text)
			)
		}
		else if (serviceInvocationError || (serviceErrorLevel === Const.SYS_ERROR_LEVEL)) {
			return (
					messageModalWindow(this, this.state.header, this.state.text)
				   )
		} 
		else if (serviceStatusCode === Const.NOT_CRITICALLY_BAD_COOKIE_WAS_SENT) {
			return <div>{Const.BAD_COOKIE_MESSAGE_CLOSE_BROWSER}</div>;
		} 
		// else if (serviceInvocationStatus !== Const.SERVICE_IS_NOT_INVOKED_YET) {
		//   return <div>{Const.DELETING_USER_ACCOUNT}</div>;
		// } 
		else if (serviceInvocationStatus !== Const.SERVICE_IS_NOT_INVOKED_YET) {
			return (
				<> 
					{sleepingCat()}
				</>
			);
		} 
		else {
			return (
			 <>
			   <Nav loginUser = { ocaLoginUser } />
					<div id="main" className={ className }>				
						<Form onSubmit={this.handleSubmit}>
							<FormGroup className="account-form">
								<Row className="delete-account">
									<Col className="center" md={2} sm={12} xs={12}>
									</Col>
									<Col className="profile-info"  md={5} sm={12} xs={12}>
									<h3>Delete Account</h3>
									<div className="usern">
										<p className="mb-0 font-small">User name:</p>
										<h5 className="pt-0"> { ocaLoginUser.userName }</h5>
									</div>

									<p>To confirm deletion, type "delete" in the box below</p>
									<div className="login-form-row">
										{
											disable
											? <Input disabled style={{opacity : Const.REDUCED_OPACITY}}  className="x-input"
												placeholder="delete" 
											/>
											: <Input style={{opacity : confirmationOpacity}}  className="x-input"
												placeholder="delete" 
												required    
												onFocus={this.onFocusHandler} 
										        onBlur={this.onBlurHandler} 
												onChange={this.handleConfirmatiOnChange}  
											/>
										}
										
									</div>

									<div className="login-form-row">
										<label className="password">{title} 
											{
												(deletionConfiramtion !== 'delete') || disable
												?  <Input disabled style={{opacity : Const.REDUCED_OPACITY}}  className="x-input"
														placeholder="Password" 
														value={this.state.password}
													/>
												:  <Input style={{opacity : passwordOpacity}}  className="x-input"
														placeholder="Password" 
														maxLength = {20}
														value={this.state.password}
														type={hide ? 'password' : 'input'} 
														required    
														onFocus={this.onFocusHandler} 
										                onBlur={this.onBlurHandler} 
														onChange={this.handlePasswordOnChange}  
													/>
											}

											{
												(deletionConfiramtion !== 'delete') || disable
												? <div className="eye"  style={{opacity : Const.REDUCED_OPACITY}}>
														{hide ? <RiIcons.RiEyeCloseLine /> : <BsIcons.BsEye />}
													</div>
												:  <div className="eye"  style={{opacity : passwordOpacity}} onClick={this.hideSwitch}>
														{hide ? <RiIcons.RiEyeCloseLine /> : <BsIcons.BsEye />}
												</div>
											}
											<div className = "error-message">
											<span>{this.state.passwordError}</span>
											</div>
										</label>
									</div>
									
									{
										(this.state.password.length === 0) || disable
										? <Button  color ="info" className="pointer-noview" disabled = { true } >Delete Account</Button>
										: <Button  color ="info" type="Submit">Delete Account</Button>
									}
									</Col>
									<Col  md={5} sm={12} xs={12}></Col>
								</Row>
							</FormGroup>
						</Form>
				</div>
			 </>
			);
		}
	}
}