import React from "react";
import '../App.css';
import { Row } from 'reactstrap';
import {BrowserRouter as Router} from 'react-router-dom';
import Consierge from '../images/consierge-greet-avatar.png';
import Ptichka from '../images/ptichka2.jpg';


export function getUserPictureClassName(obj) {
	let avatarClassName = (obj.userPictureType === '0') ? 'avatar-small-landscape' : 'avatar-small-portrait';
 
	return avatarClassName;
}

export default class AiConcierge extends React.Component {
	render() {		
		return (
			<Router>
				<div className="ai-concierge">
					<div className="ai-content">
						<div className="profile-info ai-consierge-response" >
							<Row className="row-margin-0 center mb-3">							
								<div className="avatar-big ">
									<img src={ Consierge } style={{width:'100%'}} className="" alt="Avatar" />
								</div>  
															
								<div className="pl-3">
									<h4 className="ml-0 mb-0 bold post-username">VipConcierge</h4>
								</div>
							</Row>

							<Row className="ai-row">
								{/* <div className={getUserPictureClassName(loginUser)}> */}
								<div className="avatar-small-landscape">
									{/* <img src= {loginUser.userPicture} className="" alt="Avatar" /> */}
									<img src= { Ptichka } className="" alt="Avatar" />
								</div>  
								<div className="ai-response"></div>
							</Row>

							<Row className="ai-row">
								<div className="avatar-small-landscape">
									<img src= { Consierge } className="" alt="Avatar" />
								</div>  
								<div className="user-response"></div>
							</Row>
							<Row className="ai-row">
								<div className="avatar-small-landscape">
									<img src= { Ptichka} className="" alt="Avatar" />
								</div>  
								<div className="ai-response"></div>
							</Row>
							<Row className="ai-row">
								<div className="avatar-small-landscape">
									<img src= { Consierge } className="" alt="Avatar" />
								</div>  
								<div className="user-response"></div>
							</Row>
							<Row className="ai-row">
								<div className="avatar-small-landscape">
									<img src= { Ptichka} className="" alt="Avatar" />
								</div>  
								<div className="ai-response"></div>
							</Row>
							<Row className="ai-row">
								<div className="avatar-small-landscape">
									<img src= { Consierge } className="" alt="Avatar" />
								</div>  
								<div className="user-response"></div>
							</Row>
							<Row className="ai-row">
								<div className="avatar-small-landscape">
									<img src= { Ptichka} className="" alt="Avatar" />
								</div>  
								<div className="ai-response"></div>
							</Row>
							<Row className="ai-row">
								<div className="avatar-small-landscape">
									<img src= { Consierge } className="" alt="Avatar" />
								</div>  
								<div className="user-response"></div>
							</Row>
							<Row className="ai-row">
								<div className="avatar-small-landscape">
									<img src= { Ptichka} className="" alt="Avatar" />
								</div>  
								<div className="ai-response"></div>
							</Row>
							<Row className="ai-row">
								<div className="avatar-small-landscape">
									<img src= { Ptichka} className="" alt="Avatar" />
								</div>  
								<div className="ai-response"></div>
							</Row>
						</div>
					</div>

					<div className="ai-input">
						<input name="loginName" placeholder="Ask Vip Concierge anything ot create your post" 
						required="" type="text" className="form-control "></input>
					</div>				
				</div>			
			</Router>
			);
		}
	}
