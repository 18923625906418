import React from "react";
import { Redirect } from 'react-router-dom';
import Const from './Const';
import { invokeService, getLocalCookieKeyName, getAppCookie, sleepingCat, messageModalWindow,
         setCloseYourBrowserMessage, deleteAllLocalCookies } from './Utils.js';
import { getLoginUserJson } from '../components/getData/GetLoginUserJson';
import { hideSplashScreenForApp } from '../utils/CapacitorUtils';

export default class StartLogin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loginUser : null,
      header: '',
			text: '',  
      messageModal: false,   
      serviceInvocationStatus : Const.SERVICE_IS_NOT_INVOKED_YET,
      serviceStatusCode : Const.INIT_VALUE,
      serviceErrorLevel : Const.NO_ERROR_LEVEL,
      serviceInvocationError : false,
      flag : Const.INIT_VALUE
    };
  }

  async componentDidMount() {
    let  data = {
     // Starter service : determin if a user is already logged in or not
     "mode" : Const.STARTER_MODE
    }
    let cookieKey = getLocalCookieKeyName();
    let userName = await getAppCookie(cookieKey);
    
    await hideSplashScreenForApp();
    if (userName) {
      getLoginUserJson(this, userName);
    }
    else {
      deleteAllLocalCookies();
      await invokeService(Const.LOGIN_URL, data, this);
      if (this.state.serviceErrorLevel !== Const.NO_ERROR_LEVEL) {
          setCloseYourBrowserMessage(this);
          this.setState({ flag : Const.STOP_PROCESSING_WITH_ERROR }); // this is to issue the error message
      }
    }
  }

  render() {
    const { serviceInvocationError, serviceErrorLevel, flag, loginUser,  isUserComplete} = this.state;
    const invokedByRoot = (document.referrer.indexOf('signout') === -1) ? true : false;
 
    if (serviceErrorLevel === Const.USER_ERROR_CRITICAL_LEVEL) {
      return (
        messageModalWindow(this, this.state.header, this.state.text)
      )
    }
    else if (serviceInvocationError || (serviceErrorLevel === Const.SYS_ERROR_LEVEL) || 
            (flag ===  Const.STOP_PROCESSING_WITH_ERROR)) {
      return (
        messageModalWindow(this, this.state.header, this.state.text)
      )
    } 
    else if (flag ===  Const.CONTINUE_WITH_USER_VALIDATION) {
      return (
         <Redirect to={{ pathname: "/login", params: { loginUser } }} />
      )
    }
    else if (loginUser) {
      return (
        isUserComplete 
        ?  <Redirect to={{ pathname: "/Home", params: { loginUser } }} />
        :  <Redirect to={{ pathname: "/Profile", params: { loginUser } }} />
      )
		} 
    else if (invokedByRoot) {
			return sleepingCat();
		} 
    else {
      return null;
    }
  }
}