import { getLocalCookieForOcaPreferences } from '../components/Utils.js';

const matchPrefersLight = window.matchMedia && window.matchMedia('(prefers-color-scheme : light)');

export const DEFAULT = 1;
export const DARK = 2;
export const LIGHT = 3;

export function setTheme(themeMode) {
  let themeAttribute;

  if(themeMode === LIGHT) {
    themeAttribute = 'light';
  }
  else if (themeMode === DARK) {
    themeAttribute = 'dark';
  }
  else if (themeMode === DEFAULT) {
    if (matchPrefersLight) {
      if (matchPrefersLight.matches) {
        themeAttribute = 'light';
      }
      else {
        themeAttribute = 'dark';
      }
    }
    else {
      // something very old
      themeAttribute = 'light';
    }
  }
  document.documentElement.setAttribute('data-bs-theme', themeAttribute);
}

export function setThemePreference(theme) {
  setTheme(theme);
  if (theme === DEFAULT) {
    addThemeChangeListener();
  }
  else {
    removeThemeChangeListener();
  }
}

export async function setThemeFromCookie() {
  let theme = await getLocalCookieForOcaPreferences();

  if (!theme) {
    theme = DEFAULT;
  }
  setThemePreference(theme);
}



export function addThemeChangeListener() {
  if (matchPrefersLight) {
    removeThemeChangeListener();
    matchPrefersLight.addEventListener('change', themeChangeListener);
  }
}

export function removeThemeChangeListener() {
  if (matchPrefersLight) {
    matchPrefersLight.removeEventListener('change', themeChangeListener);
  }
}

export function themeChangeListener(e) {
  document.documentElement.setAttribute('data-bs-theme', e.matches ? "light" : "dark");
}