import React from "react";
import '../css/bootstrap.css';
import {Button, Form, FormGroup, Input} from 'reactstrap';
// import Logo from "../images/cp-png-transparent-64x64.png";
import Envir from '../components/Envir';
import Const from '../components/Const';
import { invokeService, validateEmail, messageModalWindow } from './Utils.js';

const initialState = {
  email: "",
  emailError: "",
  messageModal : false,
  //
  serviceInvocationStatus : Const.SERVICE_IS_NOT_INVOKED_YET,
  serviceStatusCode : Const.INIT_VALUE,
  serviceErrorLevel : Const.NO_ERROR_LEVEL,
  serviceInvocationError : false,
  flag : Const.INIT_VALUE
};

export default class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  handleChange = event => {
    this.setState({
      [event.target.name] : event.target.value.trim(),
      emailError: ""
    });
  };

  validate = () => {
    let emailError = "";
    let ret;
    let isEmailValid = validateEmail(this.state.email);

    if (!isEmailValid) {
      emailError = Const.VALID_EMAIL_REQUIRED;
      ret = false;
    }
    else {
      ret = true;
    }
    this.setState({ emailError });
    return ret; 
  }

  handleSubmit = async event => {
    let isValid;

    event.preventDefault();
    isValid = this.validate();
    if (isValid) {
      await this.sendCodeUsingDB();
      if (this.state.serviceErrorLevel === Const.NO_ERROR_LEVEL) {
        window.location = Envir.LOGIN_FOLDER + 'entercode';
      }
      else if (this.state.flag === Const.TOO_MANY_TRIES) {
        this.setState({ 
          emailError : Const.TOO_MANY_TRIES_NEED_CLOSE_BROWSER_MESSAGE,
          serviceInvocationStatus : Const.SERVICE_IS_NOT_INVOKED_YET
        });
      } 
      else if (this.state.serviceErrorLevel === Const.USER_ERROR_LEVEL) {
        this.setState({
          emailError : Const.INVALID_EMAIL,
          serviceInvocationStatus : Const.SERVICE_IS_NOT_INVOKED_YET
       });
      }
    }
  };

  async sendCodeUsingDB() {
    let  data = {
          // Send code
          "email" : this.state.email,
          "mode" : Const.SEND_CODE_MODE
         };
         
    await invokeService(Const.LOGIN_URL, data, this);
  }

  render() {
    const { serviceInvocationError, serviceErrorLevel, serviceInvocationStatus, serviceStatusCode } = this.state;
    
    if (serviceErrorLevel === Const.USER_ERROR_CRITICAL_LEVEL) {
			return (
			  messageModalWindow(this, this.state.header, this.state.text)
			)
		}
    else if (serviceInvocationError || (serviceErrorLevel === Const.SYS_ERROR_LEVEL)) {
			return (
				messageModalWindow(this, this.state.header, this.state.text)
			)
		} 
    else if (serviceStatusCode === Const.NO_COOKIE_IN_HEADERS) {
      return <div>{Const.NO_COOKIE_IN_HEADERS_CHANGE_SETTINGS_MESSAGE}</div>;
    } 
    else if (serviceStatusCode === Const.NOT_CRITICALLY_BAD_COOKIE_WAS_SENT) {
      return <div>{Const.BAD_COOKIE_MESSAGE_CLOSE_BROWSER}</div>;
    } 
    else if (serviceInvocationStatus !== Const.SERVICE_IS_NOT_INVOKED_YET) {
      return <div>{Const.CHEKKING_USER_INFO}</div>;
    } else {
      return (
        <div className="App-div">
          <div className="after-app-wrap-center-login-div-walk forgot-password" >
            <div>
              <div className="login-form">
                {/* <Row className = "row-margin-0">
                  <img className = "left mb-5" width = "50px" src = { Logo } alt="ACE4J"/>
                </Row> */}
                <Form onSubmit={this.handleSubmit}>
                  <FormGroup >
                  <h2 className = "text-left">Find your account</h2>
                  <p className = "text-left">{Const.ENTER_YOUR_EMAIL}</p>
                    <div className="login-form-row-no-label">
                      <Input className="x-input"
                        name="email" 
                        placeholder="Email"
                        value={this.state.email}
                        required
                        onChange={this.handleChange}
                      />
                      <div className = "error-message">
                        <span>{ this.state.emailError }</span>
                      </div>
                    </div>
                    <Button className="btn-lg btn-block primary left" color ="info" type="Submit"> Search
                    </Button>
                  </FormGroup>
                </Form>
              </div> 
            </div>
          </div> 
        </div>   
      );
    }
  }
}