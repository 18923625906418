import React, { Component } from 'react';

class CustomDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: null,
      activeOption: null,
    };
  }

  handleOptionClick = (option) => {
    let { onClick } = this.props;
    this.setState((prevState) => ({
      selectedOption: option,
      activeOption: prevState.selectedOption === option ? null : option,
    }));

    // Call the parent's component onClick handler
    if (onClick) {
      onClick(option);
    }
  };

  handleTouchMove = (e) => {
    let { onTouchMove } = this.props;

    if (onTouchMove) {
      onTouchMove();
    }
  };

  handleOptionMouseEnter = (option) => {
    this.setState({
      activeOption: option,
    });
  };

  handleOptionMouseLeave = () => {
    this.setState({
      activeOption: null,
    });
  };

  render() {
    const { options, customDropdownClass, optionsContainerClass, 
            optionWrapperClass, optionClass } = this.props;
    const { selectedOption, activeOption } = this.state;
    const customDropdownClass_ = customDropdownClass ? customDropdownClass : "custom-dropdown";
    const optionsContainerClass_ = optionsContainerClass ? optionsContainerClass : "options-container";
    const optionWrapperClass_ = optionWrapperClass ? optionWrapperClass : "option-wrapper";
    const optionClass_ = optionClass ? optionClass : "option";

    return (
      <div className={ customDropdownClass_ } onTouchMove={() => this.handleTouchMove} >
        <div className={ optionsContainerClass_ }>
          {options.map((option, index) => (
            <div
              key={index}
              className={ optionWrapperClass_ }
              onClick={() => this.handleOptionClick(option)}
              onMouseEnter={() => this.handleOptionMouseEnter(option)}
              onMouseLeave={this.handleOptionMouseLeave}
            >
              <div
                className=
                {` 
                    ${optionClass_} 
                    ${
                      selectedOption === option ? 'selected' : ''
                    } 
                    ${
                      activeOption === option ? 'active' : ''
                    }
                `}
              >
                {option}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default CustomDropdown;
