import React, { Component } from 'react';
import '../App.css';
import { Dropdown } from 'react-bootstrap';
import { Nav, NavItem } from 'reactstrap';
import { Link } from 'react-router-dom';
import Medeata from '../images/Medeata200blue.svg';
import MedeataSymbol from '../images/Medeata200blueSymbol.svg';
import DefaultUserPicture from '../images/default_user.jpg';
import PostYourComment from '../components/getData/PostYourComment';
import DeleteYourComment from '../components/getData/DeleteYourComment';
import * as AiIcons from 'react-icons/ai';
import * as RiIcons from 'react-icons/ri';
import * as FiIcons from 'react-icons/fi';
import * as MdIcons from 'react-icons/md';
import * as BiIcons from 'react-icons/bi';
import Const from '../components/Const';
import GetYourPost from '../components/getData/GetYourPost';
import GetYourPostTabletLandscape from './getData/GetYourPostTabletLandscape';
import GetYourPostTabletPortret from '../components/getData/GetYourPostTabletPortret';
import { findMyPostsWithPictures, getUserPictureClassName, toolTippy,
         getLastCommentOfLoginUser, sleep, isPhonePortretLayout, isPhoneLandscapeLayout, isBrowsingMode } from './Utils.js';
import { scrollToTop, HOME } from './ScrollUtils.js';
import { storeMobilPostButtonClass } from './MobilButtonUtils.js';
import GetYourPostMobile from '../components/getData/GetYourPostMobil';
import { iOSstatusBarPadding, setAppWasAlreadyInvoked } from '../utils/CapacitorUtils';
import { getAppButtonForTestDrive, isDesktop, isTablet, isTabletLandscapeLayout } from '../utils/AppUtils.js';

class Navi extends Component {
    constructor(props) {
        super(props); 
        const { loginUser, anyUser, that, post, viewOnly, returnTo } = this.getParams();

        this.state = {
            loginUser: loginUser,
            anyUser : anyUser,
            parent : that,
            post : post, 
            viewOnly : viewOnly, 
            returnTo : returnTo,
            showMobilButton : true,
			showModalPopUp : false,
            phoneLayout : null,
            getAppSelected : false,
            orientationChanged : false
        }
        this.refreshComments = this.refreshComments.bind(this);
        this.sortParentComments = this.sortParentComments.bind(this);
        this.togglePostAndImages = this.togglePostAndImages.bind(this);
        this.toggleFollowers = this.toggleFollowers.bind(this);
        this.handleMobilButtonClick = this.handleMobilButtonClick.bind(this);
        this.mediaChangeListener = this.mediaChangeListener.bind(this);
        this.timeoutID = -1;
        storeMobilPostButtonClass(this);
        this.phoneMedia = window.matchMedia(Const.PHONE_MEDIA_INTERVAL);
        this.phoneMedia.addEventListener('change', this.mediaChangeListener);
        this.parent = that;
        this.mounted = true;
    }

    componentDidMount() {
        setAppWasAlreadyInvoked(false);
    }

    componentDidUpdate() {
        const { loginUser } = this.getParams();
        
        if (loginUser.userComplete !== this.state.loginUser.userComplete) {
            this.setState({ loginUser : loginUser });
        }
    }

    componentWillUnmount() {
        this.phoneMedia.removeEventListener('change', this.mediaChangeListener);
        this.mounted = false;
    }

    getParams() {
        let ret;

		if (this.props.hasOwnProperty('navParams')) {
            ret = this.props.navParams;
        }
        else {
            ret = {
                loginUser : this.props.loginUser,
                anyUser : null,
                that : null,
            }
        }
        return ret;
    }

    selfInvocation() {
		if (this.props.hasOwnProperty('navParams')) {
            return (
                <Navi key={Math.random()} navParams = { this.props.navParams } />
            )
        }
        else {
            return (
                <Navi key={Math.random()} navParams = { this.props.loginUser } />
            )
        }
    }

    async toggleFollowers() { 
        let loginUser = this.state.loginUser;
		let followers = this.parent.followersRef.current;
      
        loginUser.showFollowig = !loginUser.showFollowig;
        this.parent.setState({ loginUser : loginUser });
        this.setState({ loginUser : loginUser });
        if (followers) {
            this.scrollFollowersToInitPosition(followers);
        }
    }

    async scrollFollowersToInitPosition(followers) {
        let scrollLeft1;
        let scrollLeft2;
        let scrollTop1;
        let scrollTop2;

        if (isDesktop()) {
            if (this.parent.className === HOME) {
                followers.scrollTop = 0;
            }
            else {
                scrollToTop(this.parent);
            }
        }
        else {
            // cannot do it while followers are moving by themselves (e.g. iPhone)
            scrollLeft1 = followers.scrollLeft;
            scrollTop1 = followers.scrollTop;
            await sleep(50);
            scrollLeft2 = followers.scrollLeft;
            scrollTop2 = followers.scrollTop;
            if (scrollLeft1 === scrollLeft2) {
                followers.scrollLeft = 0;
            }
            if (scrollTop1 === scrollTop2) {
                followers.scrollTop = 0;
            }
        }
    }

    async scrollFollowersToInitPositionDeskTop(followers) {
        followers.scrollTop = 0;
    }

    async togglePostAndImages() {
        await this.state.parent.setState({ 
                                           postView : !this.state.parent.state.postView,
                                           canProcessContactUs : false
                                        });
    }

    refreshComments() {
        this.state.parent.refreshCommentsFromDB(this.state.post);
    }

    sortParentComments() {
        this.state.parent.sortComments();
    }


    conditionForFollowingAndFollowers(loginUser, anyUser) {
        let cond1 = loginUser.userComplete && (loginUser.userId > 0);
        let cond2 = false;
        let cond3 = false;
        
        if (cond1) {
            if ((loginUser.whereIam === Const.I_AM_AT_HOME) || 
                 ((loginUser.whereIam === Const.I_AM_IN_MY_PROFILE) && this.state.parent.state.postView)) {
              cond2 = (loginUser.hasOwnProperty('whoIsUserFollowing') && (loginUser.whoIsUserFollowing.length > 0)) ||
                          (loginUser.hasOwnProperty('followers') && (loginUser.followers.length > 0));
            }
            if ((loginUser.whereIam === Const.I_AM_IN_ANY_PROFILE) && this.state.parent.state.postView) {
                cond3 = (anyUser) && 
                         ((anyUser.hasOwnProperty('whoIsUserFollowing') && (anyUser.whoIsUserFollowing.length > 0)) ||
                           (anyUser.hasOwnProperty('followers') && (anyUser.followers.length > 0)));
            }
        }
        return cond2 || cond3;
    }

    conditionForPostsAndImages(loginUser, anyUser) {
        let cond1 = loginUser.userComplete && (loginUser.userId > 0);
        let cond2 = false;
        let cond3 = false;

        if (cond1) {
            cond2 = (loginUser.whereIam === Const.I_AM_IN_MY_PROFILE) && loginUser.userPosts &&
                      (loginUser.userPosts.length > 0) && findMyPostsWithPictures(loginUser.userPosts);
            cond3 = (loginUser.whereIam === Const.I_AM_IN_ANY_PROFILE) && anyUser.userPosts &&
                      (anyUser.userPosts.length > 0) && findMyPostsWithPictures(anyUser.userPosts);
        }
        return cond1 && (cond2 || cond3);
    }

    async handleMobilButtonClick() {
		this.setState({ showMobilButton : false, showModalPopUp : true });
	}

    getDesckTopPostComponent(getYourPostParams) {
        return (
            <NavItem key="deskTopPostButton" className="desktop-post-item pointer-view" >
                <div className="" >
                    <GetYourPost className="" getYourPostParams = { getYourPostParams } />
                </div>
            </NavItem>
        )
	}

    getLandscapeTabletPostButton(getYourPostParams) {
        return (
            <NavItem key="deskTopPostButton" className="desktop-post-item pointer-view" >
                <div className="" >
                    <GetYourPostTabletLandscape className="" getYourPostParams = { getYourPostParams } />
                </div>
            </NavItem>
        )
	}

    getPortretTabletPostButton(getYourPostParams) {
        return (
            <NavItem key="deskTopPostButton" className="desktop-post-item pointer-view" >
                <div className="" >
                    <GetYourPostTabletPortret className="" getYourPostParams = { getYourPostParams } />
                </div>
            </NavItem>
        )
	}

    getMobilPostButton(showMobilButton) {
        if (showMobilButton) {
            return (
                <div key="mobilPostButton" className="nav-link nav-icon create-post" 
                      onClick={this.handleMobilButtonClick} >
                    <div id={Const.MOBIL_POST_BUTTON_ID} className="btn-mobile">
                        <BiIcons.BiPlus  />
                    </div>
                </div>
            )
        }
	}

    getMobilPostPopup(showModalPopUp, getYourPostParams) {
        if (showModalPopUp) {
            return (
                <div key="mobilPopUp" className="nav-link nav-icon create-post">
                    <GetYourPostMobile  getYourPostParams = { getYourPostParams } />
                </div> 
            )
        }
	}

    getPostComponents(getYourPostParams, showMobilButton, showModalPopUp) {
        if (!getYourPostParams.parent.state.hasOwnProperty('postView') ||
            getYourPostParams.parent.state.postView) {
            if (isPhonePortretLayout() || this.state.phoneLayout) {
                // this.state.phoneLayout not realy needed as it's the same condition
                return (
                    [
                        this.getMobilPostButton(showMobilButton),
                        this.getMobilPostPopup(showModalPopUp, getYourPostParams)
                    ]
                )
            }
            else if (isPhoneLandscapeLayout()) {
                // no create post button
                return null;
            }
            else if (isTablet()) {
                if (isTabletLandscapeLayout()) {
                    return (
                        this.getLandscapeTabletPostButton(getYourPostParams)
                    )
                }
                else {
                    return (
                        this.getPortretTabletPostButton(getYourPostParams)
                    )
                }
            }
            else {
                return (
                    this.getDesckTopPostComponent(getYourPostParams)
                )
            }
        }
	}

    mediaChangeListener(e) {
        let phoneLayout;

        e.preventDefault();
        if (e.matches) {
            phoneLayout = true;
        }
        else {
            phoneLayout = false;
        }
        this.setState({phoneLayout : phoneLayout});
    }

    getReturnTo(loginUser) {
        let returnTo;

        if (loginUser.whereIam === Const.I_AM_AT_HOME) {
            returnTo = "/Home";
        }
        else if (loginUser.whereIam === Const.I_AM_IN_MY_PROFILE) {
            returnTo = "/Profile";
        }
        else {
            returnTo = "/User";
        }
        return returnTo;
    }

    handleGetAppClick = () => {
        this.setState({ getAppSelected : true });
    }

    render() {
        const { loginUser, anyUser, that, post, viewOnly, returnTo } = this.getParams();
        const { showMobilButton, showModalPopUp, getAppSelected } = this.state;
        const getYourPostParams = { 
			loginUser : loginUser, 
			that : this,
            parent : that,
            showModalPopUp : showModalPopUp
		};
        const postYourCommentsParams = { 
			loginUser : loginUser, 
			anyUser : anyUser,
			post : post,
			viewOnly : viewOnly,
			returnTo :  returnTo,
			that : that
		};
        
        const searchParams = { 
			loginUser : loginUser, 
			anyUser : anyUser, 
			returnTo :  this.getReturnTo(loginUser)
		};
        const avatarClassName = getUserPictureClassName(loginUser);

        if (getAppSelected) {
            // re-invoke Nav just close the dropdown menu after user selects 'Get App'
            return this.selfInvocation();
		}
       
        return (
                <>
                    {
                        iOSstatusBarPadding()
                    }
                    
                    <Nav className="justify-content-end mr-auto">
                            <NavItem>
                                <img src={ Medeata } alt={ Const.MEDEATA_IMAGE_ALT } className = "medeata-desktop" style={{float: 'left', position: 'fixed'}}  />
                                <img src={ MedeataSymbol } alt={ Const.MEDEATA_IMAGE_ALT } className = "medeata-mobile" />
                            </NavItem> 
                           
                            {
                                loginUser.userComplete && (loginUser.userId) > 0 && !loginUser.testDrive &&
                                (loginUser.whereIam === Const.I_AM_IN_COMMENTS) &&
                                <NavItem className="pointer-view" >
                                    <div className="" >
                                        <PostYourComment className="" postYourCommentsParams = { postYourCommentsParams } />  
                                    </div>
                                </NavItem>
                            }

                            {
                                loginUser.userComplete && (loginUser.userId) > 0 && !loginUser.testDrive &&
                                (loginUser.whereIam === Const.I_AM_IN_COMMENTS) && getLastCommentOfLoginUser(post, loginUser) &&
                                <NavItem className="pointer-view" >
                                    <div className="" >
                                        <DeleteYourComment className="" deleteYourLastCommentParams = { postYourCommentsParams } />  
                                    </div>
                                </NavItem>
                            }

                            {
                                loginUser.userComplete && (loginUser.userId) > 0 && 
                                (loginUser.whereIam === Const.I_AM_IN_COMMENTS) &&
                                <NavItem className="pointer-view" >
                                    { toolTippy(MdIcons.MdRefresh, "Show Latest Comments", "bottom", [Const.DELAY, 0], "nav-icon nav-link pointer-view", this.refreshComments) }
                                </NavItem>
                            }

                            {
                                loginUser.userComplete && (loginUser.userId) > 0 && 
                                (loginUser.whereIam === Const.I_AM_IN_COMMENTS) && post.hasOwnProperty('comments') &&
                                 <NavItem className="pointer-view" >
                                   { toolTippy(RiIcons.RiArrowUpDownFill, "Cgange Order", "bottom", [Const.DELAY, 0], "nav-icon nav-link pointer-view", this.sortParentComments) }
                                </NavItem>
                            }

                            {
                              (loginUser.whereIam !== Const.I_AM_IN_COMMENTS) && ((loginUser.whereIam !== Const.I_AM_IN_ACCOUNT)) &&
                              (loginUser.whereIam !== Const.I_AM_IN_DELETE_ACCOUNT) && (loginUser.whereIam !== Const.I_AM_IN_ABOUT) &&
                              (loginUser.whereIam !== Const.I_AM_IN_PREFERENCES) && (loginUser.whereIam !== Const.I_AM_IN_SHARED_POST) &&
                              !isPhoneLandscapeLayout() && loginUser.userComplete &&
                              <NavItem className="nav-icon">
                                {
                                    (loginUser.whereIam !== Const.I_AM_IN_FIND_USERS)
                                    ? <Link className="nav-icon nav-link pointer-view" to={{ pathname: '/Search', params: searchParams }}>
                                        { toolTippy(RiIcons.RiSearchLine, "Find users & posts", "bottom", [Const.DELAY, 0], "", null) }
                                    </Link>
                                    : <div className="nav-icon nav-link" >
                                        <RiIcons.RiSearchFill />
                                    </div>
                                }
                                 
                              </NavItem>
                            }

                            {
                                loginUser.userComplete && (loginUser.userId) > 0 && !loginUser.testDrive &&
                                ((loginUser.whereIam === Const.I_AM_AT_HOME) || (loginUser.whereIam === Const.I_AM_IN_MY_PROFILE) ||
                                 (loginUser.whereIam ===Const.I_AM_IN_ANY_PROFILE)) &&   
                                 this.getPostComponents(getYourPostParams, showMobilButton, showModalPopUp)
                            }

                            {
                                   this.conditionForFollowingAndFollowers(loginUser, anyUser) && (!isBrowsingMode(loginUser) || anyUser) &&
                                    <NavItem>
                                       {
                                           loginUser.showFollowig
                                           ? toolTippy(RiIcons.RiUserFollowLine, "Show My Followers", "bottom", [Const.DELAY, 0], "nav-icon nav-link pointer-view", this.toggleFollowers)
                                           
                                           : toolTippy(RiIcons.RiUserFollowFill, "Show Who I Follow", "bottom", [Const.DELAY, 0], "nav-icon nav-link pointer-view", this.toggleFollowers)
                                       }
                                   </NavItem>
                            }

                            {
                                    this.conditionForPostsAndImages(loginUser, anyUser) && (!isBrowsingMode(loginUser) || anyUser) &&
                                     <NavItem>
                                     {
                                         this.state.parent.state.postView 
                                         ? toolTippy(RiIcons.RiCheckboxMultipleBlankLine, "Show Media Library", "bottom", [Const.DELAY, 0], "nav-icon nav-link pointer-view", this.togglePostAndImages) 
                                         : toolTippy(RiIcons.RiCheckboxMultipleBlankLine, "Show Posts", "bottom", [Const.DELAY, 0], "nav-icon nav-link pointer-view", this.togglePostAndImages) 
                                     }
                                    </NavItem>
                            }

                            {
                                loginUser.userComplete &&
                                <NavItem className="nav-icon">
                                {
                                    (loginUser.whereIam !== Const.I_AM_AT_HOME) 
                                    ? <Link className="nav-icon nav-link" to={{ pathname: '/Home', params: { loginUser } }}>
                                        <AiIcons.AiOutlineHome />
                                      </Link>
                                    : <div className="nav-icon nav-link" >
                                        <AiIcons.AiTwotoneHome />
                                      </div>
                                }
                                </NavItem>
                            }

                            <NavItem className="nav-icon nav-user">
                                {
                                   (loginUser.whereIam !== Const.I_AM_IN_MY_PROFILE) 
                                   ? <Link to={{ pathname: '/Profile', params: { loginUser } }}>
                                        {
                                            loginUser.hasOwnProperty('userPicture')
                                            ? <div className={avatarClassName  + " left"}><img src={ loginUser.userPicture } alt="Avatar" /></div>
                                            : <div className={avatarClassName  + " left"}><img src={ DefaultUserPicture } alt="Avatar" /></div>
                                        }
                                     </Link>
                                   : <div className="clicked-user">
                                        {
                                            loginUser.hasOwnProperty('userPicture')
                                            ? <div className={avatarClassName  + " left"}><img src={ loginUser.userPicture } alt="Avatar" /></div>
                                            : <div className={avatarClassName  + " left"}><img src={ DefaultUserPicture } alt="Avatar" /></div>
                                        }
                                     </div>
                                }
                            </NavItem>

                            {
                                <NavItem className="navitem-dropdown">
                                    <Dropdown className="">
                                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                                            <div className="nav-icon nav-settings" style={{paddingLeft: '0px!important'}}>
                                                { toolTippy(FiIcons.FiSettings, "Admin Functions & Settings", "bottom", [Const.DELAY, 0], "", null) }
                                            </div>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className="dropdown-modal">
                                            {
                                                (loginUser.whereIam !== Const.I_AM_IN_ACCOUNT) && !loginUser.testDrive &&
                                                <Link to={{ pathname: '/Account', params: { ocaLoginUser : loginUser } }}>
                                                    Account Settings
                                                </Link>
                                            }

                                            {
                                                (loginUser.whereIam !== Const.I_AM_IN_DELETE_ACCOUNT) && !loginUser.testDrive &&
                                                <Link to={ {pathname: '/Deleteaccount', params: { ocaLoginUser : loginUser }} }>
                                                    Delete Account
                                                </Link>
                                            }

                                            {
                                                !loginUser.testDrive &&
                                                <Link to={ {pathname: '/signout'} }>
                                                  Sign Out
                                                </Link>
                                            }

                                            {
                                                loginUser.testDrive && 
                                                 getAppButtonForTestDrive(this)
                                            }

                                            {
                                                loginUser.testDrive &&
                                                <Link to={ {pathname: '/'} }>
                                                    Login
                                                </Link>
                                            }

                                            {
                                                (loginUser.whereIam !== Const.I_AM_IN_ABOUT) && 
                                                <Link to={ {pathname: '/Contactus', params: { loginUser : loginUser }} }>
                                                    Contact Us
                                                </Link>
                                            }

                                            {
                                                (loginUser.whereIam !== Const.I_AM_IN_PREFERENCES) && !loginUser.testDrive && loginUser.userComplete &&
                                                <Link to={ {pathname: '/Preferences', params: { loginUser : loginUser }} }>
                                                    Preferences
                                                </Link>
                                            }
                                            
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </NavItem>
                            }
                           
                    </Nav>
                </>
               );
        }
}

export default Navi;
