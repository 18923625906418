import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import * as AiIcons from 'react-icons/ai';
import * as FaIcons from 'react-icons/fa';
//import * as MdIcons from 'react-icons/md';

//import LoginUserAndHisPosts from '../data/DataLoginUserAndHisPosts.js';
import Navi from '../nav';
//import ImageComponent from "./imageComponent";
//import GetLikes from './GetLikes';

class GetImages extends Component {
     
    constructor(props){
        super(props);
        this.state = {            
            userImage : null,
            // userName : null,
            // userPicture : null,
            // isShow: false, 
            isOpen: false,
            //LoginUserAndHisPosts : LoginUserAndHisPosts,             
        };
    }

    setuserImage = (img) => {
        this.setState(state => ({ userClickedPostImage: img }));
    };

    setuserPost = (post) => {
        this.setState(state => ({ userClickedPost: post }));
    };

    setuserPostLikesCount = (likeCount) => {
        this.setState(state => ({ userClickedPostLikesCount: likeCount }));
    };

    setuserPostCommentsCount = (commentsCount) => {
        this.setState(state => ({ userClickedPostCommentsCount: commentsCount }));
    };

    setuserPostSharesCount = (sharesCount) => {
        this.setState(state => ({ userClickedPostSharesCount: sharesCount }));
    };

    setUser = (name) => {
        this.setState(state => ({ userName: name }));
    };

    setuserPicture = (pic) => {
        this.setState(state => ({ userPicture: pic }));
    };

    //open image div. not this option
    // setShowOn = () => {
    //     this.setState(state => ({isShow: true}));
    // };

    //open image dialog
    setOpenOn = () => {
        this.setState(state => ({isOpen: true}));
    };

    //closing dialog on click
    handleShowDialog = () => {
        this.setState({ isOpen: !this.state.isOpen });
        console.log("cliked");
    };

    render() {
        //const { params } = this.props.location; // this is the user we receive from ProfileOfAnyUser (parameter)
        const { loginUser, userPicture, userName, userNumberOfPosts,
                userNumberOfFollowers, userNumberOfFollowing, userTagline, userBio, userWebsite, userPosts } = this.props.location.params;
        const { userClickedPostImage } = this.state;
        const { userClickedPost } = this.state;
        const { userClickedPostLikesCount } = this.state;
        const { userClickedPostCommentsCount } = this.state;
        const { userClickedPostSharesCount } = this.state;
        // const { userName } = this.state;
        // const { userPicture } = this.state;
        //const { isShow } = this.state;
        const { isOpen } = this.state;

        console.log("#########  I am in GetImages ############");
        console.log( loginUser );
        //console.log( loginUserFollowingPosts );
 
        return(
            <>              
            <Navi loginUser = { loginUser } />                
                <div className="after-app-wrap-center-div">
                    <Row className="profile-block mb-3">
                        <Col className="center" lg={3} xs={12}>
                        <div className="img-wrap img-match" >
                        <img src={userPicture} className="avatar-big" alt="Avatar" />
                        </div>
                            
                        </Col>

                        <Col className="profile-info" lg={5} xs={12}>
                            <Row >              
                                    <h4 className="mb-0"> {userName} </h4>           
                            </Row>  
                            <Row className="mt-3">
                                <span className="left pr-2"> <span className="bold">{userNumberOfPosts}</span> Posts</span>
                                <span className="left pr-2"> <span className="bold">{userNumberOfFollowers}</span> Followers</span>
                                <span className="left"> <span className="bold">{userNumberOfFollowing}</span> Following</span>
                            </Row>

                            <Row className="mt-3"> {userTagline}</Row>
                            <Row className="mt-2"> {userBio}</Row>
                            <Row ><Link> {userWebsite} </Link></Row>         
                        </Col>
                    </Row>

                    {isOpen && 
                        <dialog
                            className="dialog"
                            style={{ position: "absolute" }}
                            open
                            onClick={this.handleShowDialog}
                        >
                            <img className="image" alt="postImage" src= { userClickedPostImage }
                                    onClick={this.handleShowDialog}
                            ></img>

                            <div className = "communique">
                                <p className="medium mb-3"> { userClickedPost } </p> 
                                <Row className="row-margin-0 icon-div">
                                    <AiIcons.AiOutlineHeart className="home-icons-medium left" />
                                    <p className="icon-count-gallery medium mb-0"> { userClickedPostLikesCount }</p>
                                    {/* <GetLikes clickedPost = { userPost }/>    */}
                                    <FaIcons.FaRegComment className="home-icons left" />
                                    <p className="icon-count-gallery medium mb-0"> { userClickedPostCommentsCount }</p>
                                    <AiIcons.AiOutlineShareAlt className="home-icons-medium left" />
                                    <p className="icon-count-gallery medium mb-0"> { userClickedPostSharesCount }</p>
                                </Row>
                            </div>        
                        </dialog>
                    }                      
                    
                    <Row className="profile-posts-and-channels">
                        <div className="library-div-profile" >

                            <Row className="row-margin-15">
                                <Link  className = "" to={{ pathname: '/Profile', params: { loginUser  } }}>  
                                    <span>Back to Profile</span>
                                </Link>
                            </Row>

                            {/*Original component for showing the image*/}
                            {/* <ImageComponent /> */}

                            {  userPosts.map((userPost) =>                            
                                <div  key={ userPost.postId } className="image-text-div" >                                 
                                    <button onClick={() => {
                                            //open image
                                            this.setOpenOn(); 
                                            //close image
                                            this.handleShowDialog();
                                            //this.setShowOn(); 
                                            this.setuserImage( userPost.postImg );
                                            this.setuserPost( userPost.post );
                                            this.setuserPostLikesCount( userPost.likesCount );
                                            this.setuserPostCommentsCount( userPost.commentsCount );
                                            this.setuserPostSharesCount( userPost.sharesCount );
                                            this.setUser( userName );
                                            this.setuserPicture( userPicture )
                                        }}>
                                        <img className="img-fluid image" alt="your gallery" src= { userPost.postImg } />
                                        <div className="overlay">
                                            <div className="text">
                                            <AiIcons.AiOutlineHeart className="" />
                                            { userPost.likesCount}
                                            </div>
                                        </div> 
                                        {/* <div className="middle">
                                            <div className="text">
                                            <AiIcons.AiOutlineHeart className="" />
                                            { userPost.likesCount}
                                            </div>
                                        </div>                                       */}
                                    </button>
                                </div>  
                            )} 
                        </div>
                    </Row> 
                </div>
            </>
            );
        }
    }
  
  export default GetImages;
