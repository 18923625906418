import GetYourPost from './GetYourPost';
import { addOrientationChangeListener, removeOrientationChangeListener } from '../../utils/SessionUtils.js';

export default class GetYourPostTablet extends GetYourPost {
	constructor(props) {
		super(props);
		this.autoFocus = false;
	}

	observeOrientationChange(toggleModal) {
		if (toggleModal) {
			// pop-up is window is about to pop up
			addOrientationChangeListener(this);
		}
	}

	orientationChangeProcessor() {
		this.toggle();
		this.state.parent.setState({ orientationChanged : true }); // nav will change the '+' button content
		removeOrientationChangeListener();
	}
}