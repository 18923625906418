import React, { Component } from 'react';
import '../App.css';

class Cat extends Component {
   render() {
		return (
            <body style={{ 
				height: '100vh',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center'}}
				>
                <div className="cat-container">
                <div className="cat">
                <div className="head">
                    <div className="earl"></div>
                    <div className="earr"></div>
                    <div className="eyel"></div>
                    <div className="eyer"></div>
                    <div className="mouth"></div>
                    <div className="m"></div>
                    <div className="nose"></div>
                </div>
                <div className="tail"></div>
                <div className="cat-body">
                    <div className="feetl"></div>
                    <div className="feetr"></div>
                </div>
                </div>
                <div className="bubble"></div>
                <div className="shadow"></div>
            </div>
          </body>
		);
	}
}

export default Cat;
