import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Row, Col, Card } from 'reactstrap';
//import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Link } from 'react-router-dom';
import '../css/bootstrap.css';
import * as FaIcons from 'react-icons/fa';
import mirkaAndHerPosts from '../components/data/DataMirkaAndHerPosts.js';
import fredAndHisPosts from '../components/data/DataFredAndHisPosts.js';
import starlywaveAndHerPosts from '../components/data/DataStarlywaveAndHerPosts.js';
import fabianoAndHisPosts from '../components/data/DataFabianoAndHisPosts.js';
import carolineAndHerPosts from '../components/data/DataCarolineAndHerPosts.js';
import GetLikes from '../components/getData/GetLikes';
import Navi from '../components/nav';
import GetChannels from '../components/getData/GetChannels';
import ShareDropdown from '../components/ShareDropdown';
import  { getPostSharingParms } from '../utils/RenderPages.js';
import Const from '../components/Const';
import Envir from '../components/Envir';
import GetImages from '../components/getData/GetImages';
import {invokeService,  getUserJson,  messageModalWindow, sleepingCat, sleepingCatSmall,
	    setLocalCookieForOcaAnyUser, webSiteUrlCorrector,
	    getUserJsonFromDBwithCookie, followingFollowersCondition, getClassNameForWrapDiv,
		contactUs, getUserPictureClassName, loadPartialJsonPosts, getLocalCookieForOcaAnyUser, toolTippy } from '../components/Utils.js';
import { createAppProfileLayout, appTouchStartListener,  appTouchEndListener, appImageZoomUnsubscribe,
	     addOrientationChangeListener, removeOrientationChangeListener, appTouchMoveListener } from '../components/ImageZoomUtils.js';
import { addScrollListener, scrollToStroredPosition, removeScrollListener, ANY_USER, setPostsLoadinOrPostsEndOption,
	     unsetPostsLoadinOrPostsEndOption, loadingImage, scrollToStroredPositionConditional } from '../components/ScrollUtils.js';
import { showBodyScrollBar } from '../utils/AppUtils.js';
import  { postsAndFollowersClassNames } from '../utils/PostUtils.js';
import  { renderVideoPlayerWrapperM } from '../utils/VideoPlayerUtils.js';

class ProfileOfAnyUser extends Component {
	constructor(props) {
		super(props);
		const personIFollow = props.location.params ? props.location.params.personIFollow : null;
		const fullPersonIFollow = this.getFullPersonIfollow(personIFollow);

		this.state = {
			loginUser : props.location.params ? props.location.params.loginUser : null,
			personIFollow: personIFollow,
			fullPersonIFollow: fullPersonIFollow,
			noError: false,
			zero : (Envir.DB_JSON === 'ON') ? 0 : '0',
			follow: false,
			asyncUserDeletion : false,
			header : '',
			text : '',
			postView : true,
			showMobilButton : true,
			messageModal : false,
			canProcessContactUs : false,
			serviceInvocationStatus : Const.SERVICE_IS_NOT_INVOKED_YET,
			serviceStatusCode : Const.INIT_VALUE,
			serviceErrorLevel : Const.NO_ERROR_LEVEL,
			serviceInvocationError : false
		};
		this.handleClick = this.handleClick.bind(this);
		this.postsContainerRef = React.createRef();
		this.imgsConianerRef = React.createRef();
		this.loadingImgRef = React.createRef();
		this.followersRef = React.createRef();
		this.className = ANY_USER;
		this.onTouchStartHandler = this.onTouchStartHandler.bind(this);
		this.onTouchendHandler = this.onTouchEndtHandler.bind(this);
		this.pinchToZoom = null;
		this.key = this.props.history.location.key;
	}

	async componentDidMount() {
		let loginUser = this.state.loginUser;
		let personIFollow = this.state.personIFollow;
		let fullPersonIFollow = this.state.fullPersonIFollow;
		let valueObj = await getLocalCookieForOcaAnyUser();
		let locationKey = valueObj ? valueObj.key : "";

		if ((locationKey === this.key) && loginUser && personIFollow && !fullPersonIFollow) {
			// browser back button defender
			return;
		}
	    if (!loginUser || !fullPersonIFollow) {
			this.connectToDB();
		}
		else {
			scrollToStroredPositionConditional(this);
		}
		addScrollListener(this);
		addOrientationChangeListener(this);
		createAppProfileLayout();
	}

	async connectToDB() {
		let anyUserName;
		let result;
		let loginUser = this.state.loginUser;
		let personIFollow = this.state.personIFollow;
		let fullPersonIFollow = this.state.fullPersonIFollow;
		let follow = this.state.follow; 
		let valueObj;

		this.disableEnableUpperPartOfThescreen(true);
		if (!loginUser) {
			result = await getUserJsonFromDBwithCookie(this);
			if (this.state.serviceErrorLevel === Const.NO_ERROR_LEVEL) {
				loginUser = result;
			}
		}
		if (loginUser) {
			loginUser.showFollowig = true;
			if (personIFollow) {
				anyUserName = personIFollow.userName;
			}
			else {
				valueObj = await getLocalCookieForOcaAnyUser();
				anyUserName = valueObj.name;
			}
			if (!fullPersonIFollow) {
				if (!loginUser.testDrive)  {
					setLocalCookieForOcaAnyUser({ name : anyUserName, key : this.key });
				}
				unsetPostsLoadinOrPostsEndOption(this, Const.END_OF_POSTS);
				result = await this.getUserJsonFromDB(anyUserName, loginUser);
				if (this.state.serviceErrorLevel === Const.NO_ERROR_LEVEL) {
					fullPersonIFollow = result;
					this.isItAfinalPage(fullPersonIFollow);
					personIFollow = fullPersonIFollow;
					follow = this.followOrUnfollowTheUserLogic(loginUser, fullPersonIFollow);
				}
			}
			if (fullPersonIFollow !== null)  {
				this.setState({ loginUser : loginUser, fullPersonIFollow: fullPersonIFollow, personIFollow: personIFollow, follow: follow  });
			}
		}
		this.disableEnableUpperPartOfThescreen(false);
	}

	async componentDidUpdate() {
		if (!this.state.messageModal && 
			(this.state.serviceStatusCode === Const.SYS_ERROR_FOLLOWING_USER_NO_USER_FOUND)) {
		  this.setState({ serviceStatusCode : Const.INIT_VALUE, asyncUserDeletion : true });
		}
		if (this.postsContainerRef.current || this.imgsConianerRef.current) {
			scrollToStroredPosition(this);
		}
		createAppProfileLayout();
	}

	componentWillUnmount() {
		removeScrollListener();
		removeOrientationChangeListener();
		if (this) {
			appImageZoomUnsubscribe(this);
		}
	}

	contactUsActivator() {
		if (this.state.fullPersonIFollow && !this.state.canProcessContactUs) {
			this.setState({ canProcessContactUs : true });
		}
	}

	onTouchStartHandler(e) {
		appTouchStartListener(e, this);
	}

	onTouchMoveHandler(e) {
		appTouchMoveListener(e);
	}

	onTouchEndtHandler(e) {
		appTouchEndListener(e);
	}

	async getUserJsonFromDB(userName, loginUser) {
		let ret;
		let data = {
			user_name : userName,
			no_top_posts : true,
			lu_id : loginUser.userId
		};

		if (Envir.DB_JSON === 'ON') {
			ret = await getUserJson(this, data);
		}
		else {
			ret = this.staticAnyUserJson(userName);
		}
		return ret;
	}

	staticAnyUserJson(userName) {
		let result;

		if (userName === "mirkavanderoe") {
			result = mirkaAndHerPosts;
		}
		else if (userName === "fredvoll") {
			result = fredAndHisPosts;
		}
		else if (userName === "starlywave") {
			result = starlywaveAndHerPosts;
		}
		else if (userName === "fabiangonzalezcolo") {
			result = fabianoAndHisPosts;
		}
		else if (userName === "carolinekramerabstract") {
			result = carolineAndHerPosts;
		}
		return result;
	}

	handleError(e) {
		e.preventDefault();
		this.setState({
			style: 'display:none',
			this: 'style.display = "none"'
		});
	}

	async handleClick(event) {
		event.preventDefault();
		this.disableEnableUpperPartOfThescreen(true);
		await this.followUnfollowTheUserInDB();
		if (this.state.serviceErrorLevel === Const.NO_ERROR_LEVEL) {
			this.addOrRemoveUserToFollow(this.state.personIFollow);
		}
		this.disableEnableUpperPartOfThescreen(false);
	}

	async followUnfollowTheUserInDB() {
		let data = {
			mode: Const.FOLLOW_USER_MODE,
			follow: this.state.follow,
			user_id_to_follow: this.state.fullPersonIFollow.userId
		}

		await invokeService(Const.OCA_SERVICES_URL, data, this);
	}

	followOrUnfollowTheUserLogic(loginUser, personIFollow) {
		let follow;

		if (loginUser && loginUser.hasOwnProperty("whoIsUserFollowing")) {
			follow = true;
			for (let personLoginUserfollow of loginUser.whoIsUserFollowing) {
				if (personLoginUserfollow.userName === personIFollow.userName) {
					follow = false;
					break;
				}
			}
		}
		else {
			follow = true;
		}
		return follow;
	}

	addOrRemoveUserToFollow(personIFollow) {
		let loginUser = this.state.loginUser;
		let fullPersonIFollow = this.state.fullPersonIFollow;
		let personToFollow;
		let ind;
		let me;

		if (this.state.follow) {
			me = {
				userId : loginUser.userId,
				userName : loginUser.userName,
				userPicture : loginUser.userPicture
			}
			if (!loginUser.hasOwnProperty('whoIsUserFollowing')) {
				loginUser.whoIsUserFollowing = [];
			}
			personToFollow = {
				userId: fullPersonIFollow.userId,
				userName: fullPersonIFollow.userName,
				userPicture: fullPersonIFollow.userPicture,
				userBio: fullPersonIFollow.userBio,
				userWebsite: fullPersonIFollow.userWebsite,
				userTagline: fullPersonIFollow.userTagline,
				userCompany: fullPersonIFollow.userCompany,
				userNumberOfPosts: fullPersonIFollow.userNumberOfPosts,
				userNumberOfFollowers: fullPersonIFollow.userNumberOfFollowers,
				userNumberOfFollowing: fullPersonIFollow.userNumberOfFollowing
			}
			loginUser.whoIsUserFollowing.push(personToFollow);
			if (!fullPersonIFollow.hasOwnProperty('followers')) {
				fullPersonIFollow.followers = [];
			}
			if (!fullPersonIFollow.hasOwnProperty('userNumberOfFollowers')) {
				fullPersonIFollow.userNumberOfFollowers = 0;
			}
			fullPersonIFollow.followers.push(me);
			fullPersonIFollow.userNumberOfFollowers = fullPersonIFollow.userNumberOfFollowers + 1;
			loginUser.userNumberOfFollowing = loginUser.userNumberOfFollowing + 1;
		}
		else {
			for (let personLoginUserfollow of loginUser.whoIsUserFollowing) {
				if (personLoginUserfollow.userName === personIFollow.userName) {
					ind = loginUser.whoIsUserFollowing.indexOf(personLoginUserfollow);
					break;
				}
			}
			loginUser.whoIsUserFollowing.splice(ind, 1);
			for (let personFollowThisUser of fullPersonIFollow.followers) {
				if (personFollowThisUser.userName === loginUser.userName) {
					ind = fullPersonIFollow.followers.indexOf(personFollowThisUser);
					break;
				}
			}
			fullPersonIFollow.followers.splice(ind, 1);
			fullPersonIFollow.userNumberOfFollowers --;
			loginUser.userNumberOfFollowing --;
		}
		this.setState({ loginUser: loginUser, fullPersonIFollow : fullPersonIFollow, follow: !this.state.follow });
	}

	disableEnableUpperPartOfThescreen(disable) {
		let upperPartOfTheScreen;
		let followUnfollowButton;

		upperPartOfTheScreen = document.getElementById('main');
		followUnfollowButton = document.getElementById('followUnfollowID');
		if (upperPartOfTheScreen && followUnfollowButton) {
			if (disable) {
				followUnfollowButton.setAttribute('disabled', 'disabled');
				upperPartOfTheScreen.style.opacity = Const.REDUCED_OPACITY;
			}
			else {
				followUnfollowButton.removeAttribute("disabled");
				upperPartOfTheScreen.style.opacity = Const.FULL_OPACITY;
			}
		}
	}

	getFullPersonIfollow(personIFollow) {
		let fullpersonIFollow = null;
		let isFullpersonIFollow = ((personIFollow !== null) && 
			                       (personIFollow.hasOwnProperty('userPosts') || personIFollow.hasOwnProperty('whoIsUserFollowing')));

		if (isFullpersonIFollow) {
			fullpersonIFollow = personIFollow;
		}
		return fullpersonIFollow;
	}

	getBackHomeOnAsyncUserDeletion(asyncUserDeletion) {
		if (asyncUserDeletion) {
			return (
					<Redirect to={{ pathname: "/Home" }} />
			       )
		}
	}

	isItAfinalPage(fullPersonIFollow) {
		if (fullPersonIFollow.hasOwnProperty('userPosts') && (fullPersonIFollow.userPosts.length < fullPersonIFollow.userNumberOfPosts)) {
			fullPersonIFollow.isFinalPage = false;
		}
		else {
			fullPersonIFollow.isFinalPage = true;
		}
	}

	loadMorePostsIsNeeded() {
		let anyUser = this.state.fullPersonIFollow;
		let needToLoadMorePosts = anyUser && anyUser.hasOwnProperty('userPosts') && !anyUser.isFinalPage;
		return needToLoadMorePosts;
	}

	async getPartialUserJson() {
		let fullPersonIFollow = this.state.fullPersonIFollow;
		let userPosts = fullPersonIFollow.userPosts;
		let parms = {
				user_name : fullPersonIFollow.userName,
				user_id : fullPersonIFollow.userId,
				max_post_id : userPosts[userPosts.length - 1].postId,
				lu_id : this.state.loginUser.userId
		    };
		let partialJson = await loadPartialJsonPosts(this, parms);

		this.processPartialUserJson(partialJson, fullPersonIFollow);
	}

	processPartialUserJson(partialJson, fullPersonIFollow) { 
		if ((this.state.serviceErrorLevel === Const.NO_ERROR_LEVEL)) {
			if (partialJson.hasOwnProperty('userPosts') && partialJson.userPosts.length > 0) {
				fullPersonIFollow.userPosts = fullPersonIFollow.userPosts.concat(partialJson.userPosts);
				if (fullPersonIFollow.userPosts.length === fullPersonIFollow.userNumberOfPosts) {
					fullPersonIFollow.isFinalPage = true;
					setPostsLoadinOrPostsEndOption(this, Const.END_OF_POSTS);
				}
			}
			else {
				fullPersonIFollow.isFinalPage = true;
				setPostsLoadinOrPostsEndOption(this, Const.END_OF_POSTS);
			}
			unsetPostsLoadinOrPostsEndOption(this, Const.POSTS_ARE_LOADING);
			this.setState({ fullPersonIFollow : fullPersonIFollow });
		}
	}

	showLoadingImage(fullPersonIFollow) {
		if (fullPersonIFollow.hasOwnProperty('isFinalPage') && fullPersonIFollow.isFinalPage) {
		  return null;
		}
		else {
		  return loadingImage(this);
		}
	}

	getRightDiv(loginUser, anyUser, multipleProps, pfClassNames) {
		if (followingFollowersCondition(loginUser, anyUser, this))  {
			return (
				<div id='followersWrapper' className={ pfClassNames.rightDivProfile }>
					{
						<div ref={this.followersRef} id='followers' >
							<GetChannels multipleProps = { multipleProps } />
						</div>
					}
				</div>
			)
		}
		else {
			return (
			  <div id='followersWrapper'/>
			)
		}
	}

	render() {
		const { loginUser, fullPersonIFollow, personIFollow, follow, asyncUserDeletion, 
			    serviceErrorLevel, header, text, canProcessContactUs } = this.state;
		let anyUser, userName, userPicture, userNumberOfPosts, userNumberOfFollowers, userNumberOfFollowing, userTagline, userBio, userWebsite,
			anyUserPosts, multipleProps;
		let userWebSiteFull;
		let navParams;
		let classNameContainer;
		let pfClassNames;

		if (!personIFollow || !loginUser) {
			if (serviceErrorLevel !== Const.NO_ERROR_LEVEL) {
				return messageModalWindow(this, header, text);
			}
			else {
				return sleepingCat();
			}
		}
		if (fullPersonIFollow) {
			anyUser = fullPersonIFollow;
		}
		else {
			anyUser = personIFollow;
		}
		classNameContainer = getClassNameForWrapDiv(loginUser, anyUser, this);
		pfClassNames = postsAndFollowersClassNames(classNameContainer);
		multipleProps = {
			loginUser: loginUser,
			userAndHisAssosiatedUsers: anyUser
		};
		navParams = { 
			loginUser : loginUser, 
			anyUser: anyUser,
			that : this
		};	
		
		({
			userName, userNumberOfPosts, userNumberOfFollowers, userNumberOfFollowing, userTagline, userBio,
			userWebsite
		} = anyUser
		);
		if (anyUser.hasOwnProperty('userPicture')) {
			userPicture = anyUser.userPicture;
		}
		else {
			userPicture = Const.DEFAULT_USER_PICTURE
		}
		if (anyUser.hasOwnProperty('userPosts')) {
			anyUserPosts = anyUser.userPosts;
		}
		else {
			anyUserPosts = [];
		}
		userWebSiteFull = webSiteUrlCorrector(userWebsite);
		loginUser.whereIam = Const.I_AM_IN_ANY_PROFILE;
		showBodyScrollBar();
		return (
			<>
			    {
					this.getBackHomeOnAsyncUserDeletion(asyncUserDeletion, loginUser)
				}
				<div>
					<Navi navParams = { navParams } />
				</div>

				{
					this.state.postView &&
					<div id='container' className= { pfClassNames.afterAppWrapCenterDiv }>
						{/* <NoImageFunction /> */}
						<Row id="main" className="profile-block" >
							<Col className="center" lg={3} xs={12}>
								{
									anyUser.hasOwnProperty('userPicture')
										? <div className="avatar-big"><img src={userPicture} alt="Avatar" /></div>
										: <div className="avatar-big"><img src={Const.DEFAULT_USER_PICTURE} alt="Avatar" /></div>
								}
							</Col>
	
							<Col className="profile-info" lg={7} xs={12}>
								<Row >
									<h4 className="mb-0 mt-2"> {userName} </h4>
								</Row>
								<Row className="mt-1">
									<span className="left pr-2"> <span className="bold">{userNumberOfPosts}</span> Posts</span>
									<span className="left pr-2"> <span className="bold">{userNumberOfFollowers}</span> Followers</span>
									<span className="left"> <span className="bold">{userNumberOfFollowing}</span> Following</span>
								</Row>
								<Row className="mt-3"> <p>{userTagline}</p> </Row>
								<Row > <p>{userBio}</p> </Row>
								{
									userWebsite &&
									<Row><a href={userWebSiteFull} target='_blank' rel="noopener noreferrer" role="none">{userWebsite}</a></Row>
								}
							</Col>
							{
								loginUser.testDrive 
								 ? 	<Col className="center" lg={2} xs={12}>
								      <button id="followUnfollowID" className="btn btn-outline-primary right" style = {{opacity : Const.REDUCED_OPACITY}} >{follow ? 'Follow' : 'Unfollow'}</button>
							        </Col>
								 : <Col className="center" lg={2} xs={12}>
								    <button id="followUnfollowID" className="btn btn-outline-primary right" onClick={this.handleClick}>{follow ? 'Follow' : 'Unfollow'}</button>
							      </Col>
							}
							
						</Row>

						{
							!fullPersonIFollow && 
							<Row>
								{
									sleepingCatSmall(0)
								}
							</Row>
						}
					
						<Row id='postsRow'>
							{
								/* POSTS */
								(anyUserPosts.length > 0) &&
								<div ref={this.postsContainerRef} id='posts' className={ pfClassNames.postsDivProfile }>
									{anyUserPosts.map(anyUserPost =>
										<div className="card-wrapper" key={anyUserPost.postId}>
											<Card className="postcard">
												<Row className="row-margin-0 p-2">
													<div className={getUserPictureClassName(anyUser)}>
														<img src={userPicture} className="" alt="Avatar" />
													</div>
													<div className="col-10">
														<p className="ml-0 mb-0 bold post-username">{userName}</p>
														<p className="small">{anyUserPost.time}</p>
													</div>
												</Row>
								
												{
													anyUserPost.hasOwnProperty('postImg') &&
													<div className="image-parent" onTouchStart={this.onTouchStartHandler} 
														onTouchEnd={this.onTouchEndtHandler} onTouchMove={this.onTouchMoveHandler}>
														<img style={{height: anyUserPost.imgHeight}} src={anyUserPost.postImg} alt={ Const.POST_IMAGE_ALT } />
													</div>	
												}

												{	
													anyUserPost.hasOwnProperty('postVideo') &&
													<div className="image-parent" >
														{ renderVideoPlayerWrapperM(anyUserPost, true, true) }

													</div>
												}
												
												<Row>
													<p className="pl-2 mb-0 poststyle"> {anyUserPost.post} </p>
												</Row>
		
												<Row className="icon-div p-2">
													<Col xs={4} className="mobile-col">
														<GetLikes getLikesParams = { {clickedPost : anyUserPost, viewOnly : false, loginUser : loginUser, 
																					that : this, anyUser : anyUser }}/>  
													</Col>
													<Col xs={4} className="mobile-col">
														<Link to={{ pathname: '/Comments', params: { clikedPost : anyUserPost, returnTo : '/User', 
																	viewOnly : false, anyUser, loginUser } }}>
															<div className="left">
															    { toolTippy(FaIcons.FaRegComment, "Comment", "right", [Const.DELAY, 0], "home-icons-medium", null) }
															</div>
															<div className="left">
																<p className="icon-count medium mb-0"> {anyUserPost.commentsCount} </p>
															</div>		
														</Link>   
													</Col>
													<Col xs={4} className="mobile-col">
														<ShareDropdown postSharingParms = { getPostSharingParms(anyUserPost, loginUser) } />
													</Col>
												</Row>
											</Card>
										</div>
									)}

									<div className="center">
										{
											this.showLoadingImage(fullPersonIFollow)
										}
									</div>	
								</div>
								
							}

							{  
							   /* FOLLOWING / FOLLOWERS*/ 
							   this.getRightDiv(loginUser, anyUser, multipleProps, pfClassNames) 
							}

						</Row>
						{
							canProcessContactUs &&
							contactUs()
						}
					</div>
				}

				{
				  !this.state.postView && (anyUserPosts.length > 0) &&
				   /* MEDIA */
					<div ref={this.imgsConianerRef} >
						<GetImages anyUser={anyUser} />
						{
							canProcessContactUs &&
							contactUs()
						}
						<div className="center">
							{
								this.showLoadingImage(fullPersonIFollow)
							}				
						</div>
					</div>
					
				}
			</>
		);
	}
}

export default ProfileOfAnyUser;