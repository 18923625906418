import React, { Component } from 'react';
import '../App.css';

class Birdy extends Component {
   render() {
		return (
		
				<div className="birdy-body">
					<div className="birdy-container">
						<div className="mainbody"></div>
						<div className="mouth"></div>
						<div className="beak"></div>
						<div className="feather"></div>
						<div className="tail"></div>
						<div className="leg"></div>
					</div>
				</div>
		
		);
	}
}

export default Birdy;
