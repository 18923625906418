const fredAndHisPosts =
{ 
  "userName" : "Fred Voll",
  "userId" : 3,
  "userPicture": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS3-vI1foTxugvrFoqEv8KQWAjAAzFvtsmB1Q&usqp=CAU",
  "userTagline": "Software Product",
  "userBio": "Fred description of the Hightlights",
  "userWebsite": "www.abstractthoughts.com",
  "userEmail": "example@mail.com",
  "userNumberOfPosts": 10,
  "userNumberOfFollowers": 20,
  "userNumberOfFollowing": 30,
  "userKind" : "FOLLOWING",

  "userPosts" : [        
    {
      "postId" : 1,
      "postKind" : "Popular 10",
      "post": "Fred Post 1. Simplicity is the ultimate sophistication said Leonardo Da Vinci once.", 
      "postImg": "https://javacalculationengine.com/imgs4oca/whistler-summer-2019.jpg",
      "time": "2",

      "commentsCount": 3,
      "comments": [
        {
          "userPicture": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS3-vI1foTxugvrFoqEv8KQWAjAAzFvtsmB1Q&usqp=CAU",
          "userName" : "Fred Voll",
          "comment" : "Fred Comment."
        },
        {
          "userPicture": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRa5abWqOKhszN_CZzT3SwRd9kHZ9niMIDDxA&usqp=CAU",
          "userName": "starlywave",
          "comment" : "starlywave Comment"
        },
        {
          "userPicture": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRa5abWqOKhszN_CZzT3SwRd9kHZ9niMIDDxA&usqp=CAU",
          "userName": "carolinekramerabstract",
          "comment" : "carolinekramerabstract Comment"
        }
      ], 
      
      "likesCount": "2",
      "likes": [
        {
          "userPicture": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRa5abWqOKhszN_CZzT3SwRd9kHZ9niMIDDxA&usqp=CAU",
          "userName": "starlywave" 
        },
        {
          "userPicture": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRa5abWqOKhszN_CZzT3SwRd9kHZ9niMIDDxA&usqp=CAU",
          "userName": "carolinekramerabstract"
        }
      ], 

      "sharesCount": 14
    },

    {
      "postId" : 2,
      "post": "Fred Post 2. Everything that happens, happens for a reason", 
      "postImg": "https://javacalculationengine.com/imgs4oca/whistler-20190703_130119.jpg",
      "time": "2",
      "commentsCount": 0,
      
      "likesCount": "2",
      "likes": [
        {
          "userPicture": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRa5abWqOKhszN_CZzT3SwRd9kHZ9niMIDDxA&usqp=CAU",
          "userName": "starlywave" 
        },
        {
          "userPicture": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRa5abWqOKhszN_CZzT3SwRd9kHZ9niMIDDxA&usqp=CAU",
          "userName": "carolinekramerabstract"
        }
      ],

      "sharesCount": 33, 
    },

    {
      "postId" : 3,
      "post": "Fred Post 3. There is something about complexity that draws us towards it", 
      "postImg": "https://javacalculationengine.com/imgs4oca/whistler.jpg",
      "userComment": "In life we never know how things turn out333.",
      "time": "2",
      
      "commentsCount": 66,
      
      "likesCount": "2",
      "likes": [
        {
          "userPicture": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRa5abWqOKhszN_CZzT3SwRd9kHZ9niMIDDxA&usqp=CAU",
          "userName": "starlywave"
        },
        {
          "userPicture": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRa5abWqOKhszN_CZzT3SwRd9kHZ9niMIDDxA&usqp=CAU",
          "userName": "carolinekramerabstract"
        }
      ],

      "sharesCount": 20,
    }
  ],

  "whoIsUserFollowing" : [
    {
      "userId" : 1,
      "userName" : "OlegVoll77",
      "userPicture" : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSYwLEw7-5pUK0Y5HAl5RD9-wMUE9QNAabyxg&usqp=CAU"
    },

    {
      "userId" : 4,
      "userName": "starlywave",
      "userPicture": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRa5abWqOKhszN_CZzT3SwRd9kHZ9niMIDDxA&usqp=CAU",
    }
  ]
}

export default fredAndHisPosts;