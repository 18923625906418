import Envir from '../components/Envir';
import ServiceConfig from '../components/ServiceConfig';

const Const = {
   ACE_PRODUCT : Envir.IS_ACE,
   LOGIN_URL :  ServiceConfig.LOGIN_URL,
   ACCOUNT_SERVICES_URL : ServiceConfig.ACCOUT_SERVICES_URL,
   OCA_SERVICES_URL : ServiceConfig.OCA_SERVICES_URL,
   MKT_SERVICES_URL : ServiceConfig.MKT_SERVICES_URL,
   INVOKE_SERVICE_PARMS : ServiceConfig.INVOKE_SERVICE_PARMS,
   USER_PICTURE_URL : ServiceConfig.USER_PICTURE_URL,
   USER_POST_URL : ServiceConfig.USER_POST_URL,
   USER_VIDEO_URL : ServiceConfig.USER_VIDEO_URL,
   VPS_SERVER_URL_AI : ServiceConfig.VPS_SERVER_URL_AI,
   VPS_SERVER_URL_DALEE : ServiceConfig.VPS_SERVER_URL_DALEE,
   VPS_SERVER_URL_ILLUSTRATE : ServiceConfig.VPS_SERVER_URL_ILLUSTRATE,
   VPS_SERVER_URL_VIDEO_ILLUSTRATE : ServiceConfig.VPS_SERVER_URL_VIDEO_ILLUSTRATE,
   VPS_SERVER_URL_SAVE_POST_INFO : ServiceConfig.VPS_SERVER_URL_SAVE_POST_INFO,
   ACE_COOKIE_KEY : "ace",
   ACE_TOKEN_COOKIE_KEY : "_ace",
   OCA_COOKIE_KEY : "oca",
   OCA_TOKEN_COOKIE_KEY : "_oca",
   OCA_ANY_USER_COOKIE_KEY : "a_oca",
   OCA_COMMENTS_COOKIE_KEY : "c_oca",
   OCA_PREFERECE_COOKIE_KEY : "p_oca",
   OCA_MKT_COOKIE_KEY : "m_oca",
   DELETE_ACCOUNT_COOKIE : 1,
   DELETE_TOKEN_COOKIE : 2,
   DELETE_COMMENTS_COOKIE : 3,
   DELETE_ANY_USER_COOKIE : 4,
   DELETE_PREFERENCES_COOKIE : 5,
   DELETE_MKT_COOKIE : 6,
   DELETE_ALL_COOKIES : 7,
   ACE_TN : 7,
   OCA_TN : 8,
   OK : 200,
   //
   // Login Service mode values
   STARTER_MODE : 1,
   SIGNUP_MODE : 2,
   LOGIN_MODE : 3,
   LOGOUT_MODE : 4,
   SEND_CODE_MODE : 5,
   GET_CODE_MODE : 6,
   RESET_PASSWORD_MODE : 7,
   DELETE_OCA_USER_ACCOUNT_MODE : 8, 
   NEW_PASSWORD_MODE : 9, 
   //
   // Account Service
   STORE_SETTINGS_MODE : 2,
   GET_USER_INFO_MODE : 3,
   //
   // OCA Service mode values
   STORE_USER_POST_MODE : 1, 
   STORE_USER_BIO_MODE : 2, 
   STORE_USER_PICTURE_MODE : 3, 
   FOLLOW_USER_MODE : 4, 
   STORE_USER_COMMENT_MODE : 5, 
   STORE_USER_LIKE_MODE : 6, 
   GET_USER_JSON_MODE : 7, 
   DELETE_USER_POST_MODE : 8, 
   DELETE_OCA_USER_MODE : 9, 
   EDIT_USER_POST_MODE : 10,
   REFRESH_COMMENTS_MODE : 11,
   REPORT_ABUSE_MODE : 12,
   GET_SHARED_POST_MODE : 13,
   STORE_USER_SHARE_MODE : 14,
   FIND_USERS : 15,
   GET_PARTIAL_USER_JSON : 16,
   STORE_USER_PREFERENCES : 17,
   STORE_AI_INFO : 18,
   STORE_HASH_TAGS : 19,
   FUNCTION_WARMER : 20, // not called from the client
   OCA_MAP_MARKET_PLACE_CUSTOMER : 21,
   OCA_CHARGE_MARKET_PLACE_CUSTOMER : 22,
   //
   INIT_VALUE : 0,
   CONTINUE_WITH_USER_VALIDATION : 1,
   CONTINUE_WITH_VALIDATED_USER : 2,
   STOP_PROCESSING_WITH_ERROR : 3,
   SERVICE_IS_NOT_INVOKED_YET : 0,
   SERVICE_IS_BEIGNG_INVOKED : 1,
   SERVICE_CALL_IS_COMPLETE : 2,
   NOT_CRITICALLY_BAD_COOKIE_WAS_SENT : 607,
   OLD_ACCESS_TOKEN_IN_DB_ERR : 609,
   NO_COOKIE_OR_TOKEN : 610,
   REQUEST_NEW_CLIENT_VERSION : 611,
   SYS_ERROR_APP_USER_NOT_FOUND : 831,
   USER_ERROR_MAX_VALUE : 700,
   SYS_ERROR_MIN_VALUE : 800,
   //
   SYS_ERROR_STORING_USER_POST_NO_USER_FOUND : 852,
   SYS_ERROR_FOLLOWING_USER_NO_USER_FOUND : 855,
   SYS_ERROR_STORING_USER_COMMENT_NO_POST_FOUND : 853,
   SYS_ERROR_STORING_USER_LIKE_NO_POST_FOUND : 854,
   SYS_ERROR_DELETING_USER_POST_NO_POST_FOUND : 856,
   SYS_ERROR_DELETING_USER_LIKE_NO_POST_FOUND : 863,
   SYS_ERROR_UPDATING_USER_POST_NO_POST_FOUND : 866,
   SYS_ERROR_POST_COMMENTS_REFRESHING_NO_POST_FOUND : 868,
   SYS_ERROR_STORING_POST_VIDEO : 887,
   //
   NO_ERROR_LEVEL : 0,
   USER_ERROR_LEVEL : 1,
   USER_ERROR_CRITICAL_LEVEL : 2,
   SYS_ERROR_LEVEL : 3,
   RECAPTURE_NUMBER_OF_TRIES : 1,
   TOO_MANY_TRIES : 2,
   WEAK : 0,
   GOOD : 1,
   STRONG : 2,
   I_AM_AT_HOME : 1,
   I_AM_IN_MY_PROFILE : 2,
   I_AM_IN_ANY_PROFILE : 3,
   I_AM_IN_COMMENTS : 4,
   I_AM_IN_ACCOUNT : 5,
   I_AM_IN_DELETE_ACCOUNT : 6,
   I_AM_IN_ABOUT : 7,
   I_AM_IN_SHARED_POST : 7,
   I_AM_IN_FIND_USERS : 8,
   I_AM_IN_PREFERENCES : 9,
   PROFILE : '/Profile',
   REMEMBER_ME : "Remember me",
   NON_EXISTANT_USER_ID : -1,
   REDUCED_OPACITY : 0.5,
   FULL_OPACITY : 1,
   KEEP_ME_SIGNED_IN : 2,
   SESSION_COOKIE_OPTION : 3,
   MAX_IMAGE_SIZE : 8388608, // 8 MB in bytes
   MAX_VIDEO_SIZE : 10485760, // 10 MB in bytes
   POST_IMG_MAX_WIDTH_LENDSCAPE : 617,
   POST_IMG_MAX_HEIGHT_PORTRAIT : 460,
   DELAY : 1000,
   IPHONE : 1,
   TABLET : 2,
   ANDROID : 3,
   MAC_DESKTOP : 4,
   IPAD : 5,
   WIN_DESKTOP : 6,
   SHOW_REMAINING_CHAR_NUM : 20,
   MOBIL_BUTTON_ACTIVATION_DELAY : 300,
   ESTERN_TIME_ZONE_OFFSET : 240,
   AI_MOTHLY_POST_LIMIT : 20,
   AI_MOTHLY_IMIGE_LIMIT : 10,
   EXPLORE : 'Explore',
   POSTS_ARE_LOADING : 'postsAreLoading',
   END_OF_POSTS : 'endOfPosts',
   PHONE_MEDIA_MAX : 431,
   PHONE_MEDIA_INTERVAL : "(min-width: 1px) and (max-width:430px)",
   TABLET_MEDIA_LANDSCAPE_POST_BUTTON_PESENCE_MIN : 479, // taken from Sky tablet
   MEDEATA_CONCIERGE : 'AIconcierge',
   WELCOME_TO_MEDEATA : 'Welcome to Medeata!',
   MEDEATA_GALERY : 'My Artwork Gallery on Medeata',
   MOBIL_POST_BUTTON_ID : "mobilDevicePostButtonID",
   POST_IMAGE_ALT : "social media platform post image",
   GALERY_IMAGE_ALT : "social media platform galery image",
   MEDEATA_IMAGE_ALT : "medeata social media platform image",
   IMAGE_ERROR_HEADER : "Sorry",
   UNKNOWN_IMAGE_TYPE : "Unknown image type",
   UNABLE_TO_PROCESS_THE_IMAGE : "Unable to process this image",
   IMAGE_SIZE_MAY_NOT_EXEED : "Max image size may not exceed ",
   VIDEO_SIZE_MAY_NOT_EXEED : "Max video size may not exceed ",
   ACCEPTED_IMAGE_TYPES : '.jpg, .jpeg, .png, .gif, .avif, .webp, .jfif, .mp4, .m4v, .m4a',
   ACCEPTED_FILE_TYPES : ['image/jpg', 'image/jpeg', 'image/png', 'image/svg+xml', 'image/avif', 'image/webp', 'image/gif', 
                          'image/jfif', 'video/mp4', 'video/x-m4v', 'video/*'], 
   DEFAULT_USER_PICTURE : 'https://medeata.com/img/default_user.jpg', // deprecated : now DefaultUserPicture is used
   ENTER_YOUR_EMAIL : "Please enter your email",
   ENTER_YOUR_RECOVERY_CODE : "You will receive a temporary code to verify here so you can reset your account password",
   VALID_USER_NAME_REQUIRED : "Please enter valid user name",
   VALID_EMAIL_REQUIRED : "Please enter valid email",
   VALID_PASSWORD_REQUIRED : "Please enter valid password",
   RETYPED_PASSWORD_NOT_EQUAL_PASSWORD : "Retyped password does not match the new password",
   VALID_CODE_REQUIRED : "Please enter valid verification code",
   INVALID_CREDENTIALS : "These credentials do not match our records, please try again",
   INVALID_PASSWORD : "This password doesn't match our records, please try again",
   INVALID_EMAIL : "This email doesn't match our records, please try again",
   INVALID_CODE : "This code doesn't match our records, please try again",
   CREDENTIALS_ALREADY_PRESENT : "Username or email is in use, please select a different one",
   SYS_ERROR_MESSAGE_CLOSE_BROWSER : "Please close your browser and try again",
   BAD_COOKIE_MESSAGE_CLOSE_BROWSER : "Invalid request, please close your browser and try again",
   NO_COOKIE_IN_HEADERS_CHANGE_SETTINGS_MESSAGE : "You may need to enable the cross site tracking on your device",
   TOO_MANY_TRIES_NEED_SIGN_OUT_MESSAGE : "Too many failed tries, to avoid security measures please sign out and try again",
   TOO_MANY_TRIES_NEED_CLOSE_BROWSER_MESSAGE : "Too many failed tries, to avoid security measures please close your browser and try again",
   CRITICAL_ERROR_MESSAGE_CONTACT_US : "Please contact us to resolve the issue",
   LOADING_MESSAGE : "Please wait...",
   GETTING_USER_INFO : "Collecting your info...",
   CHEKKING_USER_INFO : "Checking your credentials...",
   STORING_USER_INFO : "Saving your info, please wait...",
   CREATING_USER_PROFILE : "Creating your profile, please wait...",
   SIGNING_USER_OUT : "Signing you out of your session, please do not close your browser...",
   DELETING_USER_ACCOUNT : "Deleting your account, please do not close your browser...",
   GETTING_USER_ORDERS : "Collecting your orders...",
   EMAIL : "customerSupport@crystalprismsoftware.com"
};

export default Const;