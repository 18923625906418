import React, { Component } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Row, Col, Card } from 'reactstrap';
//the below is smaller image
import { MDBCardImage } from 'mdbreact';
import * as AiIcons from 'react-icons/ai';

import Navi from '../nav';
import GetLikes from './GetLikes';

class GetCollections extends Component {
  
    render() {

        const { loginUser } = this.props.location.params;// this is the user we receive from nav or CollectionsOfLoginUser (parameter) {loginUser}
        const userCollections =  loginUser.userCollections;

        //const whistler = userCollections.filter( collection => collection.collectionName == "Whistler");

        console.log("#########  I am in GetCollections ############");
        console.log( userCollections );
        // console.log( whistler );
 
        return(
            <>
            <Navi loginUser = { loginUser } />
                
                <div className='after-app-wrap-center-div' >
               
                <h5>My Collections</h5>

                    <Tabs>

                        <TabList className = "tabs">
                            { userCollections.map(tab =>
                                <Tab key={tab.collectionId} className = "tab"> 
                                    <Card className = "collection-card">
                                    <Row>
                                        <Col xs={7} >
                                            <MDBCardImage className="img-fluid left" alt="your gallery" src= {tab.collectionImg } /> 
                                        </Col>
                                        <Col xs={5} style={{margin:'auto'}}>
                                            <p className="mb-0"> {tab.collectionName} </p>
                                        </Col>
                                        </Row>
                                    </Card> 
                                </Tab>,
                            )}
                        </TabList>

                    
                        { userCollections.map(tabcontent =>
                            <TabPanel key={tabcontent.collectionId} className = "tabcontent"> 
                                {/* {tabcontent.collectionName} */}
                                { tabcontent.collectionPosts.map (post => 
                                    <Card key={ post.collectionPostId }> 
                                        <Row className="row-margin-0 p-2">
                                            <img src= {post.userPicture} className="avatar" alt="Avatar" />
                                            <div className="col-10">
                                                <p className="ml-0 mb-0 bold">{post.userName}</p>
                                                <p className="small">{post.time}</p>
                                            </div>
                                            
                                        </Row>
                                        <img src= {post.postImg} alt=""></img>
                                        <p className = "pl-2 mb-0"> {post.post} </p> 

                                        <Row className="icon-div p-2">
                                            <Col xs={6} className="mobile-col">
                                                <AiIcons.AiOutlineHeart className="home-icons-medium left" />
                                                <GetLikes clickedPost = { post }/>                                                        
                                            </Col>
                                            <Col xs={4} className="mobile-col">
                                                {/* <Link to={{ pathname: '/GetComments', params: { userIfollowPost } }}>
                                                    <FaIcons.FaRegComment className="home-icons left" />
                                                    <p className="icon-count medium mb-0">View All {userIfollowPost.commentsCount} Comments</p>
                                                </Link> */}
                                            </Col>
                                            
                                            <Col xs={2} className="mobile-col">
                                                <AiIcons.AiOutlineShareAlt className="home-icons-medium left" />
                                                <p className="icon-count medium mb-0"> {post.sharesCount} </p>
                                            </Col>                    
                                        </Row>

                                    </Card>) }
                            </TabPanel>,
                        )}
                       
                    </Tabs> 
                    
                </div> 
            </>
            );
        }
    }
  
  export default GetCollections;
