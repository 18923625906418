import { MDBBtn } from 'mdbreact';
import * as AiIcons from 'react-icons/ai'
import GetYourPostBase from './GetYourPostBase';
import Const from '../Const';
import { invokeService, toolTippy } from '../Utils.js';
import { removeMobilPostButton, addMobilPostButton } from '../MobilButtonUtils.js';

export default class UpdateYourPost extends GetYourPostBase {
  constructor(props) {
    super(props);
    this.state.autoFocus = false; 
    this.handleClick = this.handleClick.bind(this);
    this.mobilDeviceDetected = false;
  }

  async handleSubmit(event) {
    let post = this.state.value; // post value actualy

    event.preventDefault();
    this.processUserPostHashTags(post);
    this.disableModalWindow(true);
    await this.editPostInDB();
    if (this.state.serviceErrorLevel === Const.NO_ERROR_LEVEL) {
      this.replaceEditedPostInUserPosts();
    }
    this.disableModalWindow(false);
    this.toggle();
  }

  async editPostInDB() {
    let post = this.state.post;
    let value = this.state.value;
    let data = {
          mode : Const.EDIT_USER_POST_MODE,
          post : (value.length <= this.MAX_LENGTH) ? value : value.substring(0, this.MAX_LENGTH),
          post_id : post.postId,
    };

    await invokeService(Const.OCA_SERVICES_URL, data, this); 
	}

  replaceEditedPostInUserPosts() {
    let loginUser = this.state.loginUser;
    let posts = loginUser.userPosts;
    let editedPost = this.state.post;
    let value = this.state.value;
    
    editedPost.post = value;
    for (let post of posts) {
      if (post.postId === editedPost.postId) {
        post = editedPost;
        break;
      }
    }
    loginUser.userPosts = posts;
    this.state.parent.setState({ loginUser : loginUser, canProcessContactUs : false });
  }

  setStateAttributes() {
    addMobilPostButton(this);
  }

  getPostContent() {
    return this.state.post.post;
  }

  handleClick() {
    removeMobilPostButton(this);
    this.setState({ autoFocus : true });
    this.toggle();
    this.higlightSelectedElement('button2');
  }

  getIckon() {
    return (
      <div className="right" onClick={this.handleClick} autoFocus={false}>
        <div className="left">
          { toolTippy(AiIcons.AiOutlineEdit, "Edit Post", "right", [Const.DELAY, 0], "home-icons-medium pointer-view", null) } 
        </div>
      </div>
    )
  }

  getButton() {
    return (
      (this.state.noPostText && this.state.imageIsNotUploaded) || this.textOverflow
      ? <MDBBtn id="dialog-button" className="btn btn-primary pointer-noview"  disabled = { true }>Update</MDBBtn>
      : <MDBBtn id="dialog-button" className="btn btn-primary" type="submit" value="Submit">Update</MDBBtn>
    )
  }

  getHeader() {
    return "Update Post";
  }
}