//this not needed after "import Themes from '../data/DataThemes.js';" is part of Home"

import React, { Component } from 'react';
import { Row, Card } from 'reactstrap';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import Themes from '../data/DataThemes.js';
import Navi from '../nav';

class GetThemes extends Component {

    constructor(props){
        super(props);
        this.state = {            
         Themes :Themes            
        };
      }
    
    render() {
        //const { params } = this.props.location;
        const { loginUser } = this.props.location.params;// this is the login user we receive from Home (parameter) {loginUser}

        const {Themes} = this.state;

        console.log("QQQQQQQQQQQQQQQQQQQQQQQQQQQQQ I am in GetThemes QQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQ");

        //const { loginUserFollowingOrFollowers } = this.props; 12.13 comment, map loginUserFollowingOrFollowers
        // const { loginUser } = this.props; // this is the user post we receive from Profile (parameter) 
        // const loginUserFollowingThesePeople = loginUser.following;

        // console.log( this.props );
        // console.log( loginUser );
        // console.log( loginUser.userName );
        // console.log( loginUserFollowingThesePeople );
       

        return(
        <>
            <Navi loginUser = { loginUser } />
            <div className = "after-app-wrap-center-div">
               
                <Tabs>
                    <div className="right-div" >
                        <TabList className = "">
                            { Themes.map(tab =>
                                <Tab  key={tab.themeId} className = "theme"> {tab.themeName} </Tab>  
                            )}
                        </TabList>
                    </div>

                    <div className="post-div" >
                    <h5 className="pl-2">Popular Themes</h5>
                        { Themes.map(tabcontent =>
                            <TabPanel key={tabcontent.themeId} className = ""> 
                                { tabcontent.posts.map (post => 
                                    <Card key={ post.themePostId }> 
                                        <Row className="row-margin-0 p-2">
                                            <img src= {post.userPicture} className="avatar" alt="Avatar" />
                                            <div className="col-10">
                                                <p className="ml-0 mb-0 bold">{post.userName}</p>
                                                <p className="small">{post.time}</p>
                                            </div>    
                                        </Row>
                                        <img src= {post.postImg} alt="postImage"></img>
                                        <p className = "pl-2 mb-0"> {post.post} </p> 
                                    </Card>) }
                            </TabPanel>
                        )}
                    </div>
                   
                  </Tabs>
            </div>
        </>
        );
    }
  }
  
  export default GetThemes;