import React, { PureComponent } from 'react';
import { Row } from 'reactstrap';
import { MDBContainer, MDBBtn, MDBModal, MDBModalBody } from 'mdbreact';
import * as MdIcons from 'react-icons/md';
import * as FaIcons from 'react-icons/fa';
import Const from '../Const';
import { invokeService, messageModalWindow, deletePostInMemory, getUserPictureClassName, toolTippy } from '../Utils.js';
import { modalPopUpScrollProcessor } from '../ScrollUtils.js';

export default class DeleteYourPost extends PureComponent {
    constructor(props) {
        super(props);
        const { deleteYourPostParams } = this.props; 
        const { loginUser, post, that } = deleteYourPostParams;
        this.state = {
          value: '',
          modal: false,
          messageModal: false,
          loginUser : loginUser,
          post : post,
          parent : that,
          header : '',
		  text : '',
          disable : false,
          //
          serviceInvocationStatus : Const.SERVICE_IS_NOT_INVOKED_YET,
          serviceStatusCode : Const.INIT_VALUE,
          serviceErrorLevel : Const.NO_ERROR_LEVEL,
          serviceInvocationError : false,
          flag : Const.INIT_VALUE
        };
    
        this.handleSubmit = this.handleSubmit.bind(this);
        this.allowToggle = true;
        this.SUBMIT = 'Confirm';
        this.DELETE_YOUR_POST = 'Delete Your Post?';
        this.overflowScroll = true;
    }

    async handleSubmit(event) {
        event.preventDefault();
        this.disableModalWindow(true);
        await this.deleteMyPostInDB();
        this.disableModalWindow(false);
        this.toggle();
        if (this.state.serviceErrorLevel === Const.NO_ERROR_LEVEL) {
            this.deleteMyPostInMemory();
        }
    }

    async deleteMyPostInDB() {
        let data = {
            mode : Const.DELETE_USER_POST_MODE,
            post_id : this.state.post.postId,
        };
        
        await invokeService(Const.OCA_SERVICES_URL, data, this);
    }

    deleteMyPostInMemory() {
        let loginUser = this.state.loginUser;
        let parent = this.state.parent;
        let myPost = this.state.post;

        deletePostInMemory(loginUser.userPosts,  myPost);
        loginUser.userNumberOfPosts--;
        parent.setState({ loginUser : loginUser, canProcessContactUs : false });
    }

    toggle = () => {
        if (this.allowToggle) {
          modalPopUpScrollProcessor(this);
          this.setState({ modal : !this.state.modal });
        }
     }

    disableModalWindow(disable) { 
        let header = document.getElementById('header');
        let submitButton = document.getElementById('dialog-button');
        let xCloser = document.getElementById('X');
        let img = document.getElementById('img');
        let disableEnable;
        
        if (header && submitButton && xCloser && img) {
            disableEnable = disable ? true : false;
            header.style.opacity = disable ? Const.REDUCED_OPACITY : Const.FULL_OPACITY;
            xCloser.style.opacity = disable ? Const.REDUCED_OPACITY : Const.FULL_OPACITY;  
            img.style.opacity = disable ? Const.REDUCED_OPACITY : Const.FULL_OPACITY;  
            this.allowToggle = disable ? false : true;
            this.setState({ disable : disableEnable });
        }
    }

    render() {
        const { loginUser, modal } = this.state; 

        if (!loginUser) {
            return;
        }
        return(
            <>
                <div className="right" onClick={this.toggle}>
						<div className="left">
                            { toolTippy(FaIcons.FaRegTrashAlt, "Delete Post", "right", [Const.DELAY, 0], "home-icons-medium pointer-view", null) }
						</div>
                </div>
                {
                    modal && 
                    <MDBContainer>
                     <MDBModal isOpen={this.state.modal} toggle={this.toggle} overflowScroll={this.overflowScroll} autoFocus={false}>
                        <div className="modal-myheader" id="header">
                                <MdIcons.MdClose id="X" className="modal-close left" onClick={this.toggle} />
                                <p> {this.DELETE_YOUR_POST} </p>
                        </div>
                        
                        <MDBModalBody id="main">       
                                <form onSubmit={this.handleSubmit} className="mypostform">  
                                    <Row> 
                                        <div className={getUserPictureClassName(loginUser)}>
                                                <img id='img' src= { loginUser.userPicture} alt="Avatar" />
                                        </div>
                                    </Row>
                                    <Row className="media-align">
                                        <MDBBtn id="dialog-button" className=" btn btn-primary mt-3" type="submit" disabled = { this.state.disable } value="Submit">{this.SUBMIT}</MDBBtn>
                                    </Row>
                                </form>
                        </MDBModalBody> 
                     </MDBModal>
                    </MDBContainer>
                }
              
                {
                    messageModalWindow(this, this.state.header, this.state.text)
                }
            </>   
        );
    }
}