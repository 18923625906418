import React from 'react';
// import ReactDOM from "react-dom";
// import ImageZoom from 'react-image-zoom';
// import InnerImageZoom from 'react-inner-image-zoom';
// import 'scrollable-component'; 
// import * as unicodeEmoji from 'unicode-emoji';

// import * as nodejsunicodeEmoji from 'unicode-emoji';
// import {EmojiPickerElement}  from 'unicode-emoji-picker';
// import "https://cdn.skypack.dev/emoji-picker-element@^1";
// import '../css/userPicture.css';
// import Const from './Const';
// import { invokeService, dateToNumberDate, messageModalWindow, readLocalImageFile, extractImageParts } from './Utils.js';

// // const ImgUpload =({ onChange, src })=>{
// // 	return (
// // 			<label className="custom-file-upload fas">
// // 			<div className="img-wrap img-upload" >
// // 				<div className="avatar-big">
// // 					<img alt="Edit" className="" src={src}/>
// // 				</div>
// // 			</div>
// // 			<input id="photo-upload" type="file" onChange={onChange} 
// // 				accept={Const.ACCEPTED_IMAGE_TYPES}
// // 			/> 
// // 			</label>
// // 	       );
// // }
// const oleg = 'oleg';



export default class    TestEditUserPicture extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			sayHello : false
		};
        this.setSayHello = this.setSayHello.bind(this);
   

	}

    

	
	setSayHello(option) {
        this.setState({sayHello : option});
    }



	render() {
    //     this.emojis = unicodeEmoji.getEmojisGroupedBy('category', {versionAbove: newValue});
    // const props = {
    //     width: 617,
    //     height: 600,
    //     zoomWidth: 770,
    //     // img: "http://malaman.github.io/react-image-zoom/example/1.jpg"
    //     img: "https://ocai.s3.us-east-2.amazonaws.com/pst/minimalista/1659402661",
    //     zoomPosition : 'original',
    //     scale : 1/10
    //   };
		
		return (
			<div>
            <br></br>

        
                <div className="App">
                    {/* <ImageZoom {...props}  alt="Avatar"/> */}
                    {/* <InnerImageZoom src="https://ocai.s3.us-east-2.amazonaws.com/pst/minimalista/1659402661" 
                                    zoomScale={2}
                                    moveType={"drag"}
                                    /> */}
				</div>
                {/* <h5>Gopa</h5> */}
               
                {/* Background-image */}
                {/* <div className="gopa1">
                    <img src="https://ocai.s3.us-east-2.amazonaws.com/prf/chessmaster/1657239545" class="" alt="Avatar"/>
				</div> */}

                {/* <br></br> */}
               
                {/* No border*/}
                {/* <div className="gopa" style={{border: 'none'}}>
                    <img src="https://ocai.s3.us-east-2.amazonaws.com/prf/chessmaster/1657239545" alt="Avatar"/>
				</div> */}

                {/* <br></br> */}

                 {/* Verical image */}
                {/* <div className="gopa">
                    <img src="https://ocai.s3.us-east-2.amazonaws.com/prf/chessmaster/1657239545" alt="Avatar"/>
				</div> */}

                {/* <br></br> */}

                {/* Horisontal image*/}   
                {/* <div className="gopa">
                    <img src="https://ocai.s3.us-east-2.amazonaws.com/prf/svolkovin2/1656811578" alt="Avatar"></img>
				</div> */}

                {/* <br></br> */}

                {/* white image*/}
                {/* <div className="gopa">
                    <img alt="Edit" class="" src="https://ocai.s3.us-east-2.amazonaws.com/prf/carolinekramerabstract/1657674826"></img>
                </div> */}

                {/* <br></br> */}

                {/* just Image */}
                {/* <img src="https://ocai.s3.us-east-2.amazonaws.com/prf/chessmaster/1657239545" className="gopa" alt="Avatar"/> */}

{/* <br></br> */}


                {/* <h5>Avatar-big</h5> */}

                {/* avatar-big */}
                {/* vertical */}
                {/* <div className="avatar-big">
                    <img src="https://ocai.s3.us-east-2.amazonaws.com/prf/chessmaster/1657239545"  alt="Avatar"/>
				</div> */}

                {/* <br></br> */}

                {/* horisontal */}
                {/* <div className="avatar-big">
                    <img src="https://ocai.s3.us-east-2.amazonaws.com/prf/svolkovin2/1656811578" alt="Avatar"></img>
				</div> */}

                {/* <br></br> */}

                {/* white */}
                {/* <div className="avatar-big">
                    <img alt="Edit" class="" src="https://ocai.s3.us-east-2.amazonaws.com/prf/carolinekramerabstract/1657674826"></img>
                </div> */}

                {/* <label className="custom-file-upload fas">
                    <div className="img-wrap img-upload" >
                        <div className="avatar-big">
                            <img alt="Edit" className="" src={src}/>
                        </div>
                    </div>
                    <input id="photo-upload" type="file" onChange={onChange} 
                        accept={Const.ACCEPTED_IMAGE_TYPES}
                    /> 
                </label> */}

			</div>
		)
	}
}