import Const from '../components/Const';
import { setAIisDownMessage, setSavePostInfoDownMessage, setAIErrorMessage, setSavePostInfoErrorMessage } from '../components/Utils.js';

export async function copyToClipBoard(text) {
  try {
      if (navigator.clipboard) {
          await navigator.clipboard.writeText(text);
      }
      else {
          // this was done for craker because navigator.clipboard.writeText requires https ot localhost
          // (craker works with http). Will keep it for some time and then remove
          await alternativeClipBoardCopy(text);
      }
    }
    catch (error) {
      console.log(error);
    }
}

export function alternativeClipBoardCopy(text) {
  let textArea = document.createElement("textarea");
  
  textArea.value = text;
  // make the textarea out of viewport
  textArea.style.position = "fixed";
  textArea.style.left = "-999999px";
  textArea.style.top = "-999999px";
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();
      
  return new Promise((res, rej) => {
      // here the magic happens
      document.execCommand('copy') ? res() : rej();
      textArea.remove();
  });
}

export async function invokeServer(serviceUrl, data, that) {
  let response;
  let parms = {};
  let serverResult = null;
  let serverError = null;
  
  Object.assign(parms, Const.INVOKE_SERVICE_PARMS);
  if (parms.hasOwnProperty('credentials')) {
    delete parms.credentials;
  }
  // parms.headers['Access-Control-Allow-Origin'] = '*';
  await that.setState({
    serviceInvocationStatus :  Const.SERVICE_IS_BEIGNG_INVOKED
  });
  parms.body = JSON.stringify(data);
  await fetch(serviceUrl, parms)
    .then(res => res.json())
    .then(
      (result) => {
        serverResult = result;
        serverResultLogic(result, that, serviceUrl)
      },
      (err) => {
        serverError = err;
        serverErrorLogic(serverError, that, serviceUrl);
      }
  )
  response = {
    result : serverResult,
    error : serverError
  }
  return response;
}

export async function serverResultLogic(result, that, serviceUrl) {
  let  errorLevel = Const.NO_ERROR_LEVEL;

  console.log(result);
  if (result.statusCode !== Const.OK) {
      errorLevel = Const.SYS_ERROR_LEVEL;
  }
  if (result.statusCode !== Const.OK) {
    errorLevel = Const.SYS_ERROR_LEVEL;
    if (serviceUrl.equalIgnoreCase === Const.VPS_SERVER_URL_SAVE_POST_INFO) {
      await setSavePostInfoErrorMessage(that);
    }
    else {
      await setAIErrorMessage(that);
    }
  }
  that.setState({
      serviceInvocationStatus :  Const.SERVICE_CALL_IS_COMPLETE,
      serviceStatusCode : result.statusCode,
      serviceErrorLevel : errorLevel 
  });
}

export async function serverErrorLogic(err, that, serviceUrl) {
  console.log(err);
  if (serviceUrl === Const.VPS_SERVER_URL_SAVE_POST_INFO) {
    await setSavePostInfoDownMessage(that);
  }
  else {
    await setAIisDownMessage(that);
  }
  that.setState({
    serviceInvocationError : true
  });
}