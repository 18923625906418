import { MDBBtn, MDBModal, MDBModalBody } from 'mdbreact';
import  {Button } from 'reactstrap';
import * as MdIcons from 'react-icons/md';

export function getWelcomeGreting(that) {
  return (
        that.state.modal &&
        <MDBModal isOpen={that.state.modal} toggle={that.toggle} size="sm" centered >										
          <MDBModalBody>  
            <h5 className="mb-3">Welcome to medeata.com</h5> 
            <p className="mb-3">
              If you agree with our 
              <MDBBtn className="btn-imitation" onClick={that.toggleAgreement}>policies</MDBBtn> 
              please select your profile type
            </p>                 
            <Button id='p' onClick={that.handlePesonalOrCompanyClick} className="btn btn-outline-primary mr-3">Personal</Button>
            <Button id='c' onClick={that.handlePesonalOrCompanyClick} className="btn btn-outline-primary">Business</Button>
          </MDBModalBody>										
        </MDBModal>
  )
}

export function getPolicy(that) {
  const datapoints = [
    { point: '- No Hate, No War, No Terror, No Abuse or Harmful information of any kind for anyone in the community, otherwise your account will be suspended without notice.' },
    { point: '- This platform was created for sharing ideas and creativity.' },
    { point: '- You are solely responsible for the use of these Services and for any Content you provide, including compliance with applicable laws, rules, and regulations. You should only provide Content that you are comfortable sharing with others.' },
    { point: '- We do not endorse, support, represent or agree with any Content shared and do not bare responsibility for Content. We may not monitor or control the Content posted via the Services and, we cannot take responsibility for such Content.'},
    { point: '- You might be exposed to the opinions that you do not share or that may be otherwise deceptive.'},
    { point: '- All Content is the sole responsibility of the creator  who originated such Content.'},
    { point: '- We reserve the right to stop (permanently or temporarily) providing the Services or any features within the Services to you or to users generally if we feel that they go against these guidelines.'}
  ];

  return (
            that.state.modalAgreement &&
            <MDBModal isOpen={that.state.modalAgreement} toggle={that.toggleAgreement} size="lg" className="conditions modal-company-individual">										
              <MDBModalBody className="scroll-conditions"> 												
                <h5 className="mb-3">Hello New Friend! 
                    <MdIcons.MdClose className="modal-close right pointer-view" onClick={that.toggleAgreement}/>
                </h5>
                
                <p className="mb-3">By creating your account you agree with our Terms and Conditions:</p>
                
                <h6>Content and Services</h6>
                { 
                  datapoints.map ((datapoint, ind) =>
                    <div key={ind}>{ datapoint.point} </div>
                  )
                }

                <h6>Cookies</h6>
                <p>We use cookies technology to keep you logged in, to save and honor your preferences as well as operate our services. 
                    Cookies are small files placed on your computer as you browse the web or use a web-enabled app.
                </p>

                <h6>Disclaimer</h6>
                <p>
                    Medeata is not liable for any indirect, incidental, special, consequential or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from your access to or use of or inability to access or use the services; any conduct or content of any third party on the services, including without limitation, any defamatory, offensive or illegal conduct of other users or third parties; 
                    any content obtained from the services; or unauthorized access, use or alteration of your transmissions or content. 
                </p>
                <p> </p>													
              </MDBModalBody>										
            </MDBModal>
  )
}