const starlywaveAndHerPosts =
{
  "userName" : "starlywave",
  "userId" : 4,
  "userPicture": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRa5abWqOKhszN_CZzT3SwRd9kHZ9niMIDDxA&usqp=CAU",
  "userTagline": "Software Product",
  "userBio": "Starlywave description of the Hightlights",
  "userWebsite": "www.abstractthoughts.com",
  "userEmail": "example@mail.com",
  "userNumberOfPosts": 10,
  "userNumberOfFollowers": 20,
  "userNumberOfFollowing": 30,
  "userKind" : "Popular 10",

  "userPosts" : [         
    {
      "postId" : 1,
      "post": "Starlywave Post 1. Simplicity is the ultimate sophistication said Leonardo Da Vinci once.", 
      "postImg": "https://javacalculationengine.com/imgs4oca/asp-20190314_110316.jpg",
      "postKind" : "Top 10",
      "time": "2",
      "commentsCount": 0,
      
      "likesCount": "2",
      "likes": [
          {
            "userPicture": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRa5abWqOKhszN_CZzT3SwRd9kHZ9niMIDDxA&usqp=CAU",
            "userName": "starlywave"
          },
          {
            "userPicture": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRa5abWqOKhszN_CZzT3SwRd9kHZ9niMIDDxA&usqp=CAU",
            "userName": "carolinekramerabstract"
          }
        ],
      
      "sharesCount": 14,
    },

    {
       "postId" : 2,
       "post": "Starlywave Post 2. Everything that happens, happens for a reason", 
       "postImg": "https://javacalculationengine.com/imgs4oca/aspen-2.jpg",
       "time": "2",
       
       "commentsCount": 0,
       
       "likesCount": "2",
       "likes": [
        {
          "userPicture": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRa5abWqOKhszN_CZzT3SwRd9kHZ9niMIDDxA&usqp=CAU",
          "userName": "starlywave" 
        },
        {
          "userPicture": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRa5abWqOKhszN_CZzT3SwRd9kHZ9niMIDDxA&usqp=CAU",
          "userName": "carolinekramerabstract"
        }
      ],

      "sharesCount": 33,  
    },

    {
      "postId" : 3,
       "post": "Starlywave Post 3. There is something about complexity that draws us towards it", 
       "postImg": "https://javacalculationengine.com/imgs4oca/asp-20190313_110841.jpg",
       "userComment": "In life we never know how things turn out333.",
       "time": "2",
      
       "commentsCount": 66,
      
       "likesCount": "2",
       "likes": [
        {
          "userPicture": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRa5abWqOKhszN_CZzT3SwRd9kHZ9niMIDDxA&usqp=CAU",
          "userName": "starlywave"
        },
        {
          "userPicture": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRa5abWqOKhszN_CZzT3SwRd9kHZ9niMIDDxA&usqp=CAU",
          "userName": "carolinekramerabstract"
        }
      ],

      "sharesCount": 20,
   }
  ],

  "whoIsUserFollowing" : [
    {
      "userId" : 1,
      "userName" : "OlegVoll77",
      "userPicture" : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSYwLEw7-5pUK0Y5HAl5RD9-wMUE9QNAabyxg&usqp=CAU"
    },
    {
      "userId" : 3,
      "userName" : "Fred Voll",
      "userPicture" : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSYwLEw7-5pUK0Y5HAl5RD9-wMUE9QNAabyxg&usqp=CAU"
    }
  ] 
}

export default starlywaveAndHerPosts;