import React from "react";
import '../css/bootstrap.css';
import {Button, Form, FormGroup, Input} from 'reactstrap';
// import Logo from "../images/cp-png-transparent-64x64.png";
import Envir from '../components/Envir';
import Const from '../components/Const';
import { invokeService, messageModalWindow } from './Utils.js';

const initialState = {
  code: "",
  codeError: "",
  messageModal : false,
  //
  serviceInvocationStatus : Const.SERVICE_IS_NOT_INVOKED_YET,
  serviceStatusCode : Const.INIT_VALUE,
  serviceErrorLevel : Const.NO_ERROR_LEVEL,
  serviceInvocationError : false,
  flag : Const.INIT_VALUE
};

export default class EnterCode extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  handleChange = event => {
    this.setState({
      [event.target.name] : event.target.value.trim(),
      codeError: ""
    });
  };

  validate = () => {
    let codeError = "";
    let ret;

    if (this.state.code.length !== 6) {
      codeError = Const.VALID_CODE_REQUIRED;
      ret = false;
    }
    else {
      ret = true;
    }
    this.setState({ codeError });
    return ret;
  };

  handleSubmit = async event => {
    let isValid;

    event.preventDefault();
    isValid = this.validate();
  
    if (isValid) {
      await this.getCodeFromDB();
      if (this.state.serviceErrorLevel === Const.NO_ERROR_LEVEL) {
        window.location = Envir.LOGIN_FOLDER + 'resetpassword';
      }
      else if (this.state.flag === Const.TOO_MANY_TRIES) {
        this.setState({ 
          codeError : Const.TOO_MANY_TRIES_NEED_CLOSE_BROWSER_MESSAGE,
          serviceInvocationStatus : Const.SERVICE_IS_NOT_INVOKED_YET
        });
      } 
      else if (this.state.serviceErrorLevel === Const.USER_ERROR_LEVEL) {
        this.setState({
          codeError : Const.INVALID_CODE,
          serviceInvocationStatus : Const.SERVICE_IS_NOT_INVOKED_YET
       });
      }
    }
  };

  async getCodeFromDB() {
    let  data = {
      // Get code
      "code" : this.state.code,
      "mode" : Const.GET_CODE_MODE
    };

    await invokeService(Const.LOGIN_URL, data, this);
  }

  render() {
    const { serviceInvocationError, serviceErrorLevel, serviceInvocationStatus, serviceStatusCode } = this.state;
    
    if (serviceErrorLevel === Const.USER_ERROR_CRITICAL_LEVEL) {
			return (
			  messageModalWindow(this, this.state.header, this.state.text)
			)
		}
    else if (serviceInvocationError || (serviceErrorLevel === Const.SYS_ERROR_LEVEL)) {
			return (
				messageModalWindow(this, this.state.header, this.state.text)
			)
		} 
    else if (serviceStatusCode === Const.NOT_CRITICALLY_BAD_COOKIE_WAS_SENT) {
      return <div>{Const.BAD_COOKIE_MESSAGE_CLOSE_BROWSER}</div>;
    } 
    else if (serviceInvocationStatus !== Const.SERVICE_IS_NOT_INVOKED_YET) {
      return <div>{Const.CHEKKING_USER_CODE}</div>;
    } else {
      return (
        <div className="App-div">
          <div className="after-app-wrap-center-login-div-walk enterCode" >
            <div>
              <div className="login-form">
                {/* <Row className = "row-margin-0">
                  <img className = "left mb-5" width = "50px" src = { Logo } alt="ACE4J"/>
                </Row> */}
                <Form onSubmit={this.handleSubmit}>
                  <FormGroup >
                  <h2 className = "text-left">Check your email</h2>
                  <p className = "text-left">{Const.ENTER_YOUR_RECOVERY_CODE}</p>
                    <div className="login-form-row-no-label">
                      <Input className="x-input"
                        name="code" 
                        placeholder="Enter your verification code"
                        required
                        value={this.state.code}
                        onChange={this.handleChange}
                      />
                      <div className = "error-message">
                        <span>{ this.state.codeError }</span>
                      </div>
                    </div>
                    <Button className="left" color ="info" type="Submit"> Verify
                    </Button>
                  </FormGroup>
                </Form>
              </div> 
            </div>
          </div> 
        </div>   
      );
    }
  }
}