import ReactPlayer from 'react-player';
import Const from '../components/Const';

export function renderVideoPlayer(url, videoClass, props = {}) {
  return (
    <div>
      <ReactPlayer className={`react-player ${videoClass}`} 
        playsinline
        url={url}
        controls
        muted={true}
        {...props}
      />
    </div>
  );
}

export function renderVideoPlayerWrapperM(post, playing, loop) {
  let videoUrl = post.postVideo;
  let width;
  let height;
  let adjustment = 1.5;
  let videoPortretHeight = Const.POST_IMG_MAX_HEIGHT_PORTRAIT;
  let videoLendscapeWidth = Const.POST_IMG_MAX_WIDTH_LENDSCAPE - adjustment;

  if (post.imgHeight >= post.imgWidth) {
    height = videoPortretHeight + 'px';
    width = ((post.width / post.height) * videoPortretHeight) + 'px';
  }
  else {
    height = ((post.imgHeight / post.imgWidth) * videoLendscapeWidth) + 'px';
    width = videoLendscapeWidth + 'px';
  }
  return renderVideoPlayer(videoUrl, '', { 
                            playing: playing, 
                            loop: loop,
                            width: width,
                            height: height
                          });
}

export function renderVideoPlayerWrapper(videoUrl, playing, loop, that) {
  let { file } = that.state;
  let uploadImgOrVideo = document.getElementById('uploadImgOrVideoId');
  let main = document.getElementById('main');
  let rect = uploadImgOrVideo.getBoundingClientRect();
  let iconsRectangleHeight = 50; // enough to cover below-post-text-area-icons height
  let bottomSafePadding = 16;
  // this is the available height of the area below text area icons
  let availableHeight = main.offsetHeight - rect.top - iconsRectangleHeight - bottomSafePadding;
  let availableWidth = main.offsetWidth;
  let height = availableHeight;
  let width = (file.width / file.height) * height;
  let videoClass = 'upload-video';

  if (width > availableWidth) {
    width = 100; // switch to %
    height = file.height / file.width * width;
    width += '%';
    height += '%';
  }
  else {
    width += 'px';
    height += 'px';
  }
  return renderVideoPlayer(videoUrl, videoClass, { 
                            playing: playing, 
                            loop: loop,
                            width: width,
                            height: height
                          });
}