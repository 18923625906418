const fakeDBresponse =

    {
        "userId" : 1,
        "userName" : "OlegVoll77",
        "userPicture" : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSYwLEw7-5pUK0Y5HAl5RD9-wMUE9QNAabyxg&usqp=CAU",
        "userTagline": "Experimantal Group",
        "userBio": "Oleg description of the Hightlights",
        "userWebsite": "www.javacalculationengine.com",
        "userEmail": "example233@mail.com",
        "userNumberOfPosts": 1000,
        "userNumberOfFollowers": 10,
        "userNumberOfFollowing": 300,
        "userKind" : "FOLLOWING",
        "userCond" : true,
        "topPosts" : [
                {
                  "userName" : "mirkavanderoe",
                  "userPicture": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR-JDp6VgvMkh8rqdpUFcMm2FsE_y8XSGwIVw&usqp=CAU",
                  "postId" : 1,
                  "post": "Mirka Post 1. Simplicity is the ultimate sophistication said Leonardo Da Vinci once. llllllllllllllllll", 
                  "postImg": "https://javacalculationengine.com/imgs4oca/whistler.jpg",
                  "time": "21 July 2021",
                
                  "commentsCount": "3",
                  "comments": [
                    {
                      "userId": 3,
                      "userPicture": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS3-vI1foTxugvrFoqEv8KQWAjAAzFvtsmB1Q&usqp=CAU",
                      "userName" : "fredvoll",
                      "comment" : "Fred Comment.",
                      "commentId" : 1
                    },
                    {
                      "userId": 4,
                      "userPicture": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRa5abWqOKhszN_CZzT3SwRd9kHZ9niMIDDxA&usqp=CAU",
                      "userName": "starlywave",
                      "comment" : "starlywave Comment",
                      "commentId" : 2
                    },
                    {
                      "userId": 6,
                      "userPicture": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRa5abWqOKhszN_CZzT3SwRd9kHZ9niMIDDxA&usqp=CAU",
                      "userName": "carolinekramerabstract",
                      "comment" : "carolinekramerabstract Comment",
                      "commentId" : 3
                    }
                  ],  

                  "likesCount": "2",
                  "likes": [
                    {
                      "userId": 1,
                      "userPicture" : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSYwLEw7-5pUK0Y5HAl5RD9-wMUE9QNAabyxg&usqp=CAU",
                      "userName" : "OlegVoll77"
                    },
                    {
                      "userId": 2,
                      "userPicture": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS3-vI1foTxugvrFoqEv8KQWAjAAzFvtsmB1Q&usqp=CAU",
                      "userName" : "fredvoll"
                    }
                  ], 
               
                  "sharesCount": "140"
                },
                {
                  "userName" : "mirkavanderoe",
                  "userPicture": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR-JDp6VgvMkh8rqdpUFcMm2FsE_y8XSGwIVw&usqp=CAU",
                  "postId" : 1000,
                  "post": "Mirka Post 1. Simplicity is the ultimate sophistication said Leonardo Da Vinci once. llllllllllllllllll", 
                  "postImg": "https://javacalculationengine.com/imgs4oca/whistler.jpg",
                  "time": "21 July 2021",
                
                  "commentsCount": "0",
                  
                  "likesCount": "0",
                  "sharesCount": "0"
                },
      
                {
                  "userName" : "mirkavanderoe",
                  "userPicture": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR-JDp6VgvMkh8rqdpUFcMm2FsE_y8XSGwIVw&usqp=CAU",
                  "postId" : 2,
                  "post": "Mirka Post 2. There is something about complexity that draws us towards it", 
                  "postImg": "https://javacalculationengine.com/imgs4oca/whistler-summer-2019.jpg",
                  "time": "2",

                  "commentsCount": "3",
                  "comments": [
                    {
                      "userId": 2,
                      "userPicture": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR-JDp6VgvMkh8rqdpUFcMm2FsE_y8XSGwIVw&usqp=CAU",
                      "userName" : "mirkavanderoe",
                      "comment" : "In life we never know how things turn out.",
                      "commentId" : 4
                    },
                    {
                      "userId": 4,
                      "userPicture": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRa5abWqOKhszN_CZzT3SwRd9kHZ9niMIDDxA&usqp=CAU",
                      "userName": "starlywave",
                      "comment" : "starlywave Comment",
                      "commentId" : 5
                    },
                    {
                      "userId": 6,
                      "userPicture": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRa5abWqOKhszN_CZzT3SwRd9kHZ9niMIDDxA&usqp=CAU",
                      "userName": "carolinekramerabstract",
                      "comment" : "carolinekramerabstractcomments Comment",
                      "commentId" : 6
                    }
                  ],  

                  "likesCount": "1",
                  "likes": [
                    {
                      "userId": 1,
                      "userName" : "OlegVoll77",
                      "userPicture" : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSYwLEw7-5pUK0Y5HAl5RD9-wMUE9QNAabyxg&usqp=CAU"
                    }                      
                  ], 

                  "sharesCount": "4"                 
                },
      
                {
                  "userName" : "mirkavanderoe",
                  "userPicture": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR-JDp6VgvMkh8rqdpUFcMm2FsE_y8XSGwIVw&usqp=CAU",
                  "postId" : 3,
                  "post": "Mirka Post 3. Everything that happens, happens for a reason", 
                  "postImg": "https://javacalculationengine.com/imgs4oca/whistler-20190703_130119.jpg",
                  "time": "3",

                  "commentsCount": "3",
                  "comments": [
                    {
                      "userId": 3,
                      "userName" : "fredvoll",
                      "comment" : "In life we never know how things turn out.",
                      "commentId" : 7
                    },
                    {
                      "userId": 4,
                      "userName": "starlywave",
                      "comment" : "starlywave Comment",
                      "commentId" : 8
                    },
                    {
                      "userId": 6,
                      "userName": "carolinekramerabstract",
                      "comment" : "carolinekramerabstractcomments Comment",
                      "commentId" : 9
                    }
                  ],  

                  "likesCount": "3",
                  "likes": [
                    {
                      "userId": 1,
                      "userName" : "OlegVoll77",
                      "userPicture" : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSYwLEw7-5pUK0Y5HAl5RD9-wMUE9QNAabyxg&usqp=CAU"
                    },
                    {
                      "userId": 3,
                      "userPicture": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS3-vI1foTxugvrFoqEv8KQWAjAAzFvtsmB1Q&usqp=CAU",
                      "userName" : "fredvoll"
                    },
                    {
                      "userId": 6,
                      "userPicture": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRa5abWqOKhszN_CZzT3SwRd9kHZ9niMIDDxA&usqp=CAU",
                      "userName": "carolinekramerabstract"
                    }
                  ], 

                  "sharesCount": "4000" 
                },

                {
                  "userName" : "mirkavanderoe",
                  "userPicture": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR-JDp6VgvMkh8rqdpUFcMm2FsE_y8XSGwIVw&usqp=CAU",
                  "postId" : 4,
                  "post": "Mirka Post 4. Everything that happens, happens for a reason", 
                  "postImg": "https://javacalculationengine.com/imgs4oca/aspen-2.jpg",
                  "time": "3",

                  "commentsCount": "3",
                  "comments": [
                    {
                    "userName" : "fredvoll",
                    "comment" : "In life we never know how things turn out.",
                    "commentId" : 10
                    },
                    {
                    "userName": "starlywave",
                    "comment" : "starlywave Comment",
                    "commentId" : 11
                    },
                    {
                    "userName": "carolinekramerabstract",
                    "comment" : "carolinekramerabstractcomments Comment",
                    "commentId" : 12
                    }
                  ],  

                  "likesCount": "3",
                  "likes": [
                    {
                      "userId": 1,
                      "userName" : "OlegVoll77",
                      "userPicture" : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSYwLEw7-5pUK0Y5HAl5RD9-wMUE9QNAabyxg&usqp=CAU"
                    },
                    {
                      "userId": 3,
                      "userPicture": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS3-vI1foTxugvrFoqEv8KQWAjAAzFvtsmB1Q&usqp=CAU",
                      "userName" : "fredvoll"
                    },
                    {
                      "userId": 6,
                      "userPicture": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRa5abWqOKhszN_CZzT3SwRd9kHZ9niMIDDxA&usqp=CAU",
                      "userName": "carolinekramerabstract"
                    }
                  ], 
                 
                  "sharesCount": "4000" 
                },    
        
              {
                "userName" : "fredvoll",
                "userPicture": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS3-vI1foTxugvrFoqEv8KQWAjAAzFvtsmB1Q&usqp=CAU",
                "postId" : 5,
                "postKind" : "Popular 10",
                "post": "Fred Post 1. Simplicity is the ultimate sophistication said Leonardo Da Vinci once.", 
                "postImg": "https://javacalculationengine.com/imgs4oca/whistler-summer-2019.jpg",
                "time": "2",

                "commentsCount": 0,
                "comments": [
                  {
                    "userPicture": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS3-vI1foTxugvrFoqEv8KQWAjAAzFvtsmB1Q&usqp=CAU",
                    "userName" : "fredvoll",
                    "comment" : "Fred Comment.",
                    "commentId" : 13
                  },
                  {
                    "userPicture": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRa5abWqOKhszN_CZzT3SwRd9kHZ9niMIDDxA&usqp=CAU",
                    "userName": "starlywave",
                    "comment" : "starlywave Comment",
                    "commentId" : 14
                  },
                  {
                    "userPicture": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRa5abWqOKhszN_CZzT3SwRd9kHZ9niMIDDxA&usqp=CAU",
                    "userName": "carolinekramerabstract",
                    "comment" : "carolinekramerabstract Comment",
                    "commentId" : 15
                  }
                ], 
                
                "likesCount": "11",
                "likes": [
                  {
                    "userId": 3,
                    "userPicture": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRa5abWqOKhszN_CZzT3SwRd9kHZ9niMIDDxA&usqp=CAU",
                    "userName": "starlywave"
                  },
                  {
                    "userId": 4,
                    "userPicture": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRa5abWqOKhszN_CZzT3SwRd9kHZ9niMIDDxA&usqp=CAU",
                    "userName": "carolinekramerabstract"
                  }
                ], 

                "sharesCount": 14
              },
      
              {
                "userName" : "fredvoll",
                "userPicture": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS3-vI1foTxugvrFoqEv8KQWAjAAzFvtsmB1Q&usqp=CAU",
                "postId" : 6,
                "post": "Fred Post 2. Everything that happens, happens for a reason", 
                "postImg": "https://javacalculationengine.com/imgs4oca/whistler-20190703_130119.jpg",
                "time": "2",              
                "commentsCount": 0,
               
                "likesCount": "2",
                "likes": [
                  {
                    "userId": 4,
                    "userPicture": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRa5abWqOKhszN_CZzT3SwRd9kHZ9niMIDDxA&usqp=CAU",
                    "userName": "starlywave"
                  },
                  {
                    "userId": 6,
                    "userPicture": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRa5abWqOKhszN_CZzT3SwRd9kHZ9niMIDDxA&usqp=CAU",
                    "userName": "carolinekramerabstract"
                  }
                ],

                "sharesCount": 33    
              },
      
              {
                  "userName" : "fredvoll",
                  "userPicture": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS3-vI1foTxugvrFoqEv8KQWAjAAzFvtsmB1Q&usqp=CAU",
                  "postId" : 7,
                  "post": "Fred Post 3. There is something about complexity that draws us towards it", 
                  "postImg": "https://javacalculationengine.com/imgs4oca/whistler.jpg",
                  "userComment": "In life we never know how things turn out333.",
                  "time": "2",                 
                  "commentsCount": 0,

                  "likesCount": "2",
                  "likes": [
                  {
                    "userId": 4,
                    "userPicture": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRa5abWqOKhszN_CZzT3SwRd9kHZ9niMIDDxA&usqp=CAU",
                    "userName": "starlywave" 
                  },
                  {
                    "userId": 6,
                    "userPicture": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRa5abWqOKhszN_CZzT3SwRd9kHZ9niMIDDxA&usqp=CAU",
                    "userName": "carolinekramerabstract"
                  }
                ],

                "sharesCount": 20
              },
              {
                  "userName" : "starlywave",
                  "userPicture": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRa5abWqOKhszN_CZzT3SwRd9kHZ9niMIDDxA&usqp=CAU",
                  "postId" : 8,
                  "post": "Starlywave Post 1. Simplicity is the ultimate sophistication said Leonardo Da Vinci once.", 
                  "postImg": "https://javacalculationengine.com/imgs4oca/asp-20190314_110316.jpg",
                  "postKind" : "Top 10",
                  "time": "2",                
                  "commentsCount": 0,

                  "likesCount": "2",
                  "likes": [
                    {
                      "userId": 4,
                      "userPicture": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRa5abWqOKhszN_CZzT3SwRd9kHZ9niMIDDxA&usqp=CAU",
                      "userName": "starlywave" 
                    },
                    {
                      "userId": 6,
                      "userPicture": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRa5abWqOKhszN_CZzT3SwRd9kHZ9niMIDDxA&usqp=CAU",
                      "userName": "carolinekramerabstract"
                    }
                  ],

                  "sharesCount": 14
              },
      
              {
                  "userName" : "starlywave",
                  "userPicture": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRa5abWqOKhszN_CZzT3SwRd9kHZ9niMIDDxA&usqp=CAU",
                  "postId" : 9,
                  "post": "Starlywave Post 2. Everything that happens, happens for a reason", 
                  "postImg": "https://javacalculationengine.com/imgs4oca/aspen-2.jpg",
                  "time": "2",                
                  "commentsCount": 0,
                  
                  "likesCount": "2",
                  "likes": [
                  {
                    "userId": 4,
                    "userPicture": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRa5abWqOKhszN_CZzT3SwRd9kHZ9niMIDDxA&usqp=CAU",
                    "userName": "starlywave"
                  },
                  {
                    "userId": 6,
                    "userPicture": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRa5abWqOKhszN_CZzT3SwRd9kHZ9niMIDDxA&usqp=CAU",
                    "userName": "carolinekramerabstract"
                  }
                ],

                "sharesCount": 33                 
              },
      
              { 
                  "userName" : "starlywave",
                  "userPicture": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRa5abWqOKhszN_CZzT3SwRd9kHZ9niMIDDxA&usqp=CAU",
                  "postId" : 10,
                  "post": "Starlywave Post 3. There is something about complexity that draws us towards it", 
                  "postImg": "https://javacalculationengine.com/imgs4oca/asp-20190313_110841.jpg",
                  "userComment": "In life we never know how things turn out333.",
                  "time": "2",             
                  "commentsCount": 0,
                 
                  "likesCount": "2",
                  "likes": [
                  {
                    "userId": 4,
                    "userPicture": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRa5abWqOKhszN_CZzT3SwRd9kHZ9niMIDDxA&usqp=CAU",
                    "userName": "starlywave"
                  },
                  {
                    "userId": 6,
                    "userPicture": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRa5abWqOKhszN_CZzT3SwRd9kHZ9niMIDDxA&usqp=CAU",
                    "userName": "carolinekramerabstract"
                  }
                ],
                
                "sharesCount": 20
              },
              {
                "userName" : "fabiangonzalezcolo",
                "userPicture": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRa5abWqOKhszN_CZzT3SwRd9kHZ9niMIDDxA&usqp=CAU",
                "postId" : 11,
                "postKind" : "Popular 10",
                "post": "Fabian Post 1. Simplicity is the ultimate sophistication said Leonardo Da Vinci once.", 
                "postImg": "https://javacalculationengine.com/imgs4oca/whistler-summer-2019.jpg",
                "time": "2",
               
                "commentsCount": 3,
                "comments": [
                  {
                    "userPicture": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS3-vI1foTxugvrFoqEv8KQWAjAAzFvtsmB1Q&usqp=CAU",
                    "userName" : "fredvoll",
                    "comment" : "Fred Comment.",
                    "commentId" : 16
                  },
                  {
                    "userPicture": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRa5abWqOKhszN_CZzT3SwRd9kHZ9niMIDDxA&usqp=CAU",
                    "userName": "starlywave",
                    "comment" : "starlywave Comment",
                    "commentId" : 17
                  },
                  {
                    "userPicture": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRa5abWqOKhszN_CZzT3SwRd9kHZ9niMIDDxA&usqp=CAU",
                    "userName": "carolinekramerabstract",
                    "comment" : "carolinekramerabstract Comment",
                    "commentId" : 18
                  }
                ], 
                
                "likesCount": "2",
                "likes": [
                  {
                    "userId": 4,
                    "userPicture": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRa5abWqOKhszN_CZzT3SwRd9kHZ9niMIDDxA&usqp=CAU",
                    "userName": "starlywave"
                  },
                  {
                    "userId": 6,
                    "userPicture": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRa5abWqOKhszN_CZzT3SwRd9kHZ9niMIDDxA&usqp=CAU",
                    "userName": "carolinekramerabstract"
                  }
                ], 
                
                "sharesCount": 14
              },
      
              {
                "userName" : "fabiangonzalezcolo",
                "userPicture": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRa5abWqOKhszN_CZzT3SwRd9kHZ9niMIDDxA&usqp=CAU",
                "postId" : 12,
                "post": "Fabian Post 2. Everything that happens, happens for a reason", 
                "postImg": "https://javacalculationengine.com/imgs4oca/whistler-20190703_130119.jpg",
                "time": "2",             
                "commentsCount": 0,
               
                "likesCount": "2",
                "likes": [
                  {
                    "userId": 4,
                    "userPicture": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRa5abWqOKhszN_CZzT3SwRd9kHZ9niMIDDxA&usqp=CAU",
                    "userName": "starlywave"
                  },
                  {
                    "userId": 6,
                    "userPicture": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRa5abWqOKhszN_CZzT3SwRd9kHZ9niMIDDxA&usqp=CAU",
                    "userName": "carolinekramerabstract"
                  }
                ],

                "sharesCount": 33                  
              },
      
              {
                  "userName" : "fabiangonzalezcolo",
                  "userPicture": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRa5abWqOKhszN_CZzT3SwRd9kHZ9niMIDDxA&usqp=CAU",
                  "postId" : 13,
                  "post": "Fabian Post 3. There is something about complexity that draws us towards it", 
                  "postImg": "https://javacalculationengine.com/imgs4oca/whistler.jpg",
                  "userComment": "In life we never know how things turn out333.",
                  "time": "2",                  
                  "commentsCount": 0,

                  "likesCount": "2",
                  "likes": [
                  {
                    "userId": 4,
                    "userPicture": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRa5abWqOKhszN_CZzT3SwRd9kHZ9niMIDDxA&usqp=CAU",
                    "userName": "starlywave" 
                  },
                  {
                    "userId": 6,
                    "userPicture": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRa5abWqOKhszN_CZzT3SwRd9kHZ9niMIDDxA&usqp=CAU",
                    "userName": "carolinekramerabstract"
                  }
                ],
                
                "sharesCount": 20
              },
              {
                  "userName" : "carolinekramerabstract",
                  "userPicture": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRa5abWqOKhszN_CZzT3SwRd9kHZ9niMIDDxA&usqp=CAU",
                  "postId" : 14,
                  "post": "Caroline post 1. Simplicity is the ultimate sophistication said Leonardo Da Vinci once.", 
                  "postImg": "https://javacalculationengine.com/imgs4oca/whistler-summer-2019.jpg",
                  "postKind" : "Top 10",
                  "time": "2",                
                  "commentsCount": 0,
                 
                  "likesCount": "2",
                  "likes": [
                    {
                      "userId": 4,
                      "userPicture": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRa5abWqOKhszN_CZzT3SwRd9kHZ9niMIDDxA&usqp=CAU",
                      "userName": "starlywave" 
                    },
                    {
                      "userId": 6,
                      "userPicture": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRa5abWqOKhszN_CZzT3SwRd9kHZ9niMIDDxA&usqp=CAU",
                      "userName": "carolinekramerabstract"
                    }
                  ],

                  "sharesCount": 14
              },
      
              {
                "userName" : "carolinekramerabstract",
                 "userPicture": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRa5abWqOKhszN_CZzT3SwRd9kHZ9niMIDDxA&usqp=CAU",
                "postId" : 15,
                "post": "Caroline post 2. Everything that happens, happens for a reason", 
                "postImg": "https://javacalculationengine.com/imgs4oca/aspen-2.jpg",
                "time": "2",                
                "commentsCount": 0,
                
                "likesCount": "2",
                "likes": [
                  {
                    "userId": 4,
                    "userPicture": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRa5abWqOKhszN_CZzT3SwRd9kHZ9niMIDDxA&usqp=CAU",
                    "userName": "starlywave"
                  },
                  {
                    "userId": 6,
                    "userPicture": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRa5abWqOKhszN_CZzT3SwRd9kHZ9niMIDDxA&usqp=CAU",
                    "userName": "carolinekramerabstract"
                  }
                ],

                "sharesCount": 33
              },
      
              {
                  "userName" : "carolinekramerabstract",
                  "userPicture": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRa5abWqOKhszN_CZzT3SwRd9kHZ9niMIDDxA&usqp=CAU",
                  "postId" : 16,
                  "post": "Caroline post 3. There is something about complexity that draws us towards it", 
                  "postImg": "https://javacalculationengine.com/imgs4oca/whistler.jpg",
                  "userComment": "In life we never know how things turn out333.",
                  "time": "2",                  
                  "commentsCount": 0,

                  "likesCount": "2",
                  "likes": [
                  {
                    "userId": 3,
                    "userPicture": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRa5abWqOKhszN_CZzT3SwRd9kHZ9niMIDDxA&usqp=CAU",
                    "userName": "starlywave"
                  },
                  {
                    "userId": 4,
                    "userPicture": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRa5abWqOKhszN_CZzT3SwRd9kHZ9niMIDDxA&usqp=CAU",
                    "userName": "carolinekramerabstract"
                  }
                ],

                "sharesCount": 20
              }
        ],
        "userPosts" : [
    
          {
            "postId" : 1,
            "post": "Oleg Post 1. Simplicity is the ultimate sophistication said Leonardo Da Vinci once. llllllllllllllllll", 
            "postImg": "https://javacalculationengine.com/imgs4oca/whistler.jpg",
            "time": "1",

            "commentsCount": 3,
            "comments": [
              {
                "userId": 3,
                "userPicture": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS3-vI1foTxugvrFoqEv8KQWAjAAzFvtsmB1Q&usqp=CAU",
                "userName" : "fredvoll",
                "comment" : "Fred Comment."
              },
              {
                "userId": 4,
                "userPicture": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRa5abWqOKhszN_CZzT3SwRd9kHZ9niMIDDxA&usqp=CAU",
                "userName": "starlywave",
                "comment" : "starlywave Comment"
              },
              {
                "userId": 6,
                "userPicture": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRa5abWqOKhszN_CZzT3SwRd9kHZ9niMIDDxA&usqp=CAU",
                "userName": "carolinekramerabstract",
                "comment" : "carolinekramerabstract Comment"
              }
            ],  

            "likesCount": "3",
            "likes": [
              {
                "userId": 3,
                "userPicture": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS3-vI1foTxugvrFoqEv8KQWAjAAzFvtsmB1Q&usqp=CAU",
                "userName" : "fredvoll"
              },
              {
                "userId": 4,
                "userPicture": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRa5abWqOKhszN_CZzT3SwRd9kHZ9niMIDDxA&usqp=CAU",
                "userName": "starlywave"
              },
              {
                "userId": 6,
                "userPicture": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRa5abWqOKhszN_CZzT3SwRd9kHZ9niMIDDxA&usqp=CAU",
                "userName": "carolinekramerabstract"
              }
            ],

            "sharesCount": 140
          },
  
          {
            "postId" : 2,
            "post": "Oleg Post 2. There is something about complexity that draws us towards it", 
            "postImg": "https://javacalculationengine.com/imgs4oca/whistler-summer-2019.jpg",
            "time": "2",

            "commentsCount": 2,
            "comments": [
              {
                "userId": 2,
                "userName" : "mirkavanderoe",
                "comment" : "In life we never know how things turn out."
              },
              {
                "userId": 4,
                "userName": "starlywave",
                "comment" : "starlywave Comment"
              },
              {
                "userId": 6,
                "userName": "carolinekramerabstract",
                "comment" : "carolinekramerabstractcomments Comment"
              }
            ],  

            "likesCount": "1",
            "likes": [
              {
                "userId": 3,
                "userName" : "fredvoll"
              },
              {
                "userId": 4,
                "userName": "starlywave"
              },
              {
                "userId": 6,
                "userName": "carolinekramerabstract"
              }
            ], 

            "sharesCount": 4
          },
  
          {
            "postId" : 3,
            "post": "Oleg Post 3. Everything that happens, happens for a reason", 
            "postImg": "https://javacalculationengine.com/imgs4oca/whistler-20190703_130119.jpg",
            "time": "3",

            "commentsCount": 3,
            "comments": [
              {
                "userId": 3,
                "userName" : "fredvoll",
                "comment" : "In life we never know how things turn out."
              },
              {
                "userId": 4,
                "userName": "starlywave",
                "comment" : "starlywave Comment"
              },
              {
                "userId": 6,
                "userName": "carolinekramerabstract",
                "comment" : "carolinekramerabstractcomments Comment"
              }
            ],  

            "likesCount": "3",
            "likes": [
              {
                "userId": 3,
                "userName" : "fredvoll"
              },
              {
                "userId": 4,
                "userName": "starlywave"
              },
              {
                "userId": 6,
                "userName": "carolinekramerabstract"
              }
            ], 

            "sharesCount": 4000
          }
        ],
        
        "whoIsUserFollowing" : [
          {
            "userName" : "mirkavanderoe",
            "userId" : 2,
            "userPicture": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR-JDp6VgvMkh8rqdpUFcMm2FsE_y8XSGwIVw&usqp=CAU",
            "userTagline": "Experimantal Group",
            "userBio": "Mirka description of the Hightlights",
            "userWebsite": "www.mirkaabstracts.com",
            "userEmail": "example233@mail.com",
            "userNumberOfPosts": 1000,
            "userNumberOfFollowers": 10,
            "userNumberOfFollowing": 300,
            "userKind" : "FOLLOWING"
          },
          {
            "userName" : "fredvoll",
            "userId" : 3,
            "userPicture": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS3-vI1foTxugvrFoqEv8KQWAjAAzFvtsmB1Q&usqp=CAU",
            "userTagline": "Software Product",
            "userBio": "Fred description of the Hightlights",
            "userWebsite": "www.abstractthoughts.com",
            "userEmail": "example@mail.com",
            "userNumberOfPosts": 10,
            "userNumberOfFollowers": 20,
            "userNumberOfFollowing": 30,
            "userKind" : "FOLLOWING"
          },
          {
            "userName" : "starlywave",
            "userId" : 4,
            "userPicture": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRa5abWqOKhszN_CZzT3SwRd9kHZ9niMIDDxA&usqp=CAU",
            "userTagline": "Software Product",
            "userBio": "Starlywave description of the Hightlights",
            "userWebsite": "www.abstractthoughts.com",
            "userEmail": "example@mail.com",
            "userNumberOfPosts": 10,
            "userNumberOfFollowers": 20,
            "userNumberOfFollowing": 30,
            "userKind" : "Popular 10"
          },
          {
            "userName" : "fabiangonzalezcolo",
            "userId" : 5,
            "userPicture": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRa5abWqOKhszN_CZzT3SwRd9kHZ9niMIDDxA&usqp=CAU",
            "userTagline": "Software Product",
            "userBio": "Mirka description of the Hightlights",
            "userWebsite": "www.abstractthoughts.com",
            "userEmail": "example@mail.com",
            "userNumberOfPosts": 10,
            "userNumberOfFollowers": 20,
            "userNumberOfFollowing": 30,
            "userKind" : "Popular 10"

          },
          {
            "userName" : "carolinekramerabstract",
            "userId" : 6,
            "userPicture": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRa5abWqOKhszN_CZzT3SwRd9kHZ9niMIDDxA&usqp=CAU",
            "userTagline": "Software Product",
            "userBio": "Mirka description of the Hightlights",
            "userWebsite": "www.abstractthoughts.com",
            "userEmail": "example@mail.com",
            "userNumberOfPosts": 10,
            "userNumberOfFollowers": 20,
            "userNumberOfFollowing": 30,
            "userKind" : "LAST_TEN"
          }
        ],
        "followers" : [
          {
            "userName" : "mirkavanderoe",
            "userId" : 2,
            "userPicture": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR-JDp6VgvMkh8rqdpUFcMm2FsE_y8XSGwIVw&usqp=CAU",
            "userTagline": "Experimantal Group",
            "userBio": "Mirka description of the Hightlights",
            "userWebsite": "www.mirkaabstracts.com",
            "userEmail": "example233@mail.com",
            "userNumberOfPosts": 1000,
            "userNumberOfFollowers": 10,
            "userNumberOfFollowing": 300,
            "userKind" : "FOLLOWING"
          },
          {
            "userName" : "fredvoll",
            "userId" : 3,
            "userPicture": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS3-vI1foTxugvrFoqEv8KQWAjAAzFvtsmB1Q&usqp=CAU",
            "userTagline": "Software Product",
            "userBio": "Fred description of the Hightlights",
            "userWebsite": "www.abstractthoughts.com",
            "userEmail": "example@mail.com",
            "userNumberOfPosts": 10,
            "userNumberOfFollowers": 20,
            "userNumberOfFollowing": 30,
            "userKind" : "FOLLOWING"
          }  
        ]
    }

export default fakeDBresponse;