import React from "react";
import '../css/bootstrap.css';
import {Button, Form, FormGroup, Input} from 'reactstrap';
import {BrowserRouter as Router} from 'react-router-dom';
import * as RiIcons from 'react-icons/ri';
import * as BsIcons from "react-icons/bs";
import Envir from '../components/Envir';
import Const from '../components/Const';
import { invokeService, validatePasswordStrength, messageModalWindow } from './Utils.js';

const initialState = {
  password: "",
  passwordRetype: "",
  passwordError: "",
  passwordRetypeError: "",
  startedLoading : false,
  error : null,
  hide : true, 
  messageModal : false,
  //
  serviceInvocationStatus : Const.SERVICE_IS_NOT_INVOKED_YET,
  serviceStatusCode : Const.INIT_VALUE,
  serviceErrorLevel : Const.NO_ERROR_LEVEL,
  serviceInvocationError : false,
  flag : Const.INIT_VALUE
};


export default class ResetPassword extends React.Component {
  state = initialState;

  //password show hide
  hideSwitch = ev => {
    this.setState({ hide: !this.state.hide })
  }

  validate = () => {
    let passwordError = "";
    let passwordRetypeError = "";
    let ret = true;
    let passwordStrength = validatePasswordStrength(this.state.password);
    let passwordRetypeStrength = validatePasswordStrength(this.state.passwordRetype);

    if (passwordStrength < Const.GOOD) {
      passwordError = Const.VALID_PASSWORD_REQUIRED;
      ret = false;
    }
    if (passwordRetypeStrength < Const.GOOD) {
      passwordRetypeError = Const.VALID_PASSWORD_REQUIRED;
      ret = false;
    }
    if ((passwordStrength >= Const.GOOD) && (this.state.password !== this.state.passwordRetype)) {
      passwordRetypeError = Const.RETYPED_PASSWORD_NOT_EQUAL_PASSWORD;
      ret = false;
    }
    this.setState({ passwordError, passwordRetypeError });
    return ret;
  };

  handleSubmit = async event => {
    let isValid;

    event.preventDefault();
    isValid = this.validate();
    if (isValid) {
      await this.resetPasswordInDB();
      if (this.state.serviceErrorLevel === Const.NO_ERROR_LEVEL) {
        window.location = Envir.LOGIN_FOLDER;
      }
      else if (this.state.serviceErrorLevel === Const.USER_ERROR_LEVEL) {
        this.setState({
          passwordError : Const.INVALID_PASSWORD,
          serviceInvocationStatus : Const.SERVICE_IS_NOT_INVOKED_YET
       });
      }
    }
  };

  async resetPasswordInDB() {
    let  data = {
      // Reset password servicee
      "password" : this.state.password,
      "mode" : Const.RESET_PASSWORD_MODE
    };

    await invokeService(Const.LOGIN_URL, data, this);
  }

  render() {
    const { title } = this.props; //password show hide
    const { serviceInvocationError, serviceErrorLevel, serviceInvocationStatus, serviceStatusCode, hide} = this.state;

    if (serviceErrorLevel === Const.USER_ERROR_CRITICAL_LEVEL) {
			return (
			  messageModalWindow(this, this.state.header, this.state.text)
			)
		}
    else if (serviceInvocationError || (serviceErrorLevel === Const.SYS_ERROR_LEVEL)) {
			return (
				messageModalWindow(this, this.state.header, this.state.text)
			)
		} 
    else if (serviceStatusCode === Const.NOT_CRITICALLY_BAD_COOKIE_WAS_SENT) {
      return <div>{Const.BAD_COOKIE_MESSAGE_CLOSE_BROWSER}</div>;
    } 
    else if (serviceInvocationStatus !== Const.SERVICE_IS_NOT_INVOKED_YET) {
      return <div>{Const.STORING_USER_INFO}</div>;
    } else {
      return (
        <Router>
          <div className="App-div">
            <div className="after-app-wrap-center-login-div-walk reSet resetPassword" >
              <div className="login-background">
                <div className="login-form">
                  <Form onSubmit={this.handleSubmit}>
                    <FormGroup >
                    <h2 className = "text-left"> Reset your password</h2>
                      <div className="login-form-row-no-label"> 
                        <label className="password">{title}         
                          <Input className="x-input"
                            type={hide ? 'password' : 'input'} 
                            name="password"
                            placeholder="New password"
                            value={this.state.password}
                            required
                            onChange={e => this.setState({password : e.target.value.trim(), passwordError : "" })}
                          />
                          <div className="eye" onClick={this.hideSwitch}>
                            {hide ? <RiIcons.RiEyeCloseLine /> : <BsIcons.BsEye />}
                          </div>

                          <div className = "error-message">
                            <span>{this.state.passwordError}</span>
                          </div>

                        </label>
                         
                      </div>
                      <div className="login-form-row-no-label">
                        <label className="password">{title}
                          <Input className="x-input"
                            type={hide ? 'password' : 'input'} 
                            name="passwordRetype"
                            placeholder="Enter your password again"
                            required
                            value={this.state.passwordRetype}
                            onChange={e => this.setState({passwordRetype : e.target.value.trim(), passwordRetypeError : "" })}
                          />
                          <div className="eye" onClick={this.hideSwitch}>
                            {hide ? <RiIcons.RiEyeCloseLine /> : <BsIcons.BsEye />}
                          </div>

                          <div className = "error-message">
                            <span>{this.state.passwordRetypeError}</span>
                          </div>

                        </label>
                       
                      </div>
                      <Button className="primary left" color ="info" type="Submit"> Reset password </Button>
                    </FormGroup>         
                  </Form>
                </div>
              </div>
            </div>
          </div>
      
        </Router>
        
      );
    }
  }
}
