import Const from '../Const';
import { getUserJsonFromDB, getUserJson, setLocalCookieForOcaPreferences } from '../Utils.js';
import { DEFAULT, setThemePreference } from '../../utils/ThemeUtils.js';

export async function getLoginUserJson(that, userName) {
  let loginUser = null;
  let result;
  let isUserComplete;

  result = await getUserJsonFromDB(that, userName);
  if (result) {
    if (that.state.serviceErrorLevel === Const.NO_ERROR_LEVEL) {
      loginUser = result;
      if (loginUser.userComplete) {
        isUserComplete = true;
      }
      else {
        isUserComplete = false;
      }
      processThemeOfLoginUser(loginUser);
    }
    that.setState({ loginUser : loginUser,  isUserComplete : isUserComplete});
  }
}

export async function getLoginUserJsonWithData(that, data) {
  let loginUser = null;
  let result;
  let isUserComplete;
 

  result = await getUserJson(that, data);
  if (that.state.serviceErrorLevel === Const.NO_ERROR_LEVEL) {
    loginUser = result;
    if (loginUser.userComplete) {
      isUserComplete = true;
    }
    else {
      isUserComplete = false;
    }
    processThemeOfLoginUser(loginUser);
  }
  that.setState({ loginUser : loginUser,  isUserComplete : isUserComplete});
}

export function processThemeOfLoginUser(loginUser) {
  let theme = loginUser.hasOwnProperty('theme') ? loginUser.theme : DEFAULT;

  setThemePreference(theme);
  setLocalCookieForOcaPreferences(theme);
}