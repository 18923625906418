import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { MDBBtn, MDBModal, MDBModalBody } from 'mdbreact';
import * as MdIcons from 'react-icons/md';
import * as FaIcons from 'react-icons/fa';
import Const from '../Const';
import { invokeService, messageModalWindow, getUserPictureClassName, updateCommentsPostInMemory,
	     getLastCommentOfLoginUser, toolTippy } from '../Utils.js';
import { modalPopUpScrollProcessor } from '../ScrollUtils.js';

export default class DeleteYourComment extends Component {
    constructor(props) {
		super(props);
 		const { deleteYourLastCommentParams } = this.props; 
		const { loginUser, post, viewOnly,  returnTo, anyUser, that } = deleteYourLastCommentParams;
		this.state = {
			value: '',
			modal: false,
			messageModal: false,
			loginUser : loginUser,
			post : post,
			viewOnly : viewOnly,
			returnTo :  returnTo,
			anyUser : anyUser,
			parent : that,
			header : '',
			text : '',
			//
			serviceInvocationStatus : Const.SERVICE_IS_NOT_INVOKED_YET,
			serviceStatusCode : Const.INIT_VALUE,
			serviceErrorLevel : Const.NO_ERROR_LEVEL,
			serviceInvocationError : false,
			flag : Const.INIT_VALUE
		};
	
		this.handleSubmit = this.handleSubmit.bind(this);
		this.allowToggle = true;
		this.virtualKeyBoardDetected = false;
		this.mobilDeviceDetected = false; 
		this.textOverflow = false;
		this.timeoutID = -1;
		this.overflowScroll = false;
        this.SUBMIT = 'Confirm';
        this.DELETE_YOUR_LAST_COMMENT = 'Delete Your Last Comment?';
	}

    async handleSubmit(event) {
		event.preventDefault();
		this.disableModalWindow(true);
		await this.deleteUserCommentInDB();
		if (this.state.serviceErrorLevel === Const.NO_ERROR_LEVEL) {
			this.disableModalWindow(false);
			this.toggle(); // should do it earlier to avoid blocking scrolling to page top
			this.removeFromUserComments();
		}
		else {
			this.disableModalWindow(false);
		}
   }

   async deleteUserCommentInDB() {
		let data = {
			mode : Const.STORE_USER_COMMENT_MODE,
			post_id : this.state.post.postId
		};
		
		await invokeService(Const.OCA_SERVICES_URL, data, this);
    }

	removeFromUserComments() {
		let post = this.state.post;
		let comments = post.comments;
		let parent = this.state.parent;
		let isAddUserPicture = false;
		let loginUser = this.state.loginUser;
		let loginUserLastCommentObj = getLastCommentOfLoginUser(post, loginUser);
		let commentId = loginUserLastCommentObj ? loginUserLastCommentObj.commentId : null;

		if (commentId) {
			// this conditional logic should always take place in normal flow
			this.state.parent.sortCommentsArray(comments, this.state.parent.ASC);
			this.deleteCommentInMemory(commentId, comments);
			updateCommentsPostInMemory(this, post, comments, isAddUserPicture);
			this.dynamicIdsReAssigner(comments); 
			this.setState({ comments : comments });
			parent.setState({ clikedPost : post, sortAssending : false});
		}
	}

	deleteCommentInMemory(commentId, comments) {
		let ind = 0;
	  
		for (let comment of comments) {
		  if (comment.commentId === commentId) {
			comments.splice(ind, 1);
			break;
		  }
		  ind++;
		}
	  }

	dynamicIdsReAssigner(comments) {
		let ind = 1;

		for (let comment of comments) {
			comment.commentId = ind;
			ind++;
		}
    }

    toggle = () => {
        if (this.allowToggle) {
          modalPopUpScrollProcessor(this);
          this.setState({ modal : !this.state.modal });
        }
    }

	disableModalWindow(disable) { 
		let body;
		let submitButton;
		
		if (disable) {
			body = document.getElementById('deleteCommentBody');
			submitButton = document.getElementById('deleteCommentButton');
			if (body && submitButton) {
				submitButton.setAttribute('disabled', 'disabled')
				body.style.opacity = Const.REDUCED_OPACITY; 
				this.allowToggle = false;
			}
		}
		else {
			this.allowToggle = true;
		}
	}

    render() {
        const { loginUser, modal, post } = this.state;
		let userPictureClassName;
		let avatarMobilClassName;
		let addPostClassName;
		let loginUserLastCommentObj;
		let loginUserLastComment = "";

		if (!loginUser) {
			return;
		}
		userPictureClassName = getUserPictureClassName(loginUser);
		avatarMobilClassName = "avatar-mobile";
		addPostClassName = userPictureClassName + ' ' + avatarMobilClassName;
		loginUserLastCommentObj = getLastCommentOfLoginUser(post, loginUser);
		loginUserLastComment = loginUserLastCommentObj ? loginUserLastCommentObj.comment : ""
        return(
            <>
                <div className="right" onClick={this.toggle}>
						<div className="left">
							{ toolTippy(FaIcons.FaRegTrashAlt, "Delete Your Last Comment", "bottom", [Const.DELAY, 0], "home-icons-small pointer-view", null) }
						</div>
                </div>
                {
                    modal && 
                     <MDBModal className="" isOpen={this.state.modal} toggle={this.toggle}  autoFocus={false} >
                        <MDBModalBody >  
							<form id="deleteCommentBody" onSubmit={this.handleSubmit} className="mypostform">  
								<Row className="alignflex" >
									<Col md={1} xs={9} className="p-0">
										<div className="modal-myheader">
											<MdIcons.MdClose id="X" className="modal-close" onClick={this.toggle} />
										</div>
									</Col> 
									<Col md={8} xs={9} className="p-0">
										<h6 className="action-name"> {this.DELETE_YOUR_LAST_COMMENT} </h6>
									</Col>
									<Col md={3} xs={3} className="p-0 right">
										<MDBBtn id="deleteCommentButton" className=" btn btn-primary" type="submit" value="Submit" >{ this.SUBMIT }</MDBBtn>
									</Col>
								</Row>
								<Row> 
									<div id="deleteCommentUserPicture" className={addPostClassName} >
										<img id="deleteCommentUserPicture" src= { loginUser.userPicture} alt="Avatar" />
									</div>
									<div className="createcommentarea" >
										{
											loginUserLastComment &&
											<textarea 
												readOnly ={true}
												name="createcomment" 
												id="textArea" 
												defaultValue = {loginUserLastComment }
											>
											</textarea>			

										}
									</div>
								</Row>
							</form>
					    </MDBModalBody> 
                     </MDBModal>
                }
              
                {
                    messageModalWindow(this, this.state.header, this.state.text)
                }
            </>   
        );
    }
}