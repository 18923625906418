import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { MDBBtn, MDBModal, MDBModalBody } from 'mdbreact';
import * as BiIcons from 'react-icons/bi';
import * as MdIcons from 'react-icons/md';
import Const from '../Const';
import { invokeService, messageModalWindow, changeHandler, remainingChars, processAsyncPostDeletion, 
	     setPostTextOverflow, getUserPictureClassName, updateCommentsPostInMemory, toolTippy } from '../Utils.js';
import { modalPopUpScrollProcessor } from '../ScrollUtils.js';
import { isDesktop } from '../../utils/AppUtils';

class PostYourComment extends Component {
	constructor(props) {
		super(props);
 		const { postYourCommentsParams } = this.props; 
		const { loginUser, post, viewOnly,  returnTo, anyUser, that } = postYourCommentsParams;
		this.MAX_LENGTH = 280;
		this.DELTA = 20;
		this.YOUR_COMMENT = 'Your Comment';
		this.YOUR_FIRST_COMMENT = 'Be the First to Comment';
		this.MOBIL_DEVICE_BUTTON_KEY = 'mobilDeviceCommentsButton';
		this.DESKTOP_BUTTON_KEY = 'deskTopCommentsButton';
		this.state = {
			value: '',
			modal: false,
			messageModal: false,
			loginUser : loginUser,
			post : post,
			viewOnly : viewOnly,
			returnTo :  returnTo,
			anyUser : anyUser,
			parent : that,
			header : '',
			text : '',
			noPostText : true,
			charCounter : this.MAX_LENGTH,
			showAddCommentButton : true,
			//
			serviceInvocationStatus : Const.SERVICE_IS_NOT_INVOKED_YET,
			serviceStatusCode : Const.INIT_VALUE,
			serviceErrorLevel : Const.NO_ERROR_LEVEL,
			serviceInvocationError : false,
			flag : Const.INIT_VALUE
		};
	
		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.allowToggle = true;
		this.virtualKeyBoardDetected = false;
		this.mobilDeviceDetected = false; 
		this.textOverflow = false;
		this.timeoutID = -1;
		this.overflowScroll = false;
	}

    getYourCommentTitle() {
		let commentTitle;
 
		if (this.state.post.commentsCount > 0) {
			commentTitle = this.YOUR_COMMENT;
		}
		else {
			commentTitle = this.YOUR_FIRST_COMMENT;
		}
		return commentTitle;        
	}

   async handleChange(event) {
      changeHandler(event, this);
   }

   async handleSubmit(event) {
		let comments;

		event.preventDefault();
		this.disableModalWindow(true);
		await this.storeUserCommentInDB();
		if (this.state.serviceErrorLevel === Const.NO_ERROR_LEVEL) {
			this.disableModalWindow(false);
			this.toggle(); // should do it earlier to avoid blocking scrolling to page top
			comments = this.assembleLastSubmittedComment();
			this.addToUserComments(comments);
		}
		else {
			this.disableModalWindow(false);
		}
		this.setState({ value : '',  charCounter : this.MAX_LENGTH});
   }

   async storeUserCommentInDB() {
		let value = this.state.value;
		let data = {
			mode : Const.STORE_USER_COMMENT_MODE,
			post_id : this.state.post.postId,
			comment : (value.length <= this.MAX_LENGTH) ? value : value.substring(0, this.MAX_LENGTH)
		};
		
		await invokeService(Const.OCA_SERVICES_URL, data, this);
   }

   assembleLastSubmittedComment() {
		let comments = (this.state.post.hasOwnProperty('comments')) ? this.state.post.comments : [];
		let loginUser = this.state.loginUser;
		let lastSubmittedComment = {
			commentId : 0,
			comment : this.state.value,
			userName : loginUser.userName,
			userId : loginUser.userId,
			userPicture : this.state.loginUser.userPicture,
			time : 'Recently submited',
			userPictureType : loginUser.userPictureType
		};

		comments.push(lastSubmittedComment);
		this.dynamicIdsReAssigner(comments); // needed because we can create multiple comments in memory
		return comments;
   }

   addToUserComments(comments) {
		let post = this.state.post;
		let parent = this.state.parent;
		let isAddUserPicture = false;

		updateCommentsPostInMemory(this, post, comments, isAddUserPicture);
		this.setState({ comments : comments });
		parent.setState({ clikedPost : post, sortAssending : false});
	}

	dynamicIdsReAssigner(comments) {
		this.state.parent.sortCommentsArray(comments, this.state.parent.ASC);
		for (let comment of comments) {
			comment.commentId++;
		}
    }

	toggle = () => {
		if (this.allowToggle) {
			modalPopUpScrollProcessor(this);
			this.detectMobilDevice();
			this.setStateAttributes();
			if (!this.state.modal) {
				// we will pop modal window in this toggle invocation,
				this.setState({ value : '', noPostText : true, charCounter : this.MAX_LENGTH, showAddCommentButton : false });
			}
			this.setState({ modal : !this.state.modal });
		}
	}

	disableModalWindow(disable) { 
		let body;
		let submitButton;
		let textArea;
		let xCloser;
		
		if (disable) {
			body = document.getElementById('main');
			submitButton = document.getElementById('dialog-button');
			textArea = document.getElementById('textArea');
			xCloser = document.getElementById('X');
			if (body && textArea && xCloser) {
				submitButton.setAttribute('disabled', 'disabled');
				textArea.style.opacity = Const.REDUCED_OPACITY; 
				xCloser.style.opacity = Const.REDUCED_OPACITY;  
				this.allowToggle = false;
			}
		}
		else {
			this.allowToggle = true;
		}
	}

	componentDidUpdate() {
		if (!this.state.messageModal && 
			(this.state.serviceStatusCode === Const.SYS_ERROR_STORING_USER_COMMENT_NO_POST_FOUND)) {
			this.setState({ serviceStatusCode : Const.INIT_VALUE });
			this.processAsyncPostDeletionError();
		}
	}

	detectMobilDevice() {
		let mobilCommentsButton;

		if (!this.state.modal) {
			// Post modal pop-up is opening
			mobilCommentsButton = document.getElementById(this.MOBIL_DEVICE_BUTTON_KEY);
			if (mobilCommentsButton && (mobilCommentsButton.clientHeight > 0)) {
				this.mobilDeviceDetected = true;
			}
		}
	}

	setStateAttributes() {
		if (this.state.modal && this.mobilDeviceDetected) {
		  // Post modal pop-up is closing
		  if (this.timeoutID !== -1) {
			clearTimeout(this.timeoutID);
		  }
		  this.timeoutID = setTimeout(this.activateMobilCommentsButton, Const.MOBIL_BUTTON_ACTIVATION_DELAY, this);
		}
	}

	activateMobilCommentsButton(that) {
		that.setState({ showAddCommentButton : true});
	}

	processAsyncPostDeletionError() {
		let loginUser = this.state.loginUser;
		let clickedPost = this.state.post;

		processAsyncPostDeletion(clickedPost, this);
		this.state.parent.setState({ loginUser : loginUser, asyncPostDeletion : true });
	}
	
	getDeskTopCommentButton(viewOnly) {
		if (!viewOnly) {
			return (
				<div key={this.DESKTOP_BUTTON_KEY} className="nav-link nav-icon create-post" onClick={this.toggle} autoFocus={false}>
					{ toolTippy(BiIcons.BiMessageRoundedAdd, this.getYourCommentTitle(), "bottom", [Const.DELAY, 0], "btn-desktop", null) }
				</div>
			)
		}
	}

	getMobilCommentButton(viewOnly, modal, showAddCommentButton) {
		if (!viewOnly && !modal && showAddCommentButton) {
			return (
				<div key={this.MOBIL_DEVICE_BUTTON_KEY} className="nav-link nav-icon create-post" onClick={this.toggle} autoFocus={false}>
					<div id={this.MOBIL_DEVICE_BUTTON_KEY} className="btn-mobile-comments" >
						<BiIcons.BiMessageRoundedAdd/>
					</div>
				</div>
			)
		}
	}

	getCommentButtons(viewOnly, modal, showAddCommentButton) {
        return (
            [
                this.getDeskTopCommentButton(viewOnly),
                this.getMobilCommentButton(viewOnly, modal, showAddCommentButton)
            ]
        )
	}

	getBottomBar(charCounter) {
		return ( 
		  <Row className="media1 row-margin-01" > 			 

				 {/* <Col className="p-0" md={11} xs={11}>            */}
					 {
					 remainingChars(charCounter)
				 }
				 {/* </Col>				 */}
		  </Row> 
		) 
	}

	render() {
		const { loginUser, charCounter, viewOnly, modal, showAddCommentButton } = this.state; 
		let userPictureClassName;
		let avatarMobilClassName;
		let addPostClassName;
		let modalFullScreenClassName = "";
		let formClassName ="mypostform";

		if (isDesktop()) {
			formClassName += " form-group-local-scope-desktop";
		}
		else {
			// full screen modal edit profile pop-up for mobil devices
			modalFullScreenClassName = "modal-fullscreen"; 
		}
		if (!loginUser) {
			return;
		}
		userPictureClassName = getUserPictureClassName(loginUser);
		avatarMobilClassName = "avatar-mobile";
		addPostClassName = userPictureClassName + ' ' + avatarMobilClassName;
		setPostTextOverflow(charCounter, this);
		return(
			<>
			    {
					modal &&
					<MDBModal className= { modalFullScreenClassName } isOpen={this.state.modal} toggle={this.toggle} autoFocus={false} >
						<MDBModalBody >  
							<form id="main" onSubmit={this.handleSubmit} className={ formClassName }>  
								<Row className="alignflex" >
									<Col md={9} xs={9} className="p-0">
										<div className="modal-myheader">
											<MdIcons.MdClose id="X" className="modal-close" onClick={this.toggle} />
										</div>
									</Col>
									<Col md={3} xs={3} className="p-0 right">
										{
											this.state.noPostText || this.textOverflow
												? <MDBBtn id="dialog-button" className=" btn btn-primary pointer-noview" disabled = { true } >Comment</MDBBtn>
												: <MDBBtn id="dialog-button" className=" btn btn-primary" type="submit" value="Submit">Comment</MDBBtn>
										}
									</Col>
								</Row>
								<Row> 
									<div className={addPostClassName}>
										<img src= { loginUser.userPicture} className="" alt="Avatar" />
									</div>
									<div className="createcommentarea" >
										<textarea  className="x-input"
											autoFocus={true}
											maxLength={this.MAX_LENGTH + this.DELTA} 
											autoCorrect='ON'  
											name="createcomment" 
											id="textArea" 
											placeholder="Post your comment here" 
										//   rows="7" 
											value={ this.state.value } 
											onChange={this.handleChange}>
										</textarea>										
									</div>
								</Row>

								{
									remainingChars(charCounter)
								}	
													
							</form>
						</MDBModalBody> 
					</MDBModal>
				}
				
				{
				 this.getCommentButtons(viewOnly, modal, showAddCommentButton)
				}

				{
					messageModalWindow(this, this.state.header, this.state.text)
				}
			</>   
		);
	}
}
  
export default PostYourComment;