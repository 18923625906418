import  { getCssVarValue, setCssVarValue } from '../utils/CssUtils.js';

const AI_TEXT_AREA_INIT_HEGHT = 80;
const AI_SEND_ARROW_HEGHT = 30;

export function initAItextAreaHeight() {
  setCssVarValue('--aiTextareaHeight', AI_TEXT_AREA_INIT_HEGHT);
}

export function getInitAItextAreaHeight() {
  return AI_TEXT_AREA_INIT_HEGHT;
}

//

export function setAItextAreaHeight(val) {
  setCssVarValue('--aiTextareaHeight', val);
}

export function setAItextAreaOpacity(val) {
  setCssVarValue('--aiOpacity', val);
}

export function getAItextAreaHeight() {
  return Number(getCssVarValue('--aiTextareaHeight'));
}

//

export function setAIsendArrowHeight() {
  setCssVarValue('--aiSendArrowHeight', AI_SEND_ARROW_HEGHT);
}

export function getAIsendArrowHeight() {
  return Number(getCssVarValue('--aiSendArrowHeight'));
}

export function setAIsendArrowOpacity(val) {
  setCssVarValue('--aiArrowOpacity', val);
}

export function getAIsendArrowOpacity() {
  return Number(getCssVarValue('--aiArrowOpacity'));
}