import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import * as MdIcons from 'react-icons/md';
import Navi from '../nav';
import Const from '../../components/Const';
import { setLocalCookieForOcaComments, getAppCookie, getCommentsCookieKeyName, getLocalCookieKeyName, 
	     getUserJson, messageModalWindow, sleepingCat, invokeService, processAsyncPostDeletion,
		 personImayFollow, setCloseYourBrowserMessage, getUserPictureClassName, updateCommentsPostInMemory } from '../../components/Utils.js';
import { pageScrollToTop, pageScrollToBottom, addScrollListener, removeScrollListener, COMMENTS } from '../ScrollUtils.js';

class GetComments extends Component {
	constructor(props) {
        super(props);
		this.state = {
			header : '',
			text : '',
			loginUser : null,
			anyUser : null,
			asyncPostDeletion : false,
		    sortAssending : false,
			parametersReceived : false,
			messageModal: false,
			serviceInvocationStatus : Const.SERVICE_IS_NOT_INVOKED_YET,
			serviceStatusCode : Const.INIT_VALUE,
			serviceErrorLevel : Const.NO_ERROR_LEVEL,
			serviceInvocationError : false
		};
		this.ASC = 1;
		this.DESC = -1;
		this.className = COMMENTS;
		pageScrollToTop();
	}

	async paramsProcessor() {
		let clikedPost, returnTo, anyUser, viewOnly; 
		let commentsCookieObj; 
		let cookieKey;
		let params;
		let loginUser;

		if (this.props.location.hasOwnProperty('params')) {
			params = this.props.location.params;
			(
			  { clikedPost, returnTo, anyUser, viewOnly, loginUser } = params
			);
			commentsCookieObj = {
				clikedPostId : clikedPost.postId,
				returnTo : returnTo,
				viewOnly : viewOnly,
				userName : anyUser ? anyUser.userName : '',
				userNumberOfPosts : anyUser ? anyUser.userNumberOfPosts : '',
				userNumberOfFollowers : anyUser ? anyUser.userNumberOfFollowers : '',
				userNumberOfFollowing : anyUser ? anyUser.userNumberOfFollowing : '',
				userTagline : anyUser ? anyUser.userTagline : '',
				userBio : anyUser ? anyUser.userBio : '',
				userWebsite : anyUser ? anyUser.userWebsite : '',
				userPicture : anyUser ? anyUser.userPicture : ''
			};
			if (loginUser && !loginUser.testDrive) {
				setLocalCookieForOcaComments(commentsCookieObj);
			}
		}
		else {
			params = {};
			cookieKey = getCommentsCookieKeyName();
			commentsCookieObj = decodeURIComponent(await getAppCookie(cookieKey));
			if (commentsCookieObj) {
				params = JSON.parse(commentsCookieObj);
			}
			else {
				window.location = '/';
			}
		}
		return params;
	}

	async componentDidMount() {
		let result;
		let loginUser;
		let anyUser;
		let cookieKey;
		let userName;
		let clikedPost;
		let params = await this.paramsProcessor();

		Object.assign(this.state, params);
		this.setState({ parametersReceived : true }); // needs to invoke renderer
		if (this.state.hasOwnProperty('clikedPost') || this.state.hasOwnProperty('clikedPostId')) {
			if (!this.state.loginUser) {
				cookieKey = getLocalCookieKeyName();
				userName = await getAppCookie(cookieKey);
				result = await this.getUserJsonFromDB(userName);
				if (this.state.serviceErrorLevel === Const.NO_ERROR_LEVEL) {
					loginUser = result;
					loginUser.sharedPost = true; // we use the same flow as for shared post here for login user click
					clikedPost = result.onePost;
					this.setState({ loginUser : loginUser, clikedPost : clikedPost });
				}
				if (this.state.userName) {
					// we have any user post
					anyUser = {
						userName : this.state.userName,
						userNumberOfPosts : this.state.userNumberOfPosts,
						userNumberOfFollowers : this.state.userNumberOfFollowers,
						userNumberOfFollowing : this.state.userNumberOfFollowing,
						userTagline : this.state.userTagline,
						userBio : this.state.userBio,
						userWebsite : this.state.userWebsite,
						userPicture : this.state.userPicture
					}
					this.setState({ anyUser : anyUser });
				}
			}
		}
		else {
			// improper URL use
			setCloseYourBrowserMessage(this);
			this.setState({ serviceErrorLevel : Const.SYS_ERROR_LEVEL });
		}
		addScrollListener(this);
	}

	async getUserJsonFromDB(userName) {
		let data = {
			user_name : userName,
			post_id : this.state.clikedPostId,
			comments_refresh : true
		};
		let ret = await getUserJson(this, data);

		return ret;
	}

	async refreshCommentsFromDB(post) {
		let ret;
        let data = {
            mode : Const.REFRESH_COMMENTS_MODE,
            post_id : post.postId,
        };
		
		post.noshow = true;
		this.setState({ clikedPost : post });
        ret = await invokeService(Const.OCA_SERVICES_URL, data, this); 
		if (this.state.serviceErrorLevel === Const.NO_ERROR_LEVEL) {
			this.refreshCommentsInMemory(ret, post);
		}
		delete post.noshow;
		this.setState({ clikedPost : post, sortAssending : false });
	}

	refreshCommentsInMemory(ret, post) {
		let isAddUserPicture = true;
		let comments = ret.result.comments;

		updateCommentsPostInMemory(this, post, comments, isAddUserPicture);
	}

	sortComments() {
		let clikedPost = this.state.clikedPost;
		let userComments = clikedPost.comments;
		let direction = this.state.sortAssending ? this.ASC : this.DESC;

		this.sortCommentsArray(userComments, direction);
		this.setState({ clikedPost : clikedPost, sortAssending : !this.state.sortAssending });
	}

	sortCommentsArray(ar, direction) {
		ar.sort(function(a, b) {
			return direction * (a.commentId - b.commentId);
		});
	}

	getUserPicture(returnTo, clikedPost, loginUser, anyUser) {
		let postUserPicture;

		if (returnTo === '/Home') {
			postUserPicture = clikedPost.userPicture;
		}
		else if (returnTo === '/Profile') {
			postUserPicture = loginUser.userPicture;
		}
		else if (returnTo === '/User') {
			postUserPicture = anyUser.userPicture; // '/User'
		}
		else {
			postUserPicture = clikedPost.userPicture; // Shared Post
		}
		return postUserPicture;
	}

	getBackToCallerOnAsyncPostDeletion(asyncPostDeletion, returnTo, anyUser, loginUser) {
		if (asyncPostDeletion) {
			return (
					(returnTo === '/User')
					? <Redirect to={{ pathname: returnTo, params: { personIFollow : anyUser, loginUser } }} />
					: <Redirect to={{ pathname: returnTo, params: { loginUser }}} />
			       )
		}
	}

	componentDidUpdate() {
		if (!this.state.messageModal && 
			(this.state.serviceStatusCode === Const.SYS_ERROR_POST_COMMENTS_REFRESHING_NO_POST_FOUND)) {
		  this.setState({ serviceStatusCode : Const.INIT_VALUE });
		  this.processAsyncPostDeletionError();
		}
		else {
			if (this.state.sortAssending) {
				pageScrollToBottom(); 
			}
			else {
				pageScrollToTop();
			}
		}
    }

	componentWillUnmount() {
		removeScrollListener();
	}

	processAsyncPostDeletionError() {
        let loginUser = this.state.loginUser;
        let clickedPost = this.state.clikedPost;

        processAsyncPostDeletion(clickedPost, this);
	    this.setState({ loginUser : loginUser, asyncPostDeletion : true });
	}

	bodyEnableDesabler(clikedPost) {
		if (clikedPost.hasOwnProperty('noshow')) {
			document.body.style.opacity = Const.REDUCED_OPACITY;
		}
		else {
			document.body.style.opacity = Const.FULL_OPACITY;
		}
	}

	render() {
		let { clikedPost, returnTo, viewOnly, anyUser, loginUser, asyncPostDeletion, serviceErrorLevel, 
			  header, text } = this.state; 
		let userComments = [];
		let postYourCommentsParams;
		let postUserPicture;
		let avatarClassName;

		if (!this.state.loginUser || ((returnTo === '/User') && !anyUser)) {
			if (serviceErrorLevel !== Const.NO_ERROR_LEVEL) {
				return messageModalWindow(this, header, text);
			}
			else {
				return sleepingCat();
			}
		}
		if (clikedPost.comments) {
			userComments = clikedPost.comments;
		}
		postUserPicture = this.getUserPicture(returnTo, clikedPost, loginUser, anyUser);
		avatarClassName = getUserPictureClassName(clikedPost);
		postYourCommentsParams = { 
			loginUser : loginUser, 
			anyUser : anyUser,
			post : clikedPost,
			viewOnly : viewOnly,
			returnTo :  returnTo,
			that : this
		};
		this.bodyEnableDesabler(clikedPost);
		loginUser.whereIam = Const.I_AM_IN_COMMENTS;
		return(
			<> 
			    {
					this.getBackToCallerOnAsyncPostDeletion(asyncPostDeletion, returnTo, anyUser, loginUser)
				}
			    
				<div>
					<Navi navParams = { postYourCommentsParams } />
				</div>

				<div className="parent-div">
					<div className="after-app-wrap-center-div-comments">
						<div className="comments-div">
							<div className="comments-topost">
								<Row className="postrow">
									<Col md={1} sm={2} xs={12} >
										<Row>
											<Col md={6} sm={6} xs={2}>
												{
													(returnTo === '/User')
													? <Link to={{ pathname: returnTo, params: { personIFollow : anyUser, loginUser } }}>
															<MdIcons.MdArrowBack className="arrows " />
														</Link>
													: <Link to={{ pathname: returnTo, params: { loginUser }}}>
														<MdIcons.MdArrowBack className="arrows " />
														</Link>
												}
											</Col>
											<Col md={6} sm={6} xs={2}>
												<div className={avatarClassName}>
													<img src= { postUserPicture } alt="Avatar" />
												</div>
											</Col>
										</Row>		
									</Col>
									<Col md={11} sm={10} xs={12}>									
										<p className="poststyle"> { clikedPost.post } </p>   
									</Col>
								</Row>
							</div>

							{
								(clikedPost.commentsCount > 0) &&
								<div className = "">
									{ userComments.map( userComment => 
										<Row key={ userComment.commentId }> 										
											{
												(userComment.userName !== loginUser.userName) 
												? <Link to={{ pathname: '/User', params: { personIFollow : personImayFollow(userComment.userName, userComment.userPicture), loginUser} }}>
													<div className={getUserPictureClassName(userComment)}>
														<img src= { userComment.userPicture } alt="Avatar" />
													</div>
												</Link>
												: <div className={getUserPictureClassName(userComment)}>
													<img src= { userComment.userPicture } alt="Avatar" />
												</div>
											}
											
											<div className="triangle-left">
												<div className="inner-triangle"></div>
											</div>
										
											<div className="talkbubble">											                                          
												<p className = "mb-0 bold post-username"> { userComment.userName } </p>
												<p className = "small">{ userComment.time} </p>
												<p className = "mb-0 poststyle"> { userComment.comment } </p>														
											</div>  																			
										</Row>  
									)}                      
								</div>
							}
						</div>
					</div>  
				</div> 
				{
					messageModalWindow(this, header, text)
				}               
			</>            
		);
	}
}
  
export default GetComments;