import React from 'react';
// import '../css/bootstrap.css';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { setLocalCookie,  messageModalWindow, sleepingCat,
	     getUserJsonFromDBwithCookie, loadPartialJsonPosts } from '../components/Utils.js';
import { addScrollListener, removeScrollListener,  scrollToStroredPosition, HOME, disablePageScroll, enablePageScroll,
	     setPostsLoadinOrPostsEndOption, unsetPostsLoadinOrPostsEndOption, scrollToStroredPositionConditional } from '../components/ScrollUtils.js';
import { createAppHomeLayout, appTouchStartListener,  appTouchEndListener, appImageZoomUnsubscribe,
	     tabletInDeskTopLayout, addOrientationChangeListener, removeOrientationChangeListener, appTouchMoveListener
	   } from '../components/ImageZoomUtils.js';
import Const from '../components/Const';
import Envir from '../components/Envir';
import  {getFullHomePage } from '../utils/RenderPages.js';


export default class Home extends React.Component {
	constructor(props) {
		super(props);
		this.state = {            
			loginUser : props.location.params ? props.location.params.loginUser : null,
			zero : (Envir.DB_JSON === 'ON') ? 0 : '0',
			header : '',
			text : '',
			messageModal: false,
			canProcessContactUs : false,
			serviceInvocationStatus : Const.SERVICE_IS_NOT_INVOKED_YET,
			serviceStatusCode : Const.INIT_VALUE,
			serviceErrorLevel : Const.NO_ERROR_LEVEL,
			serviceInvocationError : false,
		};
		this.postsContainerRef = React.createRef();
		this.loadingImgRef = React.createRef();
		this.followersRef = React.createRef();
		this.className = HOME;
		this.onTouchMoveBodyHandler = this.onTouchMoveBodyHandler.bind(this);
		this.onTouchStartHandler = this.onTouchStartHandler.bind(this);
		this.onTouchMoveHandler = this.onTouchMoveHandler.bind(this);
		this.onTouchendHandler = this.onTouchEndtHandler.bind(this);
		this.pinchToZoom = null;		
	}

	async componentDidMount() {
		let loginUser = this.state.loginUser;
	
	    if (!loginUser || !loginUser.hasOwnProperty('topPosts')) {
			// topPost property check is needed for the new user case
			this.connectToDB();
		}
		else {
			scrollToStroredPositionConditional(this);
		}
		addScrollListener(this);
		document.addEventListener('touchmove', this.onTouchMoveBodyHandler);
		addOrientationChangeListener(this);
		createAppHomeLayout();
	}

	async connectToDB() {
		let loginUser = null;
		let result;
		
		unsetPostsLoadinOrPostsEndOption(this, Const.END_OF_POSTS);
		result = await  getUserJsonFromDBwithCookie(this);
		if ((this.state.serviceErrorLevel === Const.NO_ERROR_LEVEL) || (Envir.DB_JSON === 'OFF')) {
			loginUser = result;
		}
		if (loginUser) {
			loginUser.showFollowig = true;
			this.setState({ loginUser : loginUser });
			setLocalCookie(loginUser);
		}
	}

	componentWillUnmount() {
		removeScrollListener();
		if (tabletInDeskTopLayout()) {
			clearAllBodyScrollLocks();
		}
		removeOrientationChangeListener();
		if (this) {
			document.removeEventListener('touchmove', this.onTouchMoveBodyHandler);
			appImageZoomUnsubscribe(this);
		}
	}

	contactUsActivator() {
		if (this.state.loginUser && !this.state.canProcessContactUs) {
			this.setState({ canProcessContactUs : true });
		}
	}

	invokedByStartingComponent() {
		let documentReferrer = document.referrer;
		let ret = false;

		if (documentReferrer) {
			ret = (documentReferrer.indexOf('ogin') >= 0) ? true : false;
		}
		return ret;
	}

	onTouchStartHandler(e) {
		appTouchStartListener(e, this);
	}

	onTouchMoveHandler(e) {
		appTouchMoveListener(e);
	}

	onTouchEndtHandler(e) {
		appTouchEndListener(e);
	}

	// followers scroll div mouse handlers
	onMouseOverHandler(e) {
		e.preventDefault();
		disablePageScroll();
    }

	onMouseOutHandler(e) {
		e.preventDefault();
		enablePageScroll();
    }

	onTouchMoveBodyHandler(e) {
		let posts;

		if (tabletInDeskTopLayout()) { 
		   posts = document.getElementById('posts');
		   if (posts) {
			if (e.target.getAttribute('body-scroll-lock-ignore') !== null) {
				disableBodyScroll(posts);
			 }
			 else {
				enableBodyScroll(posts);
			 }
		   }
		}
    }

	componentDidUpdate() {
		createAppHomeLayout();
		scrollToStroredPosition(this);
    }

	loadMorePostsIsNeeded() {
		let loginUser = this.state.loginUser;
		let needToLoadMorePosts = (loginUser &&  !loginUser.hasOwnProperty('onePost')) ? true : false; // this is needed for back button

		return needToLoadMorePosts;
	}

	async getPartialUserJson() {
		let loginUser = this.state.loginUser;
		let topPosts = loginUser.topPosts;
		let loadMore = loginUser.loadMore;
		let parms = {
				user_id : loginUser.userId,
				max_post_id : topPosts[topPosts.length - 1].postId,
				users_posts_present : loadMore.myUsersPostsArePresent,
				top_posts : loadMore.moreTopPosts,
				users_posts : loadMore.moreUsersPosts,
				max_max_post_id : loadMore.maxMaxPostId,
				min_users_post_id : loadMore.minMyUsersPostsPostId
			};
		let partialJson = await loadPartialJsonPosts(this, parms);

		this.processPartialTopPostsJson(partialJson, loginUser);
	}

	processPartialTopPostsJson(partialJson, loginUser) { 
		if ((this.state.serviceErrorLevel === Const.NO_ERROR_LEVEL)) {
			if (partialJson.topPosts.length > 0) {
				loginUser.topPosts = loginUser.topPosts.concat(partialJson.topPosts);
				loginUser.loadMore = partialJson.loadMore;
				if (loginUser.loadMore.isFinalPage) {
					setPostsLoadinOrPostsEndOption(this, Const.END_OF_POSTS);
				}
			}
			else {
				// unlikely case: empy return
				loginUser.loadMore.isFinalPage = true;
				setPostsLoadinOrPostsEndOption(this, Const.END_OF_POSTS); 
			}
			unsetPostsLoadinOrPostsEndOption(this, Const.POSTS_ARE_LOADING);
			this.setState({ loginUser : loginUser });
		}
	}

	render() {
		const { loginUser, serviceErrorLevel, header, text, canProcessContactUs }  = this.state;
		let returnTo;
		let topPosts;

		if (serviceErrorLevel !== Const.NO_ERROR_LEVEL) {
			return messageModalWindow(this, header, text);
		}
		else if (!loginUser || !loginUser.hasOwnProperty('topPosts')) {
			if (!this.invokedByStartingComponent()) {
				return sleepingCat();
			}
			else {
				return null;
			}
		}
		topPosts = loginUser.topPosts;
		returnTo = '/' + HOME;
		loginUser.whereIam = Const.I_AM_AT_HOME;
		return getFullHomePage(canProcessContactUs, returnTo, topPosts, loginUser, this);
  	}
}