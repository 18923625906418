import React, { PureComponent } from 'react';
import { Row } from 'reactstrap';
import { MDBContainer, MDBModal, MDBModalBody } from 'mdbreact';
import * as BiIcons from 'react-icons/bi';
import * as MdIcons from 'react-icons/md';
import Const from '../Const';
import { invokeService } from '../Utils.js';
import { modalPopUpScrollProcessor } from '../ScrollUtils.js';

export default class GetMenu extends PureComponent {
	constructor(props) {
		super(props);

		const { menuParms } = props; 
		const { loginUser, post, that } = menuParms; 
		this.state = {
			modal: false,
			loginUser : loginUser,
			post : post,
			that : that
		};
		this.allowToggle = true;
	}

	toggle = () => {
		let loginUser = this.state.loginUser;

		if (loginUser && !loginUser.testDrive) {
			if (this.allowToggle) {
				modalPopUpScrollProcessor(this);
				this.setState({ modal : !this.state.modal });
			}
		 }      
	}

	async reportAbuse(post) {
		let data = {
			mode : Const.REPORT_ABUSE_MODE,
			post_id : post.postId,
		};
		await invokeService(Const.OCA_SERVICES_URL, data, this); 
		this.toggle();
	}

	blockPost(post, loginUser, that) {
		let blockedPostIds;
		
		if (loginUser.hasOwnProperty('blockedPostIds')) {
			blockedPostIds = loginUser.blockedPostIds;
		}
		else {
			blockedPostIds = [];
		}
		blockedPostIds.push(post.postId);
		loginUser.blockedPostIds = blockedPostIds;
		that.setState({loginUser : loginUser});
		this.toggle();
	}
 
   render() {
	  const { modal, loginUser, post, that } = this.state; 
    
      return (
			<>
				<div className="home-icons-medium right pointer-view report" onClick= {this.toggle }>
					<BiIcons.BiDotsVerticalRounded />
				</div>
				{
					modal && 
					<MDBContainer style={{width: '400px', height:'auto'}}>
						<MDBModal id="report" isOpen={modal} toggle={this.toggle} autoFocus={false}  size="sm">
							<div className="modal-myheader" id="header">
								<MdIcons.MdClose id="X" className="modal-close left pointer-view" onClick={this.toggle} />
							</div>
							
							<MDBModalBody id="main">       
								<form onSubmit={this.handleSubmit} className="mypostform report">  
									<Row className="bb-1 bt-1"> 
										<p className="pointer-view" onClick={()=>this.reportAbuse(post)} >Report</p>
									</Row>
									<Row className="bb-1">
										<p className="pointer-view" onClick={()=>this.blockPost(post, loginUser, that)} >Block</p>
									</Row>
								</form>
							</MDBModalBody> 
						</MDBModal>
					</MDBContainer>
				} 
			</>   
      );
   }
}