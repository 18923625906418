const carolineAndHerPosts =
{
  "userName" : "carolinekramerabstract",
  "userId" : 6,
  "userPicture": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRa5abWqOKhszN_CZzT3SwRd9kHZ9niMIDDxA&usqp=CAU",
  "userTagline": "Software Product",
  "userBio": "Mirka description of the Hightlights",
  "userWebsite": "www.abstractthoughts.com",
  "userEmail": "example@mail.com",
  "userNumberOfPosts": 10,
  "userNumberOfFollowers": 20,
  "userNumberOfFollowing": 30,
  "userKind" : "LAST_TEN",
  
  "userPosts" : [ 
         
    {
      "postId" : 1,
      "post": "Caroline post 1. Simplicity is the ultimate sophistication said Leonardo Da Vinci once.", 
      "postImg": "https://javacalculationengine.com/imgs4oca/whistler-summer-2019.jpg",
      "postKind" : "Top 10",
      "time": "2", 
      "commentsCount": 0,
        
      "likesCount": "2",
      "likes": [
        {
          "userPicture": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRa5abWqOKhszN_CZzT3SwRd9kHZ9niMIDDxA&usqp=CAU",
          "userName": "starlywave" 
        },
        {
          "userPicture": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRa5abWqOKhszN_CZzT3SwRd9kHZ9niMIDDxA&usqp=CAU",
          "userName": "carolinekramerabstract"
        }
      ],

      "sharesCount": 14,
    },

    {
      "postId" : 2,
      "post": "Caroline post 2. Everything that happens, happens for a reason", 
      "postImg": "https://javacalculationengine.com/imgs4oca/aspen-2.jpg",
      "time": "2",
      "commentsCount": 0,
      
      "likesCount": "2",
      "likes": [
        {
          "userPicture": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRa5abWqOKhszN_CZzT3SwRd9kHZ9niMIDDxA&usqp=CAU",
          "userName": "starlywave"
        },
        {
          "userPicture": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRa5abWqOKhszN_CZzT3SwRd9kHZ9niMIDDxA&usqp=CAU",
          "userName": "carolinekramerabstract"
        }
      ],

      "sharesCount": 33,    
    },

    {
      "postId" : 3,
      "post": "Caroline post 3. There is something about complexity that draws us towards it", 
      "postImg": "https://javacalculationengine.com/imgs4oca/whistler.jpg",
      "userComment": "In life we never know how things turn out333.",
      "time": "2", 
      "commentsCount": 66,
      
      "likesCount": "2",
      "likes": [
        {
          "userPicture": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRa5abWqOKhszN_CZzT3SwRd9kHZ9niMIDDxA&usqp=CAU",
          "userName": "starlywave" 
        },
        {
          "userPicture": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRa5abWqOKhszN_CZzT3SwRd9kHZ9niMIDDxA&usqp=CAU",
          "userName": "carolinekramerabstract"
        }
      ],

      "sharesCount": 20,
    }
  ],

  "whoIsUserFollowing" : [
    {
      "userId" : 1,
      "userName" : "OlegVoll77",
      "userPicture" : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSYwLEw7-5pUK0Y5HAl5RD9-wMUE9QNAabyxg&usqp=CAU"
    },
    {
      "userId" : 3,
      "userName" : "Fred Voll",
      "userPicture" : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSYwLEw7-5pUK0Y5HAl5RD9-wMUE9QNAabyxg&usqp=CAU"
    }

  ],

  "followers" : [
    {
      "userName" : "mirka_van_deroe",
      "userId" : 2,
      "userPicture": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR-JDp6VgvMkh8rqdpUFcMm2FsE_y8XSGwIVw&usqp=CAU",
      "userTagline": "Experimantal Group",
      "userBio": "Mirka description of the Hightlights",
      "userWebsite": "www.mirkaabstracts.com",
      "userEmail": "example233@mail.com",
      "userNumberOfPosts": 1000,
      "userNumberOfFollowers": 10,
      "userNumberOfFollowing": 300,
      "userKind" : "FOLLOWING",

    },

    {
      "userName" : "Fred Voll",
      "userId" : 3,
      "userPicture": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS3-vI1foTxugvrFoqEv8KQWAjAAzFvtsmB1Q&usqp=CAU",
      "userTagline": "Software Product",
      "userBio": "Fred description of the Hightlights",
      "userWebsite": "www.abstractthoughts.com",
      "userEmail": "example@mail.com",
      "userNumberOfPosts": 10,
      "userNumberOfFollowers": 20,
      "userNumberOfFollowing": 30,
      "userKind" : "FOLLOWING"
    }  
 ]

}
export default carolineAndHerPosts;