import React from "react";
import { Redirect } from 'react-router-dom';
import '../css/bootstrap.css';
import {Button, Form, FormGroup, Input, Row, Col} from 'reactstrap';
import * as RiIcons from 'react-icons/ri';
import * as BsIcons from "react-icons/bs";
import * as TiIcons from 'react-icons/ti';
import Envir from '../components/Envir';
import Const from '../components/Const';
import { invokeService, validateUserName, validateEmail, validatePasswordStrength, initUserBioProfile, 
         setLocalCookieForOcaUser, sleepingCat, contactUsWithEmail, messageModalWindow,
         getLocalCookieForOcaMkt, deleteLocalCookie } from './Utils.js';
import { isDesktop } from '../utils/AppUtils.js';
import Tippy from '@tippyjs/react';
import { roundArrow } from 'tippy.js';
import 'tippy.js/dist/svg-arrow.css';
import  { setOverflow, setWrapperHeight, onFocusHandler, onBlurHandler } from '../utils/LoginUtils.js';

const initialState = {
  name: "",
  email: "",
  fullName: "",
  company: "",
  password: "",
  nameError: "",
  emailError: "",
  passwordError: "",
  loginUser : null,
  messageModal: false,
  wrapperHeight: 0,
	styleObj : {},
  //
  serviceInvocationStatus : Const.SERVICE_IS_NOT_INVOKED_YET,
  serviceStatusCode : Const.INIT_VALUE,
  serviceErrorLevel : Const.NO_ERROR_LEVEL,
  serviceInvocationError : false,
  //
  flag : Const.INIT_VALUE,
  hide : true,
  //change class
  active: false,
  click : false
};

export default class Signup extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
    this.userNameDataTip = "A valid username should be between 6 and 30 characters. It cannot contain an &,  =,  _,  ',  -, +, \\," + 
									       	 "comma (,), <, >, or more than one period (.) in a row. It cannot begin or end with periods (.)." +  
										       "An @ character is allowed only at the beginning of the username";

    this.passwordDataTip  = "A valid password should be 7 to 20 characters long. It should contain an uppercase letter," +
                            "a lowercase letter, a number, and a special character such as a #, $, @, % etc. "  +
                            "Repetitive characters do not increase the password strength";
    this.wrapperRef = React.createRef();
  }

  async componentDidMount() {
    setOverflow(this);
		setWrapperHeight(this);
	}
  
  hideSwitch = ev => {
    //password show hide
    this.setState({ hide: !this.state.hide })
  }

  handleChange = event => {
    event.preventDefault();
    this.setState({
      [event.target.name] : event.target.value.trim(),
      nameErro : "",
      emailError: "",
      passwordError : ""
    });
  };

  onFocusHandler(e) {
    e.preventDefault();
  }

  validate = () => {
    let nameError = "";
    let emailError = "";
    let passwordError = "";
    let isUserNameValid = validateUserName(this.state.name);
    let isEmailValid = validateEmail(this.state.email);
    let passwordStrength = validatePasswordStrength(this.state.password);
    let ret = true;

    if (!isUserNameValid) {
      nameError = Const.VALID_USER_NAME_REQUIRED;
      ret = false;
    }
    if (!isEmailValid) {
      emailError = Const.VALID_EMAIL_REQUIRED;
      ret = false;
    }
    if (passwordStrength < Const.GOOD){
      passwordError = Const.VALID_PASSWORD_REQUIRED;
      ret = false;
    }
    this.setState({ nameError, emailError, passwordError });
    return ret;
  };

  handleSubmit = async event => {
    let isValid;
    let loginUser;
    let response;
    let marketPlaceCustomerID;

    event.preventDefault();
    isValid = this.validate();
    if (isValid) {
      response = await this.createUserInDB();
      if (this.state.serviceErrorLevel === Const.NO_ERROR_LEVEL) {
        loginUser = initUserBioProfile(this.state.name);
        marketPlaceCustomerID = await this.mapUserToMarketPlaceCustomerInDB();
        if (marketPlaceCustomerID) {
          loginUser.marketPlaceCustomerID = marketPlaceCustomerID;
        }
        this.setState({loginUser : loginUser});
        setLocalCookieForOcaUser(this.state.name, response.result.edt);
      }
      if (this.state.serviceErrorLevel === Const.USER_ERROR_LEVEL) {
        this.setState({
          nameError : Const.CREDENTIALS_ALREADY_PRESENT,
          serviceInvocationStatus : Const.SERVICE_IS_NOT_INVOKED_YET
       });
      }
    }
	 
  };

  async createUserInDB() {
    let  data = {
      // Signup service
      "user_name": this.state.name,
      "email": this.state.email,
      "password": this.state.password,
      "full_name" : this.state.fullName.trimEnd(),
      "company" : this.state.company,
      "keep" : Const.KEEP_ME_SIGNED_IN,
      "mode" : Const.SIGNUP_MODE
    };
    let response = await invokeService(Const.LOGIN_URL, data, this);
    
    return response;
  }

  /*change class*/
  toggleClass() {
    const currentState = this.state.active;
    this.setState({ active: !currentState });
	};

  getToolTip(inputFieldName) {
    let dataTip = (inputFieldName === 'userName') ? this.userNameDataTip : this.passwordDataTip

    if (isDesktop()) {
      return (
        <Tippy 
            content= { dataTip } 
            theme ='medeata-signup-desktop' 
            arrow = { roundArrow }
            placement = "right" >
              <span><TiIcons.TiInfoOutline className="info" /></span>
        </Tippy>
      )
    }
    else {
      return (
         <Tippy 
            content= { dataTip } 
            theme ='medeata-signup' 
            arrow = { roundArrow }
            trigger = 'click' >
              <span><TiIcons.TiInfoOutline className="info" /></span>
         </Tippy>
      )
    }
  }

  async mapUserToMarketPlaceCustomerInDB() {
    let mktPlaceCookieObject = await getLocalCookieForOcaMkt(); 
		let  data;
    let marketPlaceCustomerID = "";

    if (mktPlaceCookieObject) {
      data = {
        "mode" : Const.OCA_MAP_MARKET_PLACE_CUSTOMER,
        "customer_id": mktPlaceCookieObject.customer_id,
        "pricing_dimension": mktPlaceCookieObject.pricing_dimension,
        "user_name": this.state.name
      };
      await invokeService(Const.OCA_SERVICES_URL, data, this);
      if (this.state.serviceErrorLevel === Const.NO_ERROR_LEVEL) {
        if (mktPlaceCookieObject.pricing_dimension === '1') {
          marketPlaceCustomerID = mktPlaceCookieObject.customer_id;
        }
      }
      deleteLocalCookie(Const.DELETE_MKT_COOKIE);
    }
    return marketPlaceCustomerID;
	}

  render() {
    const { serviceInvocationError, serviceErrorLevel, serviceInvocationStatus, serviceStatusCode, hide, 
            loginUser, styleObj } = this.state;
    let personalOrCompany = null;
    const focus = isDesktop() ? true : false;

    if (this.props.location && this.props.location.hasOwnProperty('params')) {
      personalOrCompany = this.props.location.params.personalOrCompany;
    }
    if (serviceErrorLevel === Const.USER_ERROR_CRITICAL_LEVEL) {
			return (
			  messageModalWindow(this, this.state.header, this.state.text)
			)
		}
		else if (serviceInvocationError || (serviceErrorLevel === Const.SYS_ERROR_LEVEL)) {
			return (
				messageModalWindow(this, this.state.header, this.state.text)
			)
		} 
    else if (serviceStatusCode === Const.NO_COOKIE_IN_HEADERS) {
      return <div>{Const.NO_COOKIE_IN_HEADERS_CHANGE_SETTINGS_MESSAGE}</div>;
    } 
    else if (serviceStatusCode === Const.NOT_CRITICALLY_BAD_COOKIE_WAS_SENT) {
      return <div>{Const.BAD_COOKIE_MESSAGE_CLOSE_BROWSER}</div>;
    } 
    else if (loginUser) {
        loginUser.testDrive = false;
        return <Redirect to={{ pathname: "/Profile", params: { loginUser } }} />;
    } 
    else if ((serviceInvocationStatus !== Const.SERVICE_IS_NOT_INVOKED_YET)){
      return sleepingCat();
    } 
    else {
      return (
        <div className="App-div" >
          <div ref={ this.wrapperRef } style = {styleObj} className="after-app-wrap-center-login-div-walk-sign" >
          	<div className="signup-background">

              <div className="signup-form sign-up">
                <Form onSubmit={this.handleSubmit}>
                  <FormGroup >
                    <div className="login-form-row-no-label">
                      <Row className="ml-0">
                          <Col md={11} xs={11} className="input-with-icon">
                            <Input className="x-input"
                              autoFocus={ focus }
                              name="name" 
                              placeholder="Username"
                              type="text" required
                              value={this.state.name}
                              onChange={this.handleChange}
                              onFocus={() => onFocusHandler(this)}
                              onBlur={() => onBlurHandler(this)}
                            />
                          </Col>
                          <Col md={1} sm={1} xs={1} className="p-0">
                               { this.getToolTip('userName') }
                          </Col>
                      </Row>
                      <div className = "error-message">
                        {this.state.nameError}
                      </div>
                    </div>
                    <div className="login-form-row-no-label margin-against-row ">
                      <div className="input-align" >
                        <Input className="x-input"
                          name="email" 
                          placeholder="Email"
                          type="text" required
                          value={this.state.email}
                          onChange={this.handleChange}
                          onFocus={() => onFocusHandler(this)}
                          onBlur={() => onBlurHandler(this)}
                        />
                        <div className = "error-message">
                          <span>{this.state.emailError} </span>
                        </div>
                      </div>
                    </div>
                    <div className="login-form-row-no-label margin-against-row">
                      <div className="input-align">
                        <Input className="x-input"
                          name="Full name" 
                          placeholder="Full name"
                          type="text" required
                          value={this.state.fullName}
                          onChange={e => this.setState({fullName : e.target.value.trimStart()})} 
                          onFocus={() => onFocusHandler(this)}
                          onBlur={() => onBlurHandler(this)}
                        />
                       </div>
                    </div>
                    {
                      (personalOrCompany === 'c') &&
                       <div className="login-form-row-no-label margin-against-row">
                       {
                         <div className="input-align">
                           <Input className="x-input"
                             name="Compnay" 
                             placeholder="Company"
                             type="text" required
                             value={this.state.company}
                             onChange={e => this.setState({company : e.target.value.trimStart()})} 
                             onFocus={() => onFocusHandler(this)}
                             onBlur={() => onBlurHandler(this)}
                           />
                         </div>
                       }
                     </div>
                    }
                    <div className="login-form-row-no-label">
                          <Row className="ml-0">
                              <Col md={11} xs={11} className="input-with-icon">
                                  <Input 
                                    type={hide ? 'password' : 'input'} className="x-input form-control"
                                    name="password"
                                    maxLength = {20}
                                    required 
                                    placeholder="Password"
                                    value={this.state.password}
                                    onChange={this.handleChange}
                                    onFocus={() => onFocusHandler(this)}
                                    onBlur={() => onBlurHandler(this)}
                                  />
                                  <div className="eye" onClick={this.hideSwitch}>
                                    {hide ? <RiIcons.RiEyeCloseLine /> : <BsIcons.BsEye />}
                                  </div>
                                  <div className = "error-message">
                                    <span>{this.state.passwordError} </span>
                                  </div>
                              </Col>
                              <Col md={1} sm={1} xs={1} className="p-0">
                                { this.getToolTip('password') }
                              </Col>                            
                          </Row>
                    </div>
                    <div className="signup-form-row">
                      <Button className="btn-login-signup btn-lg btn-block btn-dark mb-2 margin-against-row" type="Submit">Sign Up </Button>
                      <a className="a-login-signup" href={Envir.LOGIN_FOLDER + 'login'}>Log in</a>
                    </div>
                    {
                      !isDesktop() &&
                      <div className="padding"></div>
                    }
                  </FormGroup>
                </Form>
              </div> 
            </div>
          </div> 
          <div className="after-app-wrap-center-basic"> 
						{contactUsWithEmail()} 
					</div>
        </div>
      );
    }
  }
}