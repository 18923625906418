import GetYourPostTablet from './GetYourPostTablet';

export default class GetYourPostTabletPortret extends GetYourPostTablet {
	constructor(props) {
		super(props);
		this.state.isKeyboardUp = true; // this is a const value in this class
		this.onFocusHandler = this.onFocusHandler.bind(this);
		this.onBlurHandler = this.onBlurHandler.bind(this);
	}

	onFocusHandler = () => {
		this.setState({ backgroundImageClass : this.BACKGROUND_IMAGE_CLASS_NO_IMAGE });
	}

	onBlurHandler = async () =>  {  
		setTimeout(() => {
			this.setState(
				{ 
					backgroundImageClass : (this.state.activeButton === 'button1') ? this.BACKGROUND_IMAGE_CLASS
																				   : this.BACKGROUND_IMAGE_CLASS_NO_IMAGE
				});
		}, this.TIMEOUT);
	}
}