import { nanoid } from 'nanoid';
import Envir from '../components/Envir';
import { bottomOfPage } from '../components/ScrollUtils.js';
import { setThemeFromCookieSync } from '../utils/CapacitorUtils.js';
import  { setFontSize, setConciergeImageProperties } from '../utils/CssUtils.js';
import  { initAItextAreaHeight, setAIsendArrowHeight } from '../utils/AIUtils.js';

let version = -1;
let clientId = -1;
let loadingImageObserver = null;
let windowInnerWidth = 0;
let componentClassAr = [];
let zoomObj = {
  zoomLevel : 1,
  userPinchDetected : false
}

export function setSession() {
  setClientId();
  setClientVersion();
  // sync way of getting preference cookie and setting default session theme for none Android / iOS App
  // to avoid screen ficker; for Android / iOS it is done in async way behind Splash screen
  setThemeFromCookieSync();
  createLoadingingImageObserver();
  setFontSize();
  initAItextAreaHeight();
  setAIsendArrowHeight();
  setConciergeImageProperties();
  addSessionOrientationChangeListener();
  if (Envir.iOS_APP) {
    addSessionTouchMoveListener();
  }
}

export function setClientId() {
  clientId = nanoid();
}

export function setClientVersion() {
  version = 1;
}

export function getClientId() {
  return clientId;
}

export function getClientVersion() {
  return version;
}

export function getLoadingImageObserver() {
  return loadingImageObserver;
}

export function createLoadingingImageObserver() {
  let observer;
  let options = {
    root: null,
    rootMargin: '0px 0px 50px 0px',
    threshold: 0.1,
  };

  observer = new IntersectionObserver(async (entries) => {
    if (entries[0].isIntersecting) {
       // loadingImg is visible
      await bottomOfPage();
    }
  }, options);
  loadingImageObserver = observer;
}

export async function bottomOfPageSync() {
  (async () => await bottomOfPage())(); // call async function from sync
}

export function addSessionOrientationChangeListener() {
  window.addEventListener('resize', sessionOrientationChangeListener);
  windowInnerWidth = window.innerWidth;
}

export function sessionOrientationChangeListener() {
  removeResedualZoomEffect();
  if (windowInnerWidth !== window.innerWidth) {
    windowInnerWidth = window.innerWidth;
    for (let ind in componentClassAr) {
      componentClassAr[ind].orientationChangeProcessor();
    }
  }
}

export function addOrientationChangeListener(that) {
  componentClassAr.push(that);
}

export function removeOrientationChangeListener() {
  componentClassAr.pop();
}

export function addSessionTouchMoveListener() {
    window.addEventListener('touchmove', sessionTouchMoveListener);
}

export function sessionTouchMoveListener(e) {
  if (e.touches.length >= 2) {
    zoomObj = {
      zoomLevel : window.innerWidth / document.documentElement.clientWidth,
      userPinchDetected : true
    }
  }
}

export function removeResedualZoomEffect() {
  if ((zoomObj.zoomLevel === 1) && (zoomObj.userPinchDetected)) {
    // only iOs app: user zoomed in and zoomed out to original state:
    // nedd to restart to avoid zoomed in screen on rotation
    window.location = '/'
  }
}