const mirkaAndHerPosts =
{
  "userName" : "mirka_van_deroe",
  "userId" : 2,
  "userPicture": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR-JDp6VgvMkh8rqdpUFcMm2FsE_y8XSGwIVw&usqp=CAU",
  "userTagline": "Experimantal Group",
  "userBio": "Mirka description of the Hightlights",
  "userWebsite": "www.mirkaabstracts.com",
  "userEmail": "example233@mail.com",
  "userNumberOfPosts": 1000,
  "userNumberOfFollowers": 10,
  "userNumberOfFollowing": 300,
  "userKind" : "FOLLOWING",
  
  "userPosts" : [
     {
      "postId" : 1,
      "post": "Mirka Post 1. Simplicity is the ultimate sophistication said Leonardo Da Vinci once. llllllllllllllllll", 
      "postImg": "https://javacalculationengine.com/imgs4oca/whistler.jpg",
      "postKind": "Popular",
      "time": "21 July 2021",
      
      "commentsCount": "2",
      "comments": [
          {
            // "commentId": 1,
            "userPicture": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS3-vI1foTxugvrFoqEv8KQWAjAAzFvtsmB1Q&usqp=CAU",
            "userName" : "Fred Voll",
            "comment" : "Fred Comment."
          },
          {
            // "commentId": 2,
            "userPicture": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRa5abWqOKhszN_CZzT3SwRd9kHZ9niMIDDxA&usqp=CAU",
            "userName": "starlywave",
            "comment" : "starlywave Comment"
          }
        ],  

      "likesCount": "2",
      "likes": [
          {
            "userName" : "OlegVoll77",
            "userPicture" : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSYwLEw7-5pUK0Y5HAl5RD9-wMUE9QNAabyxg&usqp=CAU"
          },
          {
            "userPicture": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS3-vI1foTxugvrFoqEv8KQWAjAAzFvtsmB1Q&usqp=CAU",
            "userName" : "Fred Voll"
          }
        ], 

      "sharesCount": "3", 
     },

      
     {
       "postId" : 2,
       "post": "Mirka Post 2. There is something about complexity that draws us towards it", 
       "postImg": "https://javacalculationengine.com/imgs4oca/whistler-summer-2019.jpg",
       "time": "2",

       "commentsCount": "3",
       "comments": [
          {
            // "commentId": 1,
            "userName" : "mirka_van_deroe",
            "comment" : "In life we never know how things turn out."
          },
          {
            // "commentId": 2,
            "userName": "starlywave",
            "comment" : "starlywave Comment"
          },
          {
            // "commentId": 3,
            "userName": "carolinekramerabstract",
            "comment" : "carolinekramerabstractcomments Comment"
          }
        ],  

        "likesCount": "1",
        "likes": [
          {
            "userName" : "OlegVoll77",
            "userPicture" : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSYwLEw7-5pUK0Y5HAl5RD9-wMUE9QNAabyxg&usqp=CAU"
          }                      
        ], 

       "sharesCount": "4"      
     },
     
     {
       "postId" : 3,
       "post": "Mirka Post 3. Everything that happens, happens for a reason", 
       "postImg": "https://javacalculationengine.com/imgs4oca/whistler-20190703_130119.jpg",
       "time": "3",

       "commentsCount": "3",
       "comments": [
          {
            // "commentId": 1,
            "userName" : "Fred Voll",
            "comment" : "In life we never know how things turn out."
          },
          {
            // "commentId": 2,
            "userName": "starlywave",
            "comment" : "starlywave Comment"
          },
          {
            // "commentId": 3,
            "userName": "carolinekramerabstract",
            "comment" : "carolinekramerabstractcomments Comment"
          }
        ],  

        "likesCount": "3",
        "likes": [
          {
            "userName" : "OlegVoll77",
            "userPicture" : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSYwLEw7-5pUK0Y5HAl5RD9-wMUE9QNAabyxg&usqp=CAU"
          },
          {
            "userPicture": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS3-vI1foTxugvrFoqEv8KQWAjAAzFvtsmB1Q&usqp=CAU",
            "userName" : "Fred Voll"
          },
          {
            "userPicture": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRa5abWqOKhszN_CZzT3SwRd9kHZ9niMIDDxA&usqp=CAU",
            "userName": "carolinekramerabstract"
          }
        ], 

        "sharesCount": "4000" 
      },

      {
        "postId" : 4,
        "post": "Mirka Post 4. Everything that happens, happens for a reason", 
        "postImg": "https://javacalculationengine.com/imgs4oca/aspen-2.jpg",
        "time": "3",

        "commentsCount": "3",
        "comments": [
          {
            // "commentId": 1,
            "userName" : "Fred Voll",
            "comment" : "In life we never know how things turn out."
          },
          {
            // "commentId": 2,
            "userName": "starlywave",
            "comment" : "starlywave Comment"
          },
          {
            // "commentId": 3,
            "userName": "carolinekramerabstract",
            "comment" : "carolinekramerabstractcomments Comment"
          }
        ],  

        "likesCount": "3",
        "likes": [
          {
            "userName" : "OlegVoll77",
            "userPicture" : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSYwLEw7-5pUK0Y5HAl5RD9-wMUE9QNAabyxg&usqp=CAU"
          },
          {
            "userPicture": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS3-vI1foTxugvrFoqEv8KQWAjAAzFvtsmB1Q&usqp=CAU",
            "userName" : "Fred Voll"
          },
          {
            "userPicture": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRa5abWqOKhszN_CZzT3SwRd9kHZ9niMIDDxA&usqp=CAU",
            "userName": "carolinekramerabstract"
          },

          
        ], 

        "sharesCount": "4000" 
      },

      {
        "postId" : 5,
        "post": "Mirka Post 5. Post without image", 
        "time": "3",

        "commentsCount": "3",
        "comments": [
          {
            // "commentId": 1,
            "userName" : "Fred Voll",
            "comment" : "In life we never know how things turn out."
          },
          {
            // "commentId": 2,
            "userName": "starlywave",
            "comment" : "starlywave Comment"
          }
        ],  

        "likesCount": "3",
        "likes": [
          {
            "userName" : "OlegVoll77",
            "userPicture" : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSYwLEw7-5pUK0Y5HAl5RD9-wMUE9QNAabyxg&usqp=CAU"
          },
          {
            "userPicture": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS3-vI1foTxugvrFoqEv8KQWAjAAzFvtsmB1Q&usqp=CAU",
            "userName" : "Fred Voll"
          }
        ], 

        "sharesCount": "4000" 
      }  
  ],

   
   "whoIsUserFollowing" : [
    {
      "userId" : 1,
      "userName" : "OlegVoll77",
      "userPicture" : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSYwLEw7-5pUK0Y5HAl5RD9-wMUE9QNAabyxg&usqp=CAU"
    },
    {
      "userId" : 3,
      "userName" : "Fred Voll",
      "userPicture": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS3-vI1foTxugvrFoqEv8KQWAjAAzFvtsmB1Q&usqp=CAU"
    }

  ],

  "userCollections" : [
     {
       "collectionId" : 1,
       "collectionName" : "Aspen",
       "postImg": "https://javacalculationengine.com/imgs4oca/whistler.jpg",
       "collectionPosts": [
          {
            "userPicture": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRa5abWqOKhszN_CZzT3SwRd9kHZ9niMIDDxA&usqp=CAU",
            "userName" : "starlywave",
            "post": "Starlywave Post 1. Simplicity is the ultimate sophistication said Leonardo Da Vinci once.", 
            "postImg": "https://javacalculationengine.com/imgs4oca/asp-20190314_110316.jpg"
          },
          {
            "userPicture": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRa5abWqOKhszN_CZzT3SwRd9kHZ9niMIDDxA&usqp=CAU",
            "userName" : "starlywave",
            "post": "Starlywave Post 2. Everything that happens, happens for a reason", 
            "postImg": "https://javacalculationengine.com/imgs4oca/aspen-2.jpg"
          }
        ] 
      },

      {
        "collectionId" : 2,
        "collectionName" : "Jackson",
        "postImg": "https://javacalculationengine.com/imgs4oca/whistler.jpg",
        "collectionPosts": [
           {
             "userPicture": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRa5abWqOKhszN_CZzT3SwRd9kHZ9niMIDDxA&usqp=CAU",
             "userName" : "fabiangonzalezcolo",
             "post": "Fabian Post 1. Simplicity is the ultimate sophistication said Leonardo Da Vinci once.", 
             "postImg": "https://javacalculationengine.com/imgs4oca/jackson-20190128_140201.jpg"
           },
           {
             "userPicture": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRa5abWqOKhszN_CZzT3SwRd9kHZ9niMIDDxA&usqp=CAU",
             "userName" : "fabiangonzalezcolo",
             "post": "Fabian Post 2. Everything that happens, happens for a reason", 
             "postImg": "https://javacalculationengine.com/imgs4oca/jackson-20190128_140438.jpg"
           }
        ] 
      }
  ]
}
export default mirkaAndHerPosts;