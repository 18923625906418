import React, { Component } from 'react';
import {Row, Card } from 'reactstrap';
import { Link } from 'react-router-dom';
import Const from '../Const';
import { getUserPictureClassName } from '../../components/Utils.js';
import { postsAndFollowersClassNames } from '../../utils/PostUtils.js';

class GetChannels extends Component {
    constructor(props) {
        super(props); 
        this.I_FOLLOW = 'I Follow';
        this.MY_FOLLOWERS = 'My Followers';
        this.USER_FOLLOWS = 'Follows';
        this.USER_FOLLOWERS = 'Followers';
    }

    getEachFollowingOrFollower(parentOrChildComponent, personIFollow, loginUser) {
        let avatarClassName = getUserPictureClassName(personIFollow);
        let pfClassNames = postsAndFollowersClassNames('');
        let userNameClassName = "mb-0 username " + pfClassNames.username;
        let taglineClassName = "medium mb-0 tagline " + pfClassNames.tagline;

		if ((personIFollow.userName !== loginUser.userName) ) { 
			return (
                    <Link to={{ pathname: parentOrChildComponent, params: { personIFollow, loginUser} }}>
                        <Row className={ pfClassNames.sugested } body-scroll-lock-ignore="true" >                           
                            {
                                personIFollow.hasOwnProperty('userPicture') 
                                ? <div body-scroll-lock-ignore="true" className={avatarClassName}><img src= { personIFollow.userPicture}  alt="Avatar" /></div>
                                : <div body-scroll-lock-ignore="true" className={avatarClassName}><img src={ Const.DEFAULT_USER_PICTURE } alt="Avatar" /></div>
                            }
                                                            
                            <div className="info-f" body-scroll-lock-ignore="true">
                                <p className={ userNameClassName } body-scroll-lock-ignore="true"> { personIFollow.userName} </p>
                                <p className={ taglineClassName } body-scroll-lock-ignore="true"> {personIFollow.userTagline} </p>
                            </div> 
                        </Row>
                    </Link>
			       )
		}
        else {
            return (
                    <Row className="suggested">
                        {
                            personIFollow.hasOwnProperty('userPicture') 
                            ? <div className={avatarClassName}><img src= { personIFollow.userPicture} alt="Avatar" /></div>
                            : <div className={avatarClassName}><img src={ Const.DEFAULT_USER_PICTURE } alt="Avatar" /></div>
                        }
                        <div className="info-f">
                            <p className="mb-0 username"> Me </p>
                        </div> 
                    </Row>
                   )
        }
	}

    componentDidUpdate() {
        console.log('');
    }

    render() {
        const { multipleProps } = this.props; 
        const { loginUser, userAndHisAssosiatedUsers } = multipleProps; 
        const loginUserFollowingThesePeople = userAndHisAssosiatedUsers.whoIsUserFollowing ? userAndHisAssosiatedUsers.whoIsUserFollowing : [];
        const loginUserFollowers = userAndHisAssosiatedUsers.followers ? userAndHisAssosiatedUsers.followers : [];
        const followingWollowres = loginUser.showFollowig ? loginUserFollowingThesePeople : loginUserFollowers;
        const parentOrChildComponent = '/User';
        let header;
        let pfClassNames = postsAndFollowersClassNames('');

        if (loginUser.userName === userAndHisAssosiatedUsers.userName) {
            if (loginUser.showFollowig) {
                header = followingWollowres.length > 0 ?  this.I_FOLLOW : '';
            }
            else {
                header = followingWollowres.length > 0 ? this.MY_FOLLOWERS : '';
            }
        }
        else {
            if (loginUser.showFollowig) {
                header = followingWollowres.length > 0 ? this.USER_FOLLOWS : '';
            }
            else {
                header = followingWollowres.length > 0 ? this.USER_FOLLOWERS : '';
            }
        }
        return (
            (followingWollowres.length > 0) 
              ?  <>
                    <p className={ pfClassNames.iFollow } style={{margin: '0', paddingLeft: '0.5rem'}}>{header}</p>
                    { followingWollowres.map(personIFollow =>
                        <Card folowers-item="true" className="shell" key={personIFollow.userId}> 
                            {
                                this.getEachFollowingOrFollower(parentOrChildComponent, personIFollow, loginUser)
                            }
                        </Card>
                    )}
                </>
              : null
        );
    }
  }
  
  export default GetChannels;