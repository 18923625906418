import React from 'react';
import '../css/userPicture.css';
import DefaultUserPicture from '../images/default_user.jpg';
import Const from './Const';
import { invokeService, dateToNumberDate, messageModalWindow, readLocalImageFile, extractImageParts } from './Utils.js';

const ImgUpload =({ onChange, src, loginUser })=>{
	return (
			<label className="custom-file-upload fas">
			<div className="img-wrap img-upload" >
				<div className="avatar-big">
					<img alt="Edit" className="" src={src}/>
				</div>
			</div>
            {
				loginUser && !loginUser.testDrive &&
				<input id="photo-upload" type="file" onChange={onChange} 
				  accept={Const.ACCEPTED_IMAGE_TYPES}
				/> 
			}
			
			</label>
	       );
}

const Edit = ({ onSubmit, children }) => {
	let profilePicture = children[0];
	let enableButton = children[1];
	let loginUser = children[2];
	
	if (loginUser.testDrive) {
		return (
			<div>
				<div>
					{ profilePicture }
				</div>
			</div>			
	    );
	}
	else {
		return (
			<div>
				<form onSubmit={onSubmit}>
					{ profilePicture }

					<p className="change-pic font-small">Click on image to change</p>
					
					{
						enableButton
						? <button id="savePictureId" type="submit" className="btn btn-sm btn-outline-primary"> Save Profile Picture</button>
						: <button id="savePictureId" className="btn btn-sm btn-outline-primary pointer-noview" disabled = { true }> Save Profile Picture</button>
					}
				</form>
			</div>			
	    );
	}
}

export default class EditUserPicture extends React.Component {
	constructor(props) {
		super(props);
		const { editPictureParams } = this.props;
		const { loginUser, that } = editPictureParams;
		this.state = {
			file: '',
			imagePreviewUrl: this.initUserPicture(loginUser),
			serviceInvocationStatus : Const.SERVICE_IS_NOT_INVOKED_YET,
			serviceStatusCode : Const.INIT_VALUE,
			serviceErrorLevel : Const.NO_ERROR_LEVEL,
			serviceInvocationError : false,
			flag : Const.INIT_VALUE,
			parent : that,
			loginUser : loginUser,
			messageModal: false,
			header : '',
			text : '',
			savePicturedEnabled : false
		};
	}

	initUserPicture(loginUser) {
		let pictureUrl = loginUser.hasOwnProperty('userPicture') ? loginUser.userPicture 
		                                                         : DefaultUserPicture
		return pictureUrl;
	}

	async componentDidMount() {
		let savePictureButton = document.getElementById('savePictureId');

		if (savePictureButton) {
			savePictureButton.setAttribute('disabled', 'disabled');
		}
	}

	pictureUpload(e) {
		let isPostImage = false;

		e.preventDefault();
		readLocalImageFile(e, this, isPostImage);
	}

	onPictureUploadCompletion(success, that) {
		// this function is invoked on a call back after image resizing
		let initIimagePreviewUrl = that.initUserPicture(that.state.loginUser);

		if (success) {			 
			that.setState({ savePicturedEnabled : true});
			
		}
		else {
			that.setState({ imagePreviewUrl: initIimagePreviewUrl, savePicturedEnabled : false });
		}
	}
	
	async handleSubmit(e) {
		let localLoginUser;
		let today = new Date();
		let todayNumber = dateToNumberDate(today);
		let savePictureButton = document.getElementById('savePictureId');
		let initIimagePreviewUrl = this.initUserPicture(this.state.loginUser);
		
		e.preventDefault();
		this.state.parent.disableEnableUpperPartOfThescreen(true);
		savePictureButton.setAttribute('disabled', 'true');
		await this.storeUserPictureInDB(todayNumber);
		this.state.parent.disableEnableUpperPartOfThescreen(false);
		if (this.state.serviceErrorLevel === Const.NO_ERROR_LEVEL) {
			// 1. at this point we succesfuly saved profile picture in S3 bucket
			// 2. the url to user profile pictures is always the same : Const.USER_PICTURE_URL  
			// 3. each individual profile picture is Const.USER_PICTURE_URL + user name
			localLoginUser = this.state.parent.state.loginUser;
			localLoginUser.userPicture = Const.USER_PICTURE_URL + localLoginUser.userName + '/' + todayNumber;
			// now we need to change the state of loginUser of ProfileOfLoginUser component 
			// once we do it, the ProfileOfLoginUser render will be invoked (this is React main rule) and
			// ProfileOfLoginUser's screen will be re-rendered with the updated picture of loginUser
			// (this will also invoke the re-redendering of Navi).
			this.updateUserPictureTypeInMemory(localLoginUser);
			this.state.parent.setState({
				loginUser : localLoginUser
			}); 
		}
		else {
			this.setState({
				imagePreviewUrl: initIimagePreviewUrl
			});
		}
		this.setState({ savePicturedEnabled : false }); 
	}

	async storeUserPictureInDB(todayNumber) {
		let imagePreviewUrl = this.state.imagePreviewUrl; //this.ImageUploadder.current.state.file;
		let imageParts;
		let imageType = "";
		let imageBody = "";
		let data;
		let userPictureType = '0';

		if (imagePreviewUrl) {
			imageParts = extractImageParts(imagePreviewUrl);
			imageType = imageParts.image_type;
			imageBody = imageParts.image_body;
			userPictureType = (this.imageParams.maxWidth / this.imageParams.maxHeight) < 1 ? '1' : '0';
		}
		data = {
			number_date : todayNumber,
			image_type : imageType,
			image : imageBody,
			user_picture_type : userPictureType,
			mode : Const.STORE_USER_PICTURE_MODE
		}
		await invokeService(Const.OCA_SERVICES_URL, data, this);
	}

	async updateUserPictureTypeInMemory(loginUser) {
		let userPictureType = (this.imageParams.maxWidth / this.imageParams.maxHeight) < 1 ? '1' : '0';

		loginUser.userPictureType = userPictureType;
		if (loginUser.hasOwnProperty('userPosts')) {
			for (let post of loginUser.userPosts) { 
			  post.userPictureType = userPictureType;
			}
		}
		return loginUser;
	}

	render() {
		const { imagePreviewUrl, savePicturedEnabled, loginUser } = this.state;
		
		return (
			<>
				<div>
					{  
						<Edit onSubmit={(e) => this.handleSubmit(e)}>
							<ImgUpload onChange={(e)=>this.pictureUpload(e)} src={ imagePreviewUrl } loginUser = {loginUser}/>
							{ savePicturedEnabled }
							{ loginUser }
						</Edit>
					}       
				</div>
				<div>
					{
						messageModalWindow(this, this.state.header, this.state.text)
					}
				</div>
			</>
		)
	}
}