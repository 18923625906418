import React from "react";
import Const from '../components/Const';
import { invokeService, deleteAllLocalCookies, sleepingCat, messageModalWindow } from './Utils.js';

export default class Signout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      messageModal : false,
      serviceInvocationStatus : Const.SERVICE_IS_NOT_INVOKED_YET,
      serviceStatusCode : Const.INIT_VALUE,
      serviceErrorLevel : Const.NO_ERROR_LEVEL,
      serviceInvocationError : false,
    };
  }

  async componentDidMount() {
    let  data = {
          // Logout service 
          "mode" : Const.LOGOUT_MODE
        };

    await invokeService(Const.LOGIN_URL, data, this);
    deleteAllLocalCookies();
    if (this.state.serviceErrorLevel === Const.NO_ERROR_LEVEL) {
      window.location = '/';
    }
  }

  render() {
    const { serviceInvocationError, serviceErrorLevel, serviceStatusCode } = this.state;

    if (serviceErrorLevel === Const.USER_ERROR_CRITICAL_LEVEL) {
			return (
			        messageModalWindow(this, this.state.header, this.state.text)
			       )
		}
    else if (serviceInvocationError || (serviceErrorLevel === Const.SYS_ERROR_LEVEL)) {
			return (
				messageModalWindow(this, this.state.header, this.state.text)
			)
		} 
    else if (serviceStatusCode === Const.NOT_CRITICALLY_BAD_COOKIE_WAS_SENT) {
      return <div>{Const.BAD_COOKIE_MESSAGE_CLOSE_BROWSER}</div>;
    } 
    else {
      return sleepingCat();
    } 
  }
}