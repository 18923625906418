import {PinchToZoom} from 'pinch-to-zoom';
import Envir from '../components/Envir';
import { getDevice } from '../components/Utils.js';
import Const from '../components/Const';
import { HOME } from '../components/ScrollUtils';

const DYNAM_ID_ONE = 'pinchZoomId01';
const DYNAM_ID_TWO = 'pinchZoomId02';
const DYNAM_ID_TREE = 'pinchZoomId03';
const BIG_Z_INDEX = 3000;

let appLayoutObject = {
  homeLayout : false,
  windowInnerWidth : 0,
}

let componentClass;

export function createAppHomeLayout() {
  let container;
  let posts;
  let div;
  let divCopy;
  let followersWrapper;
  let containerOffsetTop;

  if (Envir.ANDROID_APP) {
      container = document.getElementById('container');
      posts = document.getElementById('posts');
      if (container && posts && posts.firstChild && (posts.firstChild.id !== DYNAM_ID_ONE)) {
        followersWrapper = document.getElementById('followersWrapper');
        if (container && posts) {
          containerOffsetTop = container.offsetTop;
          container.style.top = 0; // needed to zoom the first posts image to the size of device
          div = createDiv(containerOffsetTop, DYNAM_ID_ONE);
          posts.prepend(div);
          if (!isMobilLayout(container, posts)) {
            divCopy = createDiv(containerOffsetTop, DYNAM_ID_TWO);
            followersWrapper.prepend(divCopy);
          }
          appLayoutObject.imgZoomLayoutInitialized = true;
        }
      }
  }
}

export function createAppProfileLayout() {
  let main;
  let container;
  let div;
  let divCopy;
  let posts;
  let postsRow;
  let followersWrapper;
  let containerOffsetTop;
  let  mainOffsetHeight;
  let followersAlreadyPositioned;
 
  if (Envir.ANDROID_APP) {
      // posts can be added and removed during the profile user session
      container = document.getElementById('container');
      posts = document.getElementById('posts');
      if (container && posts && posts.firstChild && (posts.firstChild.id !== DYNAM_ID_ONE)) {
        main = document.getElementById('main');
        postsRow = document.getElementById('postsRow');
        followersWrapper = document.getElementById('followersWrapper');
        mainOffsetHeight = main.offsetHeight;
        main.style.zIndex = 1;
        main.style.position = 'relative'; // important, othervise profile div is greyed out (not clear why?)
        positionContainer(container);
        containerOffsetTop = container.containerOffsetTop;
        followersAlreadyPositioned = container.followersAlreadyPositioned;
        // the following marginTop will bring posts to the top of device
        // adding div below will bring it back, but now first image can be fully zoomed
        postsRow.style.marginTop = '-' + (containerOffsetTop + mainOffsetHeight) + 'px';
        // the following div is needed to properly show posts
        div = createDiv(containerOffsetTop + mainOffsetHeight, DYNAM_ID_ONE);
        posts.prepend(div);
        if (!isMobilLayout(container, posts) && !followersAlreadyPositioned) {
          divCopy = createDiv(containerOffsetTop + mainOffsetHeight, DYNAM_ID_TWO);
          followersWrapper.prepend(divCopy);
        }
      }
  }
}

export function createDiv(height, id) {
  let div = document.createElement('div');

  div.style.height = height + 'px';
  div.style.minHeight = height + 'px';
  div.id = id;
  return div;
}

export function positionContainer(container) {
  let div;
  let followersAlreadyPositioned;
  let containerOffsetTop;

  if (container.firstChild.id === DYNAM_ID_TREE) {
    // container was already positioned for app, in some scenarious
    // like add first post, we need this (the original container.offsetTop is stored in added div offsetHeight) 
    containerOffsetTop = container.firstChild.offsetHeight;
    followersAlreadyPositioned = true;
  }
  else {
    containerOffsetTop = container.offsetTop;
    container.style.top = 0; // needed to zoom first image to the size of device
    // the following  div is needed to properly show profile
    div = createDiv(containerOffsetTop, DYNAM_ID_TREE);
    container.prepend(div);
    followersAlreadyPositioned = false;
  }
  container.containerOffsetTop = containerOffsetTop;
  container.followersAlreadyPositioned = followersAlreadyPositioned;
}

export function isMobilLayout() {
  let container = document.getElementById('container');
  let posts = document.getElementById('posts');
  let isMobilLayout = false;
  let portretMode = (window.innerWidth < window.innerHeight)

  if (container && posts && ((container.offsetWidth ===  posts.offsetWidth) || portretMode)) {
    isMobilLayout = true;
  }
  return isMobilLayout;
}

export function tabletInDeskTopLayout() {
  let device = getDevice();
  let ret;

  if (((device === Const.TABLET) || (device === Const.ANDROID)) && !isMobilLayout() ) { 
    ret = true;
  }
  else {
    ret = false;
  }
  return ret;
}

export function ipadInDeskTopLayout() {
  let device = getDevice();
  let ret;

  if (((device === Const.IPAD) || (device === Const.IPHONE)) && !isMobilLayout()) {
    ret = true;
  }
  else {
    ret = false;
  }
  return ret;
}

export function pinchHandler(image, that) {
  // internal function
  if (that.pinchToZoom) {
      that.pinchToZoom.unsubscribe();
  }    
  that.pinchToZoom = new PinchToZoom(image);
}

export function appTouchStartListener(e, that) {
  let image;
 
  if (Envir.ANDROID_APP && (e.touches.length < 2)) {
    image = e.target;
    pinchHandler(image, that);
  }
}

export function appTouchMoveListener(e) {
  let image;

  if (Envir.ANDROID_APP) {
    image = e.target;
    if (e.touches.length < 2) {
      // regular movement
      image.style.zIndex = '';
    }
    else {
      // pinch
      image.style.zIndex = BIG_Z_INDEX;
    }
  }
}

export function appTouchEndListener(e) {
  let image;
 
  if (Envir.ANDROID_APP) {
    image = e.target;
    image.style.zIndex = '';
  }
}

export function appImageZoomUnsubscribe(that) {
  if (Envir.ANDROID_APP) {
    appLayoutObject.imgZoomLayoutInitialized = false;
    if (that.pinchToZoom) {
      that.pinchToZoom.unsubscribe();
    } 
  }
}

export function addOrientationChangeListener(that) {
  if (Envir.ANDROID_APP) {
      removeOrientationChangeListener();
      window.addEventListener('resize', orientationChangeListener);
      appLayoutObject.windowInnerWidth = window.innerWidth;
      if (that.className === HOME) {
        appLayoutObject.homeLayout = true;
      }
      else {
        appLayoutObject.homeLayout = false;
      }
  }
  else {
      componentClass = that;
      removeOrientationChangeListener();
      window.addEventListener('resize', orientationChangeListener);
  }
}

export function removeOrientationChangeListener() {
  window.removeEventListener('resize', orientationChangeListener);
}

export function orientationChangeListener() {
  let that;

  if (Envir.ANDROID_APP) {
    if (appLayoutObject.windowInnerWidth !== window.innerWidth) {
      // if we are here it means change orientation event and not keyboard up event
      appLayoutObject.windowInnerWidth = window.innerWidth;
      resetDynamicChanges();
    }
  }
  else {
    that = componentClass;
    if (that.className === HOME) {
      makeFollowersNonScrollable();
    }
  }
}

export function resetDynamicChanges() {
  let container = document.getElementById('container');
  
  removeDynamicDivs();
  container.removeAttribute('style');
  if (appLayoutObject.homeLayout) {
    makeFollowersNonScrollable(); // will be applied for ipad / big iphone on rotation if needed
    createAppHomeLayout();
  }
  else {
    clearProfileLayoutStyleChanges();
    createAppProfileLayout();
  }
}

export function removeDynamicDivs() {
  let dynamicChildOne = document.getElementById(DYNAM_ID_ONE);
  let dynamicChildTwo = document.getElementById(DYNAM_ID_TWO);
  let dynamicChildThree = document.getElementById(DYNAM_ID_TREE);

  removeNodeChild(dynamicChildOne);
  removeNodeChild(dynamicChildTwo);
  removeNodeChild(dynamicChildThree);
}

export function removeNodeChild(nodeChild) {
  if (nodeChild) {
    nodeChild.parentNode.removeChild(nodeChild);
  }
}

export function makeFollowersNonScrollable() {
  let folowerScroll = document.getElementById('folowerScroll');
  let rightDiv = document.getElementById('rightDiv');
     
  if (rightDiv && folowerScroll && ipadInDeskTopLayout()) {
    rightDiv.classList.remove('right-div');
    folowerScroll.classList.remove('scroll');
    folowerScroll.classList.add('right-div-home');
  }
}

export function clearProfileLayoutStyleChanges() {
  let main = document.getElementById('main');
  let postsRow = document.getElementById('postsRow');

  if (main) {
    main.removeAttribute('style');
  }
  if (postsRow) {
    postsRow.removeAttribute('style');
  }
}