import  { isiPhone, isDesktop, isAndroidPhone } from '../utils/AppUtils.js';
import Envir from '../components/Envir';

const IPHONE_BASE_FONT_SIZE = 18;
const DEFAULT_BASE_FONT_SIZE = 16;
const TABLET_BASE_FONT_SIZE = 18;
//
const IPHONE_MIN_FONT_SIZE = 16;
const IPHONE_MAX_FONT_SIZE = 21;
const DEFAULT_MIN_FONT_SIZE = 14;
const DEFAULT_MAX_FONT_SIZE = 20;
//
const ANDROID_APP_MAX_FONT_SIZE = 19;
//
const IPHONE_RATIO = IPHONE_BASE_FONT_SIZE / DEFAULT_BASE_FONT_SIZE;
const IPHONE_RATIO_STRING = IPHONE_RATIO.toString();
const DEFAULT_APP_RATIO = 1; 
const DEFAULT_APP_RATIO_STRING = DEFAULT_APP_RATIO.toString();
const TABLET_RATIO = TABLET_BASE_FONT_SIZE / DEFAULT_BASE_FONT_SIZE;
const TABLETE_RATIO_STRING = TABLET_RATIO.toString();

let root = document.querySelector(':root');

export function setFontSize() {
  let koeffValue;

  if (isiPhone()) {
    koeffValue = IPHONE_RATIO_STRING;
  }
  else if (isDesktop() || isAndroidPhone()) {
    koeffValue = DEFAULT_APP_RATIO_STRING;
  }
  else {
    koeffValue = TABLETE_RATIO_STRING;
  }
  setCssVarValue('--koeff', koeffValue);
}

export function getBaseFontSize() {
  let baseFontSize;

  if (isiPhone()) {
    baseFontSize = IPHONE_BASE_FONT_SIZE;
  }
  else {
    baseFontSize = DEFAULT_BASE_FONT_SIZE;
  }
  return baseFontSize;
}

export function getCssVarValue(cssVarName) {
  let cssVarValue;

  cssVarValue = root.style.getPropertyValue(cssVarName);
  return cssVarValue;
}

export function setCssVarValue(cssVarName, value) {
  root.style.setProperty(cssVarName, value.toString());
}

export function getFontSizeRange() {
  let fontSizeRangObj;

  if (isiPhone()) {
    fontSizeRangObj = {
      minFontSize : IPHONE_MIN_FONT_SIZE,
      maxFontSize : IPHONE_MAX_FONT_SIZE
    }
  }
  else if (Envir.ANDROID_APP) {
    fontSizeRangObj = {
      minFontSize : DEFAULT_MIN_FONT_SIZE,
      maxFontSize : ANDROID_APP_MAX_FONT_SIZE
    }
  }
  else {
    fontSizeRangObj = {
      minFontSize : DEFAULT_MIN_FONT_SIZE,
      maxFontSize : DEFAULT_MAX_FONT_SIZE
    }
  }
  return fontSizeRangObj;
}

export function setConciergeImageProperties() {
  let conciergeImageHeightVal;
  let conciergeImageBackgroundSizeVal;
  let conciergeLignHeight;

  if (Envir.iOS_APP) {
    conciergeImageHeightVal = '94vh'
    conciergeImageBackgroundSizeVal = '100%';
    conciergeLignHeight = '2.5';
  }
  else {
    conciergeImageHeightVal = '100vh'
    conciergeImageBackgroundSizeVal = '118%';
    conciergeLignHeight = '';
  }
  setCssVarValue('--conciergeImageHeight', conciergeImageHeightVal);
  setCssVarValue('--conciergeImageBackgroundSize', conciergeImageBackgroundSizeVal);
  setCssVarValue('--conciergeLignHeight', conciergeLignHeight);
}