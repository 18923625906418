import React, { Component } from 'react';
import { Row } from 'reactstrap';
import * as AiIcons from 'react-icons/ai';
import {  appTouchStartListener,  appTouchEndListener, appImageZoomUnsubscribe } from '../../components/ImageZoomUtils.js';
import { imageGaleryScrollProcessor } from '../../components/ScrollUtils.js';
import { isBrowsingMode } from '../../components/Utils.js';
import Const from '../Const';

class GetImages extends Component {
    constructor(props){
        super(props);
        this.state = {            
            userImage : null,
            isOpen: false
        };
        this.onTouchStartHandler = this.onTouchStartHandler.bind(this);
		this.onTouchendHandler = this.onTouchEndtHandler.bind(this);
		this.pinchToZoom = null;
    }

    setuserImage = (img) => {
        this.setState(state => ({ userClickedPostImage: img }));
    };

    setuserPostLikesCount = (likeCount) => {
        this.setState(state => ({ userClickedPostLikesCount: likeCount }));
    };

    setUser = (name) => {
        this.setState(state => ({ userName: name }));
    };

    setuserPicture = (pic) => {
        this.setState(state => ({ userPicture: pic }));
    };

    //open image dialog
    setOpenOn = () => {
        this.setState(state => ({isOpen: true}));
    };

    //closing dialog on click
    handleShowDialog = () => {
        this.setState({ isOpen: !this.state.isOpen });
        console.log("cliked");
    };

    onTouchStartHandler(e) {
		appTouchStartListener(e, this);
	}

	onTouchEndtHandler(e) {
		appTouchEndListener(e);
	}

    componentWillUnmount() {
		if (this) {
			appImageZoomUnsubscribe(this);
		}
        imageGaleryScrollProcessor(false);
	}

    showLikes(userPost, anyUser) {
        if (isBrowsingMode(anyUser)) {
            return null;
        }
        else{
            return (
                <div className="overlay">
                    <div className="text">
                        <AiIcons.AiOutlineHeart className="" />
                        { userPost.likesCount}
                    </div>
                </div> 
            )
        }
    }

    render() {
        const { anyUser }  = this.props;
        const { userPicture, userName, userPosts } = anyUser;
        const { userClickedPostImage } = this.state;
        const { isOpen } = this.state;

        imageGaleryScrollProcessor(isOpen);
        if (!anyUser) {
            return null;
        }
        return(
            <>              
                <div className="after-app-wrap-images-div" >
                    {isOpen && 
                        <dialog
                            className="dialog pointer-view"
                            open
                            onClick={this.handleShowDialog}
                            onTouchStart={this.onTouchStartHandler} 
                            onTouchEnd={this.onTouchEndtHandler}
                        >
                        <img className="" alt={ Const.POST_IMAGE_ALT } src={ userClickedPostImage }></img>
                        </dialog>
                    }                      
                    
                    <Row className="profile-posts-and-channels" style={{Background: 'tranparent'}}>
                        <div className="library-div-profile" >

                            {  userPosts.map((userPost) =>                            
                                <div  key={ userPost.postId } className="image-text-div" >                                 
                                  {
                                    userPost.hasOwnProperty('postImg') &&
                                    <button onClick={() => {
                                            this.setOpenOn(); 
                                            this.handleShowDialog();
                                            this.setuserImage( userPost.postImg );
                                            this.setuserPostLikesCount( userPost.likesCount );
                                            this.setUser( userName );
                                            this.setuserPicture( userPicture )
                                        }}>
                                        <img className="img-fluid image" alt={Const.GALERY_IMAGE_ALT} src= { userPost.postImg } />
                                        { this.showLikes(userPost, anyUser) }
                                    </button>
                                  }                     
                                </div>  
                            )} 
                        </div>
                    </Row> 
                </div>
            </>
            );
        }
    }
  
  export default GetImages;
