import React from 'react';
import { Redirect } from 'react-router-dom';
import '../css/bootstrap.css';
import * as AiIcons from 'react-icons/ai';
import Const from '../components/Const';
import { invokeService, adjustJsonPicturesAndTime, sleepingCat, getLocalCookieKeyName,
	     getAppCookie, getUserJson } from '../components/Utils.js';
import Envir from '../components/Envir';
import  {getSharedPage, getFullHomePage } from '../utils/RenderPages.js';
import { createAppHomeLayout, appTouchStartListener,  appTouchEndListener, appImageZoomUnsubscribe,
	     addOrientationChangeListener, removeOrientationChangeListener, appTouchMoveListener
       } from '../components/ImageZoomUtils.js';
import { isiOSPlatform, isAndroidPlatform, getiOSAppSchemeURL, getAndroidAppIntentURL, iOSAppPresent, 
	     openiOSAppFromURL, getAppStoreURL, showBodyScrollBar } from '../utils/AppUtils.js';
import { hideSplashScreenForApp, setAppWasAlreadyInvoked } from '../utils/CapacitorUtils';

export default class SharedPost extends React.Component {
  constructor(props) {
		super(props);
		this.state = {    
			loginUser : null,     
			userName : '',  
			fakeTopPosts : [],
			path : props.location.pathname,
			onePost : null,
			sharedPostId : '',
            serviceErrorLevel : Const.NO_ERROR_LEVEL
		};
		this.SHARED_POST_PATH = '/Post/';
		this.iOSAppPresent = false;
		this.iosScheme = "medeata";
		this.onTouchStartHandler = this.onTouchStartHandler.bind(this);
		this.onTouchMoveHandler = this.onTouchMoveHandler.bind(this);
		this.onTouchendHandler = this.onTouchEndtHandler.bind(this);
	}

  async componentDidMount() {
	let loginUser;
	let userName;
    let pathParts = this.state.path.split('/');
    let sharedPostIdString = pathParts[2];
    var sharedPostId = parseInt(sharedPostIdString, 10);
    let ret;
    let result;
    let onePost = null;
	let topPosts = [];
	
	setAppWasAlreadyInvoked(false);
	await hideSplashScreenForApp();
    if (!isNaN(sharedPostId)) {
	  this.checkiOsAppPresence(sharedPostId);
      userName = await this.getUserNameFromCookie();
	  if (userName) {
		this.setState({ userName : userName});
		result = await this.getUserJsonFromDB(userName, sharedPostId);
		if (this.state.serviceErrorLevel === Const.NO_ERROR_LEVEL) {
			loginUser = result;
			loginUser.sharedPost = true;
			onePost = result.onePost;
			topPosts.push(onePost);
			// loginUser.topPosts = topPosts;
			this.setState({ loginUser : loginUser, onePost : onePost, 
				            sharedPostId : sharedPostId, fakeTopPosts : topPosts });
			addOrientationChangeListener(this);
			createAppHomeLayout();
		}
	  }
	  else {
		ret = await this.getSharedPostFromDB(sharedPostId); 
		if (this.state.serviceErrorLevel === Const.NO_ERROR_LEVEL) {
			result = await adjustJsonPicturesAndTime(ret.result);
			onePost = result.onePost;
			this.setState({ onePost : onePost, sharedPostId : sharedPostId });
		}
	  }
    }
  }

  componentWillUnmount() {
	removeOrientationChangeListener();
	if (this) {
		appImageZoomUnsubscribe(this);
	}
  }

  async getUserNameFromCookie() {
	let cookieKey = getLocalCookieKeyName();
	let userName = await getAppCookie(cookieKey);

	return userName;
  }

  async getSharedPostFromDB(sharedPostId) {
	let data = {
        mode : Const.GET_SHARED_POST_MODE,
        post_id : sharedPostId
      };
    let ret = await invokeService(Const.OCA_SERVICES_URL, data, this); 

	return ret;
  }

  async getUserJsonFromDB(userName, sharedPostId) {
	let data = {
		user_name : userName,
		post_id : sharedPostId
	};
	let ret = await getUserJson(this, data);

	return ret;
  }

  getTheRightHeart() {
		return (
			<div className="left">
			  < AiIcons.AiOutlineHeart className = "home-icons-medium" />
			</div>
		)
  }

  getAppButton(sharedPostId) {
	let androidPlatform = isAndroidPlatform();
	let iosPlatform = isiOSPlatform();
	let androidAppIntentUrl;

	if (Envir.ANDROID_IOS_APP) {
		// we are already inside the mobile app
		return null; 
	}
	else if (androidPlatform) {
		// Android web site
		androidAppIntentUrl = getAndroidAppIntentURL(sharedPostId);

		return (
			<a className="btn btn-info buttonsClass" href={ androidAppIntentUrl }>Get app</a>
		);
	}
    else if (iosPlatform) { 
		// iOS web site 
		return (
			<a className="btn btn-info buttonsClass" href={ getAppStoreURL() }>Get app</a>
		);
	} 
	else {
		// Window or Mac computer
		return null;
	}
  }

  onTouchStartHandler(e) {
	appTouchStartListener(e, this);
  }

  onTouchMoveHandler(e) {
	appTouchMoveListener(e);
  }

  onTouchEndtHandler(e) {
	appTouchEndListener(e);
  }

  async checkiOsAppPresence(sharedPostId) {
	let iosSchemeUrl;
	let presenceObj;

	if (!Envir.iOS_APP && isiOSPlatform()) {
		// we are in iPhone or iPad or iPod but not in the iOS app
		iosSchemeUrl = getiOSAppSchemeURL(sharedPostId);
		presenceObj = await iOSAppPresent(iosSchemeUrl);
		this.iOSAppPresent = presenceObj.value;
	}
  }

  handleClick (e, iosSchemeUrl)  {
    e.preventDefault();
	openiOSAppFromURL(iosSchemeUrl);
  }

  render() {
    const { onePost, sharedPostId, userName, loginUser, fakeTopPosts } = this.state;
	const returnTo = this.SHARED_POST_PATH + sharedPostId;

	if (userName) {
		if (!loginUser) {
			return sleepingCat();
		}
		loginUser.whereIam = Const.I_AM_IN_SHARED_POST;
		showBodyScrollBar();
		return getFullHomePage(false, returnTo, fakeTopPosts, loginUser, this);
	}
	else {
		if (loginUser) {
			loginUser.testDrive = true;
			return (
			  <Redirect to={{ pathname: "/Profile", params: { loginUser } }} />
			)
		}
		else {
			if ( onePost) {
				return getSharedPage(sharedPostId, onePost, loginUser, this);
			}
			else {
				return sleepingCat();
			}
		}
	}
  }
}
