//Themes or Tags

const Themes = [

    {
        "tag" : "Top 10",
        "themeId" : "1",
        "posts": [

            //Post 1 with Tag "Top 10"
            {
                "userName" : "mirka_van_deroe",
                "userId" : 1,
                "userPicture": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR-JDp6VgvMkh8rqdpUFcMm2FsE_y8XSGwIVw&usqp=CAU",

                "postId" : 1,
                "post": "Mirka Post 1. Simplicity is the ultimate sophistication said Leonardo Da Vinci once. llllllllllllllllll", 
                "postImg": "https://javacalculationengine.com/imgs4oca/whistler.jpg",
                "comments": [
                    {
                    "userPicture": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS3-vI1foTxugvrFoqEv8KQWAjAAzFvtsmB1Q&usqp=CAU",
                    "userName" : "Fred Voll",
                    "comment" : "Fred Comment."
                    },
                    {
                    "userPicture": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRa5abWqOKhszN_CZzT3SwRd9kHZ9niMIDDxA&usqp=CAU",
                    "userName": "starlywave",
                    "comment" : "starlywave Comment"
                    }
                ],  

                "likes": [
                    {
                    "userPicture": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS3-vI1foTxugvrFoqEv8KQWAjAAzFvtsmB1Q&usqp=CAU",
                    "userName" : "Fred Voll",
                    },
                    {
                    "userPicture": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRa5abWqOKhszN_CZzT3SwRd9kHZ9niMIDDxA&usqp=CAU",
                    "userName": "starlywave",
                    }
                ], 

                "time": "1",
                "likesCount": "10",
                "commentsCount": "122",
                "sharesCount": "140"
            },

            //Post 2 with Tag "Top 10"
            {
                "userName" : "starlywave",
                "userId" : 3,
                "userPicture": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRa5abWqOKhszN_CZzT3SwRd9kHZ9niMIDDxA&usqp=CAU",

                "postId" : 1,
                "post": "Starlywave Post 1. Simplicity is the ultimate sophistication said Leonardo Da Vinci once. llllllllllllllllll", 
                "postImg": "https://javacalculationengine.com/imgs4oca/asp-20190314_110316.jpg",
                "comments": [
                    {
                    "userPicture": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS3-vI1foTxugvrFoqEv8KQWAjAAzFvtsmB1Q&usqp=CAU",
                    "userName" : "Fred Voll",
                    "comment" : "Fred Comment."
                    },
                    {
                    "userPicture": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRa5abWqOKhszN_CZzT3SwRd9kHZ9niMIDDxA&usqp=CAU",
                    "userName": "starlywave",
                    "comment" : "starlywave Comment"
                    }
                ],  

                "likes": [
                    {
                    "userPicture": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS3-vI1foTxugvrFoqEv8KQWAjAAzFvtsmB1Q&usqp=CAU",
                    "userName" : "Fred Voll",
                    },
                    {
                    "userPicture": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRa5abWqOKhszN_CZzT3SwRd9kHZ9niMIDDxA&usqp=CAU",
                    "userName": "starlywave",
                    }
                ], 

                "time": "1",
                "likesCount": "10",
                "commentsCount": "122",
                "sharesCount": "140"
            }
        ],

    },
    
    {   

        "tag" : "#abstract",
        "themeId" : "2",
        "posts": [

            {
                "userName" : "Fred Voll",
                "userId" : 2,
                "userPicture": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS3-vI1foTxugvrFoqEv8KQWAjAAzFvtsmB1Q&usqp=CAU",

                "postId" : 1,
                "post": "Fred Post 1. Simplicity is the ultimate sophistication said Leonardo Da Vinci once. llllllllllllllllll", 
                "postImg": "https://javacalculationengine.com/imgs4oca/whistler.jpg",
                "comments": [
                    {
                    "userPicture": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS3-vI1foTxugvrFoqEv8KQWAjAAzFvtsmB1Q&usqp=CAU",
                    "userName" : "Fred Voll",
                    "comment" : "Fred Comment."
                    },
                    {
                    "userPicture": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRa5abWqOKhszN_CZzT3SwRd9kHZ9niMIDDxA&usqp=CAU",
                    "userName": "starlywave",
                    "comment" : "starlywave Comment"
                    }
                ],  

                "likes": [
                    {
                    "userPicture": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS3-vI1foTxugvrFoqEv8KQWAjAAzFvtsmB1Q&usqp=CAU",
                    "userName" : "Fred Voll",
                    },
                    {
                    "userPicture": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRa5abWqOKhszN_CZzT3SwRd9kHZ9niMIDDxA&usqp=CAU",
                    "userName": "starlywave",
                    }
                ], 

                "time": "1",
                "likesCount": "10",
                "commentsCount": "122",
                "sharesCount": "140"
            }
        ],
    },

    { 
        "tag" : "#chess",
        "themeId" : "3",
        "posts" : [
          
            {
                "userName" : "Fred Voll",
                "userId" : 2,
                "userPicture": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS3-vI1foTxugvrFoqEv8KQWAjAAzFvtsmB1Q&usqp=CAU",

                "postId" : 2,
                "post": "Fred Post 2. There is something about complexity that draws us towards it", 
                "postImg": "https://javacalculationengine.com/imgs4oca/whistler-summer-2019.jpg",
                "comments": [
                  {
                  "userName" : "mirka_van_deroe",
                  "comment" : "In life we never know how things turn out."
                  }
                ],  
  
                "likes": [
                  {
                    "userPicture": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRa5abWqOKhszN_CZzT3SwRd9kHZ9niMIDDxA&usqp=CAU",
                    "userName": "carolinekramerabstract", 
                  }
                ], 
  
                "time": "2",
                "likesCount": "1",
                "commentsCount": "2",
                "sharesCount": "4"
            },

            {
                "userName" : "Fred Voll",
                "userId" : 2,
                "userPicture": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS3-vI1foTxugvrFoqEv8KQWAjAAzFvtsmB1Q&usqp=CAU",

                "postId" : 3,
                "post": "Fred Post 3. There is something about complexity that draws us towards it", 
                "postImg": "https://javacalculationengine.com/imgs4oca/whistler.jpg",
                "comments": [
                    {
                    "userPicture": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS3-vI1foTxugvrFoqEv8KQWAjAAzFvtsmB1Q&usqp=CAU",
                    "userName" : "Fred Voll",
                    "comment" : "Fred Comment."
                    },
                    {
                    "userPicture": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRa5abWqOKhszN_CZzT3SwRd9kHZ9niMIDDxA&usqp=CAU",
                    "userName": "starlywave",
                    "comment" : "starlywave Comment"
                    }
                ],  

                "likes": [
                    {
                    "userPicture": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS3-vI1foTxugvrFoqEv8KQWAjAAzFvtsmB1Q&usqp=CAU",
                    "userName" : "Fred Voll",
                    },
                    {
                    "userPicture": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRa5abWqOKhszN_CZzT3SwRd9kHZ9niMIDDxA&usqp=CAU",
                    "userName": "starlywave",
                    }
                ], 

                "time": "1",
                "likesCount": "10",
                "commentsCount": "122",
                "sharesCount": "140"
            }
        ]
    }
];

export default Themes;