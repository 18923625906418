import { AppLauncher } from '@capacitor/app-launcher';
import Envir from '../components/Envir';
import { getDevice, isPhoneLayout } from '../components/Utils.js';
import { isMobilDeviceDetected } from '../components/MobilButtonUtils.js';
import Const from '../components/Const';

const iosScheme = "medeata";
const SHARED_POST_PATH = '/Post/';
const GOOGLE_PLAY_STORE_URL = 'https://play.google.com/store/apps/details?id=com.medeata.app';
const APP_STORE_URL = 'https://apps.apple.com/us/app/medeata/id6443628807';

export function getAndroidAppIntentURL(sharedPostId) {
  let intent =  "intent://" + Envir.APP_DOMAIN + SHARED_POST_PATH + sharedPostId + '#Intent;';
  let sheme = "scheme=https;";
  let appPackage = "package=com.medeata.app;";
  let fallBackUr = "S.browser_fallback_url=" + GOOGLE_PLAY_STORE_URL + ';';
  let intentUrl = intent + sheme + appPackage + fallBackUr + 'end';

  return intentUrl;
}

export function getiOSAppSchemeURL(sharedPostId) {
  let iosSchemeUrl = iosScheme + '://' + Envir.APP_DOMAIN + SHARED_POST_PATH + sharedPostId;
  
  return iosSchemeUrl;
}

export function getAppStoreURL() {
  return APP_STORE_URL;
}

export function getGooglePlayStoreURL() {
  return GOOGLE_PLAY_STORE_URL;
}

export function isiOSPlatform() {
  let device = getDevice();
  let iosPlatform = ((device === Const.IPAD) || (device === Const.IPHONE)) ? true : false;

  return iosPlatform;
}

export function isiPhone() {
  let device = getDevice();
  let isIphone = (device === Const.IPHONE) ? true : false;

  return isIphone;
}

export function isiPad() {
	let device = getDevice();
	let isIpad = (device === Const.IPAD) ? true : false;
  
	return isIpad;
}

export function isAndroidPlatform() {
  let device = getDevice();
  let androidPlatform = ((device === Const.TABLET) || (device === Const.ANDROID)) ? true : false;

  return androidPlatform;
}

export function isAndroidPhone() {
	let androidPhone = isAndroidPlatform() && isPhoneLayout();

	return androidPhone;
}

export function isAndroidTablet() {
	let androidTablet = isAndroidPlatform() && !isPhoneLayout();

	return androidTablet;
}

export function isMobilPlatform() {
	let mobilPlatform = (isAndroidPlatform() || isiOSPlatform());
  
	return mobilPlatform;
  }

export function isDesktop() {
	let device = getDevice();
	let deskTopPlatform = ((device === Const.WIN_DESKTOP) || (device === Const.MAC_DESKTOP)) ? true : false;
  
	return deskTopPlatform;
}

export function isTablet() {
	let device = getDevice();
	let tablet = (device === Const.IPAD) || isAndroidTablet();
  
	return tablet;
}

export function isTabletLandscapeLayout() {
	let tabletInLandscapeLayout = isTablet() && (window.innerWidth > window.innerHeight);
  
	return tabletInLandscapeLayout;
}

export async function iOSAppPresent(iosSchemeUrl) {
  let cond = await AppLauncher.canOpenUrl(iosSchemeUrl);
  
  return cond;
}

export async function openiOSAppFromURL(iosSchemeUrl) {
  await AppLauncher.openUrl(iosSchemeUrl);
}

export function getAppButtonForTestDrive(that) {
	let androidPlatform = isAndroidPlatform();
	let iosPlatform = isiOSPlatform();

	if (Envir.ANDROID_IOS_APP) {
		// we are already inside the mobile app
		return null; 
	}
	else if (androidPlatform) {
		// Android web site
		return (
			<a  href={ getGooglePlayStoreURL()} onClick={ that.handleGetAppClick }>Get App</a>
		);
	}
    else if (iosPlatform) { 
		// iOS web site 
		return (
			<a  href={ getAppStoreURL() } onClick={ that.handleGetAppClick }>Get App</a>
		);
	} 
	else {
		// Window or Mac computer
		return null;
	}
}

export function isSearchButtonDisplayLeft() {
	let ret = true;

	if (isMobilDeviceDetected()) {
		ret = false;
	}
	return ret;
}

export function showBodyScrollBar() {
	if (isDesktop()) {
		document.body.style.overflowY = 'scroll';
	}
}

export function noBodyScrollBar() {
	if (isDesktop()) {
		document.body.style.overflowY = 'hidden';
	}
}