import React from "react";
import { Redirect } from 'react-router-dom';
import Const from './Const';
import { sleepingCat, messageModalWindow } from './Utils.js';
import { hideSplashScreenForApp, setAppWasAlreadyInvoked } from '../utils/CapacitorUtils';
import  { enterBrowseMode } from '../utils/RenderPages.js';

export default class UserAccess extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loginUser : null,
      testDrive: false,
      header: '',
			text: '',  
      messageModal: false,   
      serviceInvocationStatus : Const.SERVICE_IS_NOT_INVOKED_YET,
      serviceStatusCode : Const.INIT_VALUE,
      serviceErrorLevel : Const.NO_ERROR_LEVEL,
      serviceInvocationError : false,
      flag : Const.INIT_VALUE
    };
    this.propsUserName = props.match.params.name;
  }

  async componentDidMount() {
    setAppWasAlreadyInvoked(false);
    await hideSplashScreenForApp();
    if (this.propsUserName) {
			enterBrowseMode(this, this.propsUserName);
		}
  }

  render() {
    const { serviceInvocationError, serviceErrorLevel, loginUser, testDrive } = this.state;
 
    if (!this.propsUserName) {
      // looks impossible
      return (
        <Redirect to={{ pathname: "/"}} />
     )
    }
    else if (serviceErrorLevel === Const.USER_ERROR_CRITICAL_LEVEL) {
      return (
        messageModalWindow(this, this.state.header, this.state.text)
      )
    }
    else if (serviceInvocationError || (serviceErrorLevel === Const.SYS_ERROR_LEVEL)) {
      return (
        messageModalWindow(this, this.state.header, this.state.text)
      )
    } 
    else if (!loginUser) {
			return sleepingCat();
		}
    else if (loginUser.userId < 0) {
      return (
        <Redirect to={{ pathname: "/"}} />
      )
    }
    else if (loginUser) {
      if (testDrive) {
				loginUser.testDrive = true;
        loginUser.firstTimeIn = true;
        return (
          <Redirect to={{ pathname: "/Profile", params: { loginUser } }} />
        )
      }
      else {
        // looks impossible
        return (
          <Redirect to={{ pathname: "/"}} />
        )
      }
		} 
  }
}