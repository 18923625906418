import React from 'react';
import { Row, Col, Form, FormGroup, Input, Button } from 'reactstrap';
import { MDBContainer, MDBModal, MDBBtn, MDBModalBody, MDBFooter } from 'mdbreact';
import Navi from '../components/nav';
import * as MdIcons from 'react-icons/md';
import * as RiIcons from 'react-icons/ri';
import * as BsIcons from "react-icons/bs";
import Const from '../components/Const';
import { invokeService, validateEmail, validatePasswordStrength, getUserJsonFromDBwithCookie, messageModalWindow, 
         sleepingCat, contactUs } from '../components/Utils.js';
import { modalPopUpScrollProcessor } from '../components/ScrollUtils.js';
import { isiPhone } from '../utils/AppUtils.js';

export default class Account extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      modal: false,
      messageModal: false,
      currentPassword : "",
      newPassword : "",
      currentPasswordError : "",
      newPasswordError : "",
      emailError : "",
      phoneError: "",
      hide : true, 
      ocaLoginUser : props.location.params ? this.props.location.params.ocaLoginUser : null,
      loginUser : null,
      showNav : true,
      disableButton : true,
      //
      serviceInvocationStatus : Const.SERVICE_IS_NOT_INVOKED_YET,
      serviceStatusCode : Const.INIT_VALUE,
      serviceErrorLevel : Const.NO_ERROR_LEVEL,
      serviceInvocationError : false,
      flag : Const.INIT_VALUE
    }
    this.allowToggle = true;
    this.toggle = this.toggle.bind(this);
    this.onFocusHandler = this.onFocusHandler.bind(this);
    this.onBlurHandler = this.onBlurHandler.bind(this);
    this.handleFullNameChange = this.handleFullNameChange.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handleCompanyChange = this.handleCompanyChange.bind(this);
  }

  addLoginUserToOcaLoginUser(ocaLoginUser, loginUser) {
    ocaLoginUser.accountInfo = loginUser;
  }
 
  hideSwitch = () => {
    //password show hide
    this.setState({ hide: !this.state.hide })
  }
  
  toggle = () => {
    if (this.allowToggle) {
        modalPopUpScrollProcessor(this);
        this.setState({
        modal: !this.state.modal
      });
      this.resetPasswords();
      this.resetPasswordErrors();
      if (!this.state.hide) {
        this.setState({ hide: true });
      }
    }
  }

  validate = () => {
    let emailError = "";
    let ret = true;
    let isEmailValid = validateEmail(this.state.loginUser.userEmail);

    if (!isEmailValid) {
      emailError = Const.VALID_EMAIL_REQUIRED;
      ret = false;
    }
    this.setState({ emailError });
    return ret;
  };

  validatePasswords = () => {
    let currentPasswordError = "";
    let newPasswordError = "";
    let currentPasswordStrength;
    let newPasswordStrength;
    let ret = true;

    if (this.state.currentPassword) {
      currentPasswordStrength = validatePasswordStrength(this.state.currentPassword);
    }
    else {
      currentPasswordStrength = Const.WEAK;
    }
    if (currentPasswordStrength < Const.GOOD) {
      currentPasswordError = Const.VALID_PASSWORD_REQUIRED;
      ret = false;
    }
    //
    if (this.state.newPassword) {
      newPasswordStrength = validatePasswordStrength(this.state.newPassword);
    }
    else {
      newPasswordStrength = Const.WEAK;
    }
    if (newPasswordStrength < Const.GOOD) {
      newPasswordError = Const.VALID_PASSWORD_REQUIRED;
      ret = false;
    }
    this.setState({ currentPasswordError, newPasswordError });
    return ret;
  };

  resetPasswords() {
    this.setState({
      currentPassword : "", 
      newPassword : ""
    });
  }

  resetPasswordErrors() {
    this.setState({
      currentPasswordError : "",
      newPasswordError : ""
    });
  }

  handleSubmit = async event => {
    let isValid;

    event.preventDefault();
    isValid = this.validate();
    if (isValid) {
      this.disableEnableSettings(true);
      await this.updateUserSettingsInDB(this.state.loginUser);
      this.disableEnableSettings(false);
      if (this.state.serviceErrorLevel === Const.NO_ERROR_LEVEL) {
        this.addLoginUserToOcaLoginUser(this.state.ocaLoginUser, this.state.loginUser);
      }
      this.setState({ disableButton: true });
    }
  };

  handleModalSubmit = async event => {
    let isValid;
    
    event.preventDefault();
    isValid = this.validatePasswords();
    if (isValid) {
      this.disableEnableSecuritySettings(true);
      await this.setNewPasswordInDB();
      this.disableEnableSecuritySettings(false);
      if (this.state.serviceErrorLevel === Const.NO_ERROR_LEVEL) {
        this.toggle();
      }
      else if (this.state.flag === Const.TOO_MANY_TRIES) {
        this.setState({ 
          currentPasswordError : Const.TOO_MANY_TRIES_NEED_CLOSE_BROWSER_MESSAGE
        });
      } 
      else if (this.state.serviceErrorLevel === Const.USER_ERROR_LEVEL) {
        this.setState({ 
          currentPasswordError : Const.INVALID_PASSWORD
        });
      }
    }
  };

  async componentDidMount() {
    let ocaLoginUser = this.getOcaUserFromPropsParamsOrState();
    let loginUser;
    let result;
 
    if (!ocaLoginUser) {
			result = await  getUserJsonFromDBwithCookie(this);
			if ((this.state.serviceErrorLevel === Const.NO_ERROR_LEVEL)) {
				ocaLoginUser = result;
			}
		}
    if (ocaLoginUser) {
      this.setState({ ocaLoginUser : ocaLoginUser});
      if (!ocaLoginUser.hasOwnProperty('accountInfo')) {
        result = await this.getUserSettingsFromDB();
        if (this.state.serviceErrorLevel === Const.NO_ERROR_LEVEL) {
          loginUser = result;
          this.addLoginUserToOcaLoginUser(ocaLoginUser, loginUser);
          this.setState({ loginUser : loginUser });
        }
      }
      else {
        loginUser = ocaLoginUser.accountInfo;
        this.setState({ loginUser : loginUser });
      }
    }
  }

  async getUserSettingsFromDB() {
    let  data = {
          // Get user info service
          "mode" : Const.GET_USER_INFO_MODE
         };
   let response = await invokeService(Const.ACCOUNT_SERVICES_URL, data, this);

   return response.result;
  }

  async updateUserSettingsInDB(loginUser) {
    let  data = {
      // Store settings service
      "email": loginUser.userEmail,
      "phone": loginUser.userPhone,
      "company": loginUser.userCompany,
      "full_name" : loginUser.userFullName.trimEnd(),
      "country" : loginUser.userCountry,
      "address" : loginUser.userAdress,
      "mode" : Const.STORE_SETTINGS_MODE
    };
    
    await invokeService(Const.ACCOUNT_SERVICES_URL, data, this);
  }

  async setNewPasswordInDB() {
    let  data = {
      // New password service
      "old_password": this.state.currentPassword,
      "new_password": this.state.newPassword,
      "mode" : Const.NEW_PASSWORD_MODE
    };
    await invokeService(Const.LOGIN_URL, data, this);
  }

  getOcaUserFromPropsParamsOrState() {
    let ocaLoginUser;
  
    if (this.props.location.params) {
      ocaLoginUser = this.props.location.params.ocaLoginUser;
    }
    else if (this.state.ocaLoginUser) {
      ocaLoginUser = this.state.ocaLoginUser;
    }
    else {
      ocaLoginUser = null;
    }
    return ocaLoginUser;
  }

  disableEnableSettings(disable) { 
		let submitButton = document.getElementById('savechanges');
		let securityButton = document.getElementById('security');
		let fullName = document.getElementById('name');
		let email = document.getElementById('email');
		let company = document.getElementById('company');
		let main = document.getElementById('main');

		if (disable) {
			submitButton.setAttribute('disabled', 'disabled');
			securityButton.setAttribute('disabled', 'disabled');
			fullName.setAttribute('disabled', 'true');
			email.setAttribute('disabled', 'true');
      if (company) {
        company.setAttribute('disabled', 'true');
      }
			main.style.opacity = Const.REDUCED_OPACITY;
		}
		else {
      submitButton.removeAttribute("disabled");
      securityButton.removeAttribute("disabled");
      fullName.removeAttribute("disabled");
      email.removeAttribute("disabled");
      if (company) {
        company.removeAttribute("disabled");
      }
			main.style.opacity = Const.FULL_OPACITY;
		}
	}

  disableEnableSecuritySettings(disable) { 
		let securitysaveButton = document.getElementById('securitysave');
    let header = document.getElementById('header');
		let xCloser = document.getElementById('X');
		let oldPassword = document.getElementById('oldpassword');
		let newPassword = document.getElementById('newpassword');

		if (disable) {
			securitysaveButton.setAttribute('disabled', 'disabled');
      header.style.opacity = Const.REDUCED_OPACITY;
			xCloser.style.opacity = Const.REDUCED_OPACITY;
			oldPassword.setAttribute('disabled', 'true');
			newPassword.setAttribute('disabled', 'true');
      this.allowToggle = false;
		}
		else {
      securitysaveButton.removeAttribute("disabled");
      header.style.opacity = Const.FULL_OPACITY;
			xCloser.style.opacity = Const.FULL_OPACITY;
			oldPassword.removeAttribute("disabled");
			newPassword.removeAttribute("disabled");
      this.allowToggle = true;
		}
	}

  onFocusHandler(e) {
    if (isiPhone()) {
      // iPhone doesn't honor fixed navigation bar, so we remove it when typing
      e.preventDefault();
      this.setState({ showNav : false});
    }
  }

  onBlurHandler(e) {
    if (isiPhone()) {
      e.preventDefault();
      this.setState({ showNav : true});
    }
  }

  handleFullNameChange(e) {
    let loginUser = this.state.ocaLoginUser.accountInfo;
		let disable = (e.target.value.trimStart() === loginUser.userFullName) ? true : false;

		this.setState(prevState => ({
		  loginUser: {
			   ...prevState.loginUser,
         userFullName: e.target.value.trimStart()
		  },
		  disableButton : disable
		}))
	}

  handleEmailChange(e) {
    let loginUser = this.state.ocaLoginUser.accountInfo;
		let disable = (e.target.value.trimStart() === loginUser.userEmail) ? true : false;

		this.setState(prevState => ({
		  loginUser: {
			   ...prevState.loginUser,
         userEmail: e.target.value.trimStart()
		  },
		  disableButton : disable
		}))
	}

  handleCompanyChange(e) {
    let loginUser = this.state.ocaLoginUser.accountInfo;
		let disable = (e.target.value.trimStart() === loginUser.userCompany) ? true : false;

		this.setState(prevState => ({
		  loginUser: {
			   ...prevState.loginUser,
         userCompany: e.target.value.trimStart()
		  },
		  disableButton : disable
		}))
	}

  render() {
    const { serviceErrorLevel, serviceStatusCode, hide, ocaLoginUser, loginUser, header, text, modal, 
            serviceInvocationError, showNav, disableButton } = this.state;
    const { title } = this.props; 
    let className = "after-app-wrap-center-div-gotop";
    
    if (isiPhone()) {
      className += " posFixed";
    }
    if (!ocaLoginUser) {
			if (serviceErrorLevel !== Const.NO_ERROR_LEVEL) {
				return messageModalWindow(this, header, text);
			}
			else {
				return null;
			}
		}
    else {
      ocaLoginUser.whereIam = Const.I_AM_IN_ACCOUNT;
    }
    // at this point oca user is retrieved and we are processing account login user processing
    if (serviceErrorLevel === Const.USER_ERROR_CRITICAL_LEVEL) {
			return (
			  messageModalWindow(this, this.state.header, this.state.text)
			)
		}
    else if (serviceInvocationError || (serviceErrorLevel === Const.SYS_ERROR_LEVEL)) {
			return (
				messageModalWindow(this, this.state.header, this.state.text)
			)
		} 
    else if (serviceStatusCode === Const.NOT_CRITICALLY_BAD_COOKIE_WAS_SENT) {
      return <div>{Const.BAD_COOKIE_MESSAGE_CLOSE_BROWSER}</div>;
    } 
    else if (!loginUser) {
			if (serviceErrorLevel !== Const.NO_ERROR_LEVEL) {
				return messageModalWindow(this, header, text);
			}
			else {
        return (
          <> 
            <Navi loginUser = { ocaLoginUser }/>
            {sleepingCat()}
          </>
         );
			}
		}
    else {
      return (
        <>
          {
            showNav &&
            <Navi loginUser = { ocaLoginUser } />
          }

          {
            loginUser &&
            <div id="main" className={ className }>
              <Form>
                <FormGroup className="account-form">
                  <Row className="account">

                    <Col className="profile-info profile-info-account" md={10} sm={10} xs={12}>                 
                      <h3>Settings</h3>
                      <div className="mt-3 mb-3">
                        <p className="mb-0 font-small">User name:</p>
                        <h5 className="pt-0"> { ocaLoginUser.userName }</h5>
                      </div>
                    
                      <div className="login-form-row">
                        <label>Full name</label>
                        <Input id="name" placeholder="Full name (required)" className="x-input"
                                autoFocus={false}
                                value = {loginUser.userFullName}
                                type="text" required    
                                onFocus={this.onFocusHandler} 
                                onBlur={this.onBlurHandler} 
                                onChange={ this.handleFullNameChange }
                        />
                      </div>

                      <div className="login-form-row">
                        <label>Email</label>
                        <Input id="email" placeholder="Email" className="x-input"
                                value = {loginUser.userEmail}
                                type="text" required    
                                onFocus={this.onFocusHandler} 
                                onBlur={this.onBlurHandler}    
                                onChange={ this.handleEmailChange }
                        />
                        <div className = "error-message">
                          <span>{this.state.emailError}</span>
                        </div>
                      </div>

                      {
                        loginUser.userCompany &&
                        <div className="login-form-row">
                        <label>Company</label>
                        <Input id="company" placeholder="Company name" className="x-input"
                                value = {loginUser.userCompany}
                                type="text" required   
                                onFocus={this.onFocusHandler} 
                                onBlur={this.onBlurHandler}   
                                onChange={ this.handleCompanyChange }
                        />
                      </div>
                      }
                                
                    </Col>

                    <Col md={2} sm={2} xs={12} className="btn-security">
                      <MDBBtn id="security" className="btn btn-outline-primary" onClick={this.toggle}>Security</MDBBtn>         
                    </Col>
                  </Row>

                  <Row>
                    {
                       disableButton 
                       ? <Button style={{marginLeft: '0'}} disabled = { true } className="pointer-view" id="savechanges" color ="info"> Save Changes </Button> 
                       : <Button style={{marginLeft: '0'}} onClick={ this.handleSubmit } className="pointer-view" id="savechanges" color ="info"> Save Changes </Button> 
                    }
                  </Row>
                </FormGroup>
              </Form>
            </div>
          }
           {
              modal &&
              <MDBContainer>
              <MDBModal isOpen={this.state.modal} toggle={this.toggle} autoFocus={false} overflowScroll={false}>
                <Row className="row-margin-0">
                  <Col xs={2} className="pl-0">
                    <MdIcons.MdClose id="X" className="modal-close left pointer-view" onClick={this.toggle} />
                  </Col>
                  <Col xs={10} className="p-0">
                    {
                      (this.state.currentPassword.length === 0)
                      ? <MDBBtn id="securitysave" className="btn btn-outline-primary right pointer-noview"  disabled = { true } >Save changes</MDBBtn>
                      : <MDBBtn id="securitysave" className="btn btn-outline-primary right" onClick={this.handleModalSubmit}>Save changes</MDBBtn>
                    }
                  </Col>
                </Row>

                <MDBModalBody className="p-0">
                  <Row>
                    <div className="edit-password">
                      <Form>
                        <FormGroup>
                          <Row id="header" className="">
                            <h5 className="left">Change password</h5>
                          </Row>
                          <Row className="login-form-row-no-label">
                            <label className="password">{title}
                              <Input id="oldpassword" className="x-input form-control"
                                  autoFocus={false}
                                  type={hide ? 'password' : 'text'} required 
                                  placeholder="Current password"
                                  maxLength = {20}
                                  value = {this.state.currentPassword}
                                  onChange={e => this.setState({currentPassword : e.target.value.trim(), currentPasswordError : "" })} 
                              />
                              <div className="eye" onClick={this.hideSwitch}>
                                {hide ? <RiIcons.RiEyeCloseLine /> : <BsIcons.BsEye />}
                              </div>
                              <div className = "error-message">
                                <span>{this.state.currentPasswordError}</span>
                              </div>
                            </label>
                          </Row>

                          <Row className="login-form-row-no-label">
                            <label className="password">{title}
                              <Input id="newpassword" className="x-input form-control"
                                  type={hide ? 'password' : 'text'} required  
                                  placeholder="New password"
                                  maxLength = {20}
                                  value = {this.state.newPassword}
                                  onChange={e => this.setState({newPassword : e.target.value.trim(), newPasswordError : ""})} 
                              />
                              <div className="eye" onClick={this.hideSwitch}>
                                {hide ? <RiIcons.RiEyeCloseLine /> : <BsIcons.BsEye />}
                              </div>
                              <div className = "error-message">
                                <span>{this.state.newPasswordError}</span>
                              </div>
                            </label>
                          </Row>
                        </FormGroup>
                      </Form>
                    </div>
                  </Row>      
                </MDBModalBody> 
              </MDBModal>
              </MDBContainer>
           }        
          <MDBFooter>
            {contactUs()}
          </MDBFooter>
        </>
      );
    }
  }
}