import React from 'react';
import {Row, Card, Input, Button } from 'reactstrap';
import { Link, Redirect } from 'react-router-dom';
import '../App.css';
import * as RiIcons from 'react-icons/ri';
import * as IoIcons from 'react-icons/io';
import * as MdIcons from 'react-icons/md';
import Navi from './nav';
import Const from './Const';
import { invokeService, addUserPicture, getUserPictureClassName, sleepingCatSmall, loadPartialJsonPosts } from './Utils.js';
import { showBodyScrollBar, isDesktop } from '../utils/AppUtils.js';
import { storeState, getState } from '../utils/StateStoreUtils.js';
import { addScrollListener, removeScrollListener, SEARCH, scrollToStroredPosition,
	     loadingImage, setPostsLoadinOrPostsEndOption, unsetPostsLoadinOrPostsEndOption } from './ScrollUtils.js';
import  { getHashTagedPostsPage } from '../utils/RenderPages.js';
import CustomDropdown from './CustomDropdown';
import { iOSstatusBarPadding } from '../utils/CapacitorUtils.js';
import { appTouchStartListener,  appTouchEndListener, appTouchMoveListener, appImageZoomUnsubscribe } from '../components/ImageZoomUtils.js';
import  { postsAndFollowersClassNames } from '../utils//PostUtils.js';

// Logic :

// input box empty - show all users
// at this point foundHashTags are the same as defaultHashTags : we don't allow DB search for hashTags except default
// so subMode = 'hashtags' is present and service recognizes it, but we don't use it at the moment

// hashTagSearch showHashTagsDropDown hashTagPosts
//   true            true               null             this.renderHashTags(foundHashTags)
//   true            false              array            this.getHashTagedPostsPage(returnTo, hashTagPosts, loginUser, this)
//   false           false              null             this.getUsers(loginUser, characterInput, foundUsers, click)

export default class UniversalSearch extends React.Component {
	constructor(props){
		super(props);
		this.className = SEARCH;
		this.DEFAULT_SEARCH_TEXT = 'username or #';
		this.SEARCH_TERM = 'searchTerm';
		const loginUser = props.location.params ? props.location.params.loginUser : null;
		const anyUser = props.location.params ? props.location.params.anyUser : null;
		const returnTo = props.location.params ? props.location.params.returnTo : null;
		this.findUsersInDB = this.findUsersInDB.bind(this);
		this.onClickHandler = this.onClickHandler.bind(this);
		this.onTouchHandler = this.onTouchHandler.bind(this);
		this.handleDropdownSelection = this.handleDropdownSelection.bind(this);
		this.noUser = {userId: 0, userName: 'User not found', userTagline: ''};
		this.RECORD_NOT_FOUND_MSG = "No matching records...";
		const storedState = getState(SEARCH);
		//
		this.state = {    
			loginUser : loginUser,   
			anyUser : anyUser ? anyUser : (storedState ? storedState.anyUser : null),   
			returnTo : returnTo ? returnTo : (storedState ? storedState.returnTo : null),   
			defaultUsers : storedState ? storedState.defaultUsers : null,
			foundUsers : storedState ? storedState.foundUsers : null, 
			foundHashTags : storedState ? storedState.foundHashTags : null,  
			defaultHashTags : storedState ? storedState.defaultHashTags : null,
			showHashTagsDropDown : storedState ? storedState.showHashTagsDropDown : false,  
			searchTerm: storedState ? storedState.searchTerm : '',
			characterInput : storedState ? storedState.characterInput : false,
			buttonDisabler : storedState ? storedState.buttonDisabler : false,
			click : storedState ? storedState.click : false,
			hashTagSearch : storedState ? storedState.hashTagSearch : false,
			hashTagPosts :  storedState ? storedState.hashTagPosts : null,
			magnifier :  storedState ? storedState.magnifier : false,
			isKeyboardUp : false,
			showX : storedState ? storedState.showX : false,
			loadMore : storedState ? storedState.loadMore : null,
			//
			serviceErrorLevel : Const.NO_ERROR_LEVEL,
			serviceInvocationStatus : Const.SERVICE_IS_NOT_INVOKED_YET,
		};
		this.removeInputValue = this.removeInputValue.bind(this);
		this.removeRestoreBackGroundImage(true);
		this.searchTerm = this.state.searchTerm;
		this.hashTagPosts = this.state.hashTagPosts;
		this.loadMore = this.state.loadMore;
		this.loadingImgRef = React.createRef();
		this.onTouchStartHandler = this.onTouchStartHandler.bind(this);
		this.onTouchMoveHandler = this.onTouchMoveHandler.bind(this);
		this.onTouchendHandler = this.onTouchEndtHandler.bind(this);
		this.pinchToZoom = null;		
	}

    async componentDidMount() {
		let loginUser = this.state.loginUser;
		let searchTerm = this.state.searchTerm;
		let foundUsers = this.state.foundUsers;
		let hashTagPosts = this.state.hashTagPosts;

		if (searchTerm) {
			this.setState({ showX : true });
		}
		if (loginUser) {
			if (!foundUsers) {
				await this.findUsersInDB(searchTerm);
			}
			else {
				if (hashTagPosts) {
					hashTagPosts = this.replaceHashTagPostsWithTopAndUserPosts(hashTagPosts); // refresh because of likes and comments
					this.setState({ hashTagPosts : hashTagPosts });
				}
				scrollToStroredPosition(this);
			}
			addScrollListener(this);
		}
		document.addEventListener('touchmove', this.onTouchHandler);
    }

    componentWillUnmount() {
		removeScrollListener();
		this.removeRestoreBackGroundImage(false);
		document.removeEventListener('touchmove', this.onTouchHandler);
		appImageZoomUnsubscribe(this);
    }

	handleKeyPress = (event) => {
		let searchTerm;

		if ((event.key === 'Enter') && !this.state.buttonDisabler) {
			searchTerm = this.state.searchTerm;
			this.onClickHandlerLogic(searchTerm);
		}
	};

    onChangeHandler = async (e) => {
		let searchTerm = e.target.value.trim();

		if (searchTerm.startsWith('#')) {
			this.editHashTagSearchTerm(searchTerm);
		}
		else {
			this.editUsersSearchTerm(searchTerm);
		}
    }

	onFocusHandler = (e) => {
		e.preventDefault();
		this.setState({ isKeyboardUp : true });
    }

	onBlurHandler = (e) => {
		this.setState({ isKeyboardUp : false });
    }

    onTouchHandler() {
		let inp = document.getElementById("inp");

		if (this.state.isKeyboardUp) {
			setTimeout(() => {
				this.blurProcessing(inp); 
			}, 10);
		}
    }

	blurProcessing(inp) {
		// Possible way of doing it:
		// let event = new Event('blur');
		// inp.dispatchEvent(event);

		inp.blur();
	}

    editHashTagSearchTerm = async (searchTerm) => {
		let foundHashTags = this.state.foundHashTags;
		let buttonDisabler = true;
		let showHashTagsDropDown = true;
		let hashTagMatch;
		let defaultHashTags = this.state.defaultHashTags;

        if (!foundHashTags) {
			foundHashTags = defaultHashTags;
		}
		if (searchTerm === '#') {
			if (defaultHashTags) {
				foundHashTags = defaultHashTags;
				buttonDisabler = true;
			}
		}
		else {
			if (foundHashTags) {
				// looking for a possible exact match to disable search button
				hashTagMatch = this.applyExactHashTagEqualityFilter(searchTerm);
				if (hashTagMatch.length > 0) {
					showHashTagsDropDown = true;
					buttonDisabler = false;
				}
			}
		}
		this.setState({ 
			searchTerm : searchTerm, 
			hashTagSearch : true,
			hashTagPosts : null,
			buttonDisabler : buttonDisabler, 
			click : false,
			showHashTagsDropDown : showHashTagsDropDown,
			foundHashTags : foundHashTags,
			showX : true
		});
    }
  
    editUsersSearchTerm = async (searchTerm) => {
		let buttonDisabler = false;
		let characterInput = true;
		let foundUsers = this.state.foundUsers;
		let showX = searchTerm ? true : false;
		
		if ((searchTerm === '*' || !searchTerm)) {
			if (this.state.defaultUsers) {
				foundUsers = this.state.defaultUsers;
				buttonDisabler = true;
			}
		}
		this.setState({ 
			           searchTerm : searchTerm, 
					   characterInput : characterInput, 
					   buttonDisabler : buttonDisabler, 
					   foundUsers : foundUsers,
					   click : false,
					   hashTagSearch : false,
			           showHashTagsDropDown : false,
					   hashTagPosts : null,
					   showX : showX
					 });
	}

	applyUsersFilter = (foundUsers) => {
		let searchTerm = this.state.searchTerm;
		let filteredUsers;

		if ((searchTerm === '*') || (searchTerm === '')) {
			filteredUsers = foundUsers;
		}
		else {
			filteredUsers = foundUsers.filter(personIFollow => personIFollow.userName.toLowerCase().startsWith(searchTerm.toLowerCase()));
		}
		return filteredUsers;
	}

	applyExactUserEqualityFilter = (searchTerm) => {
		return this.state.foundUsers.filter(personIFollow => 
			                                (personIFollow.userName.toLowerCase() === (searchTerm.toLowerCase())));
	}

	applyHashTagsFilter = (foundHashTags) => {
		let searchTerm = this.state.searchTerm;
		let filteredHashTags;

		if (searchTerm === '#') {
			filteredHashTags = foundHashTags;
		}
		else {
			filteredHashTags = foundHashTags.filter(foundHashTag => foundHashTag.toLowerCase().startsWith(searchTerm.toLowerCase()));
		}
		return filteredHashTags;
	}

	applyExactHashTagEqualityFilter = (searchTerm) => {
		return this.state.foundHashTags.filter(foundHashTag => 
			                                   (foundHashTag.toLowerCase() === (searchTerm.toLowerCase())));
	}

	async onClickHandler(e) {
		let searchTerm = this.state.searchTerm;

		e.preventDefault();
		this.onClickHandlerLogic(searchTerm);
	}

	async onClickHandlerLogic(searchTerm) {
		if (searchTerm.startsWith('#')) {
			this.setState({ buttonDisabler : true, magnifier : true, hashTagPosts : null }); // hashTagPosts : null and not [] here so render will not 
			                                                                                 // issue 'recod not found' message inapropriately
			await this.getHashTagPosts(searchTerm);
		}
		else {
			await this.findUsersInDB(searchTerm);
		}
		this.setState({ click : true });
	}

	async findUsersInDB(searchTerm) {
		let subMode = this.getSubMode(searchTerm);
		let  data = {
			mode : Const.FIND_USERS,
			search : (searchTerm === '*') ? '' : searchTerm,
			sub_mode : subMode
		};
		let ret;
		let usersFromDB = null;
		let hashTagsFromDB = null;
		let loginUser;

		this.setState({  buttonDisabler : true, magnifier : true, foundUsers : [] });
		if (((searchTerm === '*') || (searchTerm === '')) && this.state.defaultUsers) {
			this.setState({ 
				           foundUsers :  this.state.defaultUsers, 
						   characterInput : false, buttonDisabler : true,
						   magnifier : true 
						 });
		}
		else {
			ret = await invokeService(Const.OCA_SERVICES_URL, data, this);
			if (this.state.serviceErrorLevel === Const.NO_ERROR_LEVEL) {
				if (ret.result.hasOwnProperty('users')) {
					loginUser = this.state.loginUser;
					if (loginUser) {
						ret.result.users = ret.result.users.filter(user => user.userName !== loginUser.userName);
					}
					addUserPicture(ret.result, 'users');
					usersFromDB = ret.result.users;
				}
				if (ret.result.hasOwnProperty('hashTags')) {
					hashTagsFromDB = ret.result.hashTags;
				}
				this.setState({ 
					           foundUsers : usersFromDB, 
							   foundHashTags : hashTagsFromDB, 
							   characterInput : false, 
							   buttonDisabler : true,
							   magnifier : false
							 });
				if (!this.state.defaultUsers) {
					this.setState({ defaultUsers : usersFromDB });
				}
				if (!this.state.defaultHashTags) {
					this.setState({ defaultHashTags : hashTagsFromDB });
				}
			}
		}
	}

	getSubMode(searchTerm) {
		let subMode;

		if ((searchTerm === '*') || (searchTerm === '')) {
			subMode = 'all';
		}
		else if (searchTerm.startsWith('#')) {
			subMode = 'hashtags';
		}
		else {
			subMode = 'users';
		}
		return subMode;
	}

	getUsers(loginUser, characterInput, foundUsers, click, pfClassNames) {
		let users = foundUsers;

		if (!users) {
			return null;
		}
		if (characterInput) {
			users = this.applyUsersFilter(users);
		}
		return this.renderFoundUsers(users, loginUser, click, pfClassNames);
	}

	renderFoundUsers(users, loginUser, click, pfClassNames) {
		let searchCardClassNme = "mb-0 " + pfClassNames.username;

		if (users && (users.length > 0)) {
			return (
				<div className="search-results" onTouchMove={ this.onTouchHandler }>
					{          
						users.map(personIFollow => ( 
							<div align="start" key={ personIFollow.userId }>
								<Card className="mb-1 search-card ">                               
									<Link to={{ pathname: '/User', params: { personIFollow, loginUser} }}>
										<Row className={ pfClassNames.sugested } body-scroll-lock-ignore="true" >                           
											{
												personIFollow.hasOwnProperty('userPicture') 
												? <div className={getUserPictureClassName(personIFollow)}><img src= { personIFollow.userPicture}  alt="Avatar" /></div>
												: <div className={getUserPictureClassName(personIFollow)}><img src={ Const.DEFAULT_USER_PICTURE } alt="Avatar" /></div>
											}
																			
											<div className="info-f" body-scroll-lock-ignore="true">
												<p className={ searchCardClassNme } body-scroll-lock-ignore="true"> { personIFollow.userName} </p>
												<p className="medium mb-0 tagline" body-scroll-lock-ignore="true"> {personIFollow.userTagline} </p>
											</div> 
										</Row>
									</Link>       							
								</Card>   
							</div>  
						))
					} 
				</div>
			)
		}
		else if (click) {
			return ( 
			        <div className="center" >
				       <p className="notfound">{ this.RECORD_NOT_FOUND_MSG }</p>
			        </div>
			       )
		}
		else {
			return null;
		}
	}

	renderHashTags(foundHashTags) {
		let filteredHashTags = this.applyHashTagsFilter(foundHashTags);
		let searchOptionsContainerClass = "search-options-container"

		if (filteredHashTags.length > 0) {
			if (isDesktop()) {
				searchOptionsContainerClass += " search-options-container-max-height";
			}
			return (
				<div className="center" onTouchMove={ this.onTouchHandler }>
					<CustomDropdown  
					            options={ filteredHashTags } 
									 onClick={this.handleDropdownSelection}
									 onTouchMove={ this.onTouchHandler }
									 customDropdownClass="search-dropdown"
									 optionsContainerClass={ searchOptionsContainerClass }
									 optionWrapperClass="search-option-wrapper"
									 optionClass="search-option"
					/>
				</div>
			)
		}
		else {
			return null;
		}
	}

	handleDropdownSelection(option) {
		this.setState({ 
			           hashTagSearch : true, 
					   searchTerm : option,  
					   buttonDisabler : false,
					   showHashTagsDropDown : false
					 });
		this.onClickHandlerLogic(option);
	}

	replaceHashTagPostsWithTopAndUserPosts(hashTagPosts) {
		let loginUser = this.state.loginUser;
		let topPosts = loginUser.topPosts;
		let userPosts = loginUser.userPosts;
		let postIdToPostsMap = {};
		let post;
	  
		// Create a map of postId to corresponding topPosts and userPosts
		topPosts.forEach(post => {
		  postIdToPostsMap[post.postId] = post;
		});
		userPosts.forEach(post => {
		  postIdToPostsMap[post.postId] = post;
		});
		// Replace elements in hashTagPosts with corresponding posts
		const replacedPosts = hashTagPosts.map(hashTagPost => {
		  post = postIdToPostsMap[hashTagPost.postId];
		  post = post ? post : hashTagPost;
		  post.userId = hashTagPost.userId; // because user post don't have user id
		  return post;
		});
		return replacedPosts;
	}

	getSearchInput(searchTerm, buttonDisabler, magnifier, returnTo, anyUser, loginUser, showX) {
		return (
			<div className="search-input-container">
				{ 
					<div className="search-center-parent">	
					<Row>
						<div className="search-center">	
							<Input id='inp'
									autoComplete="off"
									type="text"
									name='searchu-h'
									value= { searchTerm }
									onChange={ this.onChangeHandler } 
									onFocus={ this.onFocusHandler } 
									onBlur={ this.onBlurHandler } 
									onKeyPress={this.handleKeyPress}
									placeholder={this.DEFAULT_SEARCH_TEXT}
									className="shadow-none" // preventing border shadow on focus
							/>
							{ 
						     this.getInputBoxButton(buttonDisabler, magnifier, returnTo, anyUser, loginUser)
						    }

							{ 
						     this.getX(showX)
						    }
						</div>
						</Row>	
					</div>
				}
			</div>
		)
	}

	getInputBoxButton(buttonDisabler, magnifier, returnTo, anyUser, loginUser) {
		if (buttonDisabler) {
			if (magnifier) {
				return this.getMagnifier(buttonDisabler);
			}
			else {
				return this.getBackArrow(returnTo, anyUser, loginUser);
			}
		}
		else {
			return this.getMagnifier(buttonDisabler);
		}
	}

	getMagnifier(buttonDisabler) {
		return (
			 <Button className="btn-primary btn-magnifier-arrow" onClick={this.onClickHandler} disabled={buttonDisabler}>
				<RiIcons.RiSearchLine className = "home-icons-medium" />
			</Button>		
		)
	}

	getBackArrow(returnTo, anyUser, loginUser) {
		if (returnTo === '/User') {
			return (
				<Link to={{ pathname: returnTo, params: { personIFollow : anyUser, loginUser } }}>
					<Button className="btn-primary btn-magnifier-arrow"  >
						<IoIcons.IoIosArrowBack className = "arrows" />
					</Button>
				</Link>
			)
		}
		else {
			return (
				<Link to={{ pathname: returnTo, params: { loginUser }}}>
					<Button className="btn-primary btn-magnifier-arrow"  >
						<IoIcons.IoIosArrowBack className = "arrows" />
					</Button>
				</Link>
			)
		}
	}

	getX(showX) {
		if (showX) {
			return (
				<div className="eraser-X" onClick={this.removeInputValue}>
					<MdIcons.MdClose id="X" className="eraser-img-X"  />
				</div>
			)
		}
		else {
			return null;
		}
	}

	removeInputValue(e) {
		let inp = document.getElementById('inp');

		e.preventDefault();
		if (inp) {
			inp.value = "";
			this.editUsersSearchTerm("");
		}
	}

	removeRestoreBackGroundImage(cond) {
		if (!isDesktop()) {
			if (cond) {
				document.body.style.backgroundImage = 'none'; // remove
			}
			else {
				document.body.style = ""; // restore
			}
		}
	}

	getNavOrSearchBoxOnTop(navParams, searchTerm, buttonDisabler, magnifier, returnTo, anyUser, loginUser, showX) {
		if (isDesktop()) {
			return (
				<div className="nav-display-desktop">
					<Navi navParams = { navParams } />
				</div>
			)
		}
		else {
			return this.getSearchInput(searchTerm, buttonDisabler, magnifier, returnTo, anyUser, loginUser, showX);
		}
	}
 
	async getHashTagPosts(searchTerm) {
		// we use this.searchTerm, this.loadMore, and this.hashTagPosts
		// to avoid sync issues with state vars : searchTerm, loadMore, and hashTagPosts
		this.loadMore = null;
		this.hashTagPosts = null;
		this.searchTerm = searchTerm; 
		this.setState({ 
			            loadMore : this.loadMore, 
			            hashTagPosts : this.hashTagPosts,
						searchTerm : this.searchTerm
					  });
		unsetPostsLoadinOrPostsEndOption(this, Const.END_OF_POSTS);
		await this.getPartialUserJson();
	}

	async getPartialUserJson() {
		let loginUser = this.state.loginUser;
		let parms = {
				user_id : loginUser.userId,
				hashtags_search : this.searchTerm,
				max_max_post_id : this.loadMore? this.loadMore.maxMaxPostId : 0,
				max_post_id : this.hashTagPosts && this.hashTagPosts.length ? this.hashTagPosts[this.hashTagPosts.length - 1].postId : 0,
				remaining_records_count : this.loadMore? this.loadMore.numberOfHashTagRecordsRemaining : 0
			};
		let result = await loadPartialJsonPosts(this, parms);

		this.processPartialTopPostsJson(result);
	}

	processPartialTopPostsJson(partialJson) { 
		let hashTagPosts = null;

		if (this.state.serviceErrorLevel === Const.NO_ERROR_LEVEL) {
			if (partialJson.hasOwnProperty('topPosts')) {
				this.processPartialTopPostsJsonLogic(partialJson);
				hashTagPosts = this.replaceHashTagPostsWithTopAndUserPosts(this.hashTagPosts);
			}
			
		}
		this.setState({ 
				hashTagPosts : hashTagPosts,
				hashTagSearch : true, 
				loadMore : this.loadMore, 
				isNextChunkOfPostsAdded : this.isNextChunkOfPostsAdded,
				showHashTagsDropDown : false,
				magnifier : false
			 });
	}

	processPartialTopPostsJsonLogic(partialJson) {
		if (partialJson.topPosts.length > 0) {
			if (partialJson.hasOwnProperty('loadMore')) {
				this.loadMore = partialJson.loadMore;
			}
			else {
				this.loadMore = null;
			}
			if (this.hashTagPosts) {
				this.hashTagPosts = this.hashTagPosts.concat(partialJson.topPosts);
			}
			else {
				this.hashTagPosts = partialJson.topPosts;
			}
			if (partialJson.loadMore.isFinalPage) {
				setPostsLoadinOrPostsEndOption(this, Const.END_OF_POSTS);
			}
		}
		else {
			// no posts exist with a given hashTag or some strange case
			this.hashTagPosts = [];
			setPostsLoadinOrPostsEndOption(this, Const.END_OF_POSTS); 
		}
		unsetPostsLoadinOrPostsEndOption(this, Const.POSTS_ARE_LOADING);
	}

	loadMorePostsIsNeeded() {
		let needToLoadMorePosts;

		if (this.loadMore && this.loadMore.isFinalPage) {
			needToLoadMorePosts = false;
		}
		else {
			needToLoadMorePosts = true;
		}
		return needToLoadMorePosts;
	}

	showLoadingImage() {
		if (this.loadMore && !this.loadMore.isFinalPage) {
			return loadingImage(this);
		}
		else {
			return null;
		}
	}

	onTouchStartHandler(e) {
		appTouchStartListener(e, this);
	}

	onTouchMoveHandler(e) {
		appTouchMoveListener(e);
	}

	onTouchEndtHandler(e) {
		appTouchEndListener(e);
	}

	render() {
		let { loginUser, foundUsers, characterInput, buttonDisabler, searchTerm, click, foundHashTags, 
			  hashTagSearch, showHashTagsDropDown, hashTagPosts, anyUser, returnTo, magnifier,
			  showX } = this.state;
		const navParams = { 
			loginUser : loginUser, 
			that : this
		}
		const localReturnTo = '/Search';
		let wrapContainerClass = "after-app-wrap-center-div";
		let pfClassNames = postsAndFollowersClassNames(wrapContainerClass);
		let searchContainerClass = pfClassNames.afterAppWrapCenterDiv + " search-container-add";

		if (!loginUser ) {
			return (
				<Redirect to={{ pathname: "/"}} />
			)
		}
		if ((this.state.serviceInvocationStatus ===  Const.SERVICE_IS_BEIGNG_INVOKED  && !this.loadMore)) {
			searchContainerClass +=  " search-container-sleepingcat";
		}
		else {
			searchContainerClass += " search-container";
		}
		showBodyScrollBar();
		storeState(SEARCH, this.state);
		loginUser.whereIam = Const.I_AM_IN_FIND_USERS;
		return (
			<>
				{
                  iOSstatusBarPadding()
                }
			    {
				  this.getNavOrSearchBoxOnTop(navParams, searchTerm, buttonDisabler, magnifier, returnTo, anyUser, loginUser, showX)
				}

				<div className={ searchContainerClass } >
					<div className="center-container-small" >
						{
						 isDesktop() && 
						 this.getSearchInput(searchTerm, buttonDisabler, magnifier, returnTo, anyUser, loginUser, showX)
					    }
					</div>
					{
						(this.state.serviceInvocationStatus ===  Const.SERVICE_IS_BEIGNG_INVOKED) && !this.loadMore &&
						<div className="search-results" >
							{ sleepingCatSmall(0) }
						</div>	
					}

                    {  
					  hashTagSearch && !showHashTagsDropDown && hashTagPosts && (hashTagPosts.length === 0) &&
					  <div className="center" >
					    <p className="notfound">{ this.RECORD_NOT_FOUND_MSG }</p>
				      </div>
					}

                    {
					   !hashTagSearch  && !showHashTagsDropDown && !hashTagPosts &&this.getUsers(loginUser, characterInput, foundUsers, click, pfClassNames)
					}		
				
					{  
					  hashTagSearch && showHashTagsDropDown && !hashTagPosts && this.renderHashTags(foundHashTags) 
					}

					{  
					  hashTagSearch && !showHashTagsDropDown && hashTagPosts && (hashTagPosts.length > 0) &&
					  getHashTagedPostsPage(localReturnTo, hashTagPosts, loginUser, anyUser, this)  
					}
				</div>
			</>
		);
	}
}