import Const from '../components/Const';

let mobilPostButtonClass = null;

export function isMobilDevice(that) {
  let	mobilPostButton = document.getElementById(Const.MOBIL_POST_BUTTON_ID);

  if (mobilPostButton && (mobilPostButton.clientHeight > 0)) {
    that.mobilDeviceDetected = true;
  }
}

export function isMobilDeviceDetected() {
  let	mobilPostButton = document.getElementById(Const.MOBIL_POST_BUTTON_ID);
  let ret = false;

  if (mobilPostButton && (mobilPostButton.clientHeight > 0)) {
    ret = true;
  }
  return ret;
}

export function removeMobilPostButton(that) {
  isMobilDevice(that);
  if (mobilPostButtonClass) {
    mobilPostButtonClass.setState({ showMobilButton : false });
  }
}

export function addMobilPostButton(that) {
  if (that.state.modal && that.mobilDeviceDetected) {
    // Post modal pop-up is closing
    if (that.timeoutID !== -1) {
      clearTimeout(that.timeoutID);
    }
    that.timeoutID = setTimeout(activateAddPostButton, Const.MOBIL_BUTTON_ACTIVATION_DELAY, that);
  }
}

export function activateAddPostButton(that) {
  mobilPostButtonClass.setState({ showMobilButton : true, showModalPopUp : false });
  clearTimeout(that.timeoutID);
  window.onresize = null;
}

export function storeMobilPostButtonClass(that) { 
  mobilPostButtonClass = that;
}

export function getMobilPostButtonClass() { 
  return mobilPostButtonClass;
}