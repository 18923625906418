import React from 'react';
import { Row, Col } from 'reactstrap';
import * as BiIcons from 'react-icons/bi';
import * as FiIcons from 'react-icons/fi'; 
import Navi from '../components/nav';
import Const from '../components/Const';
import { FormGroup } from 'reactstrap';
import { getUserJsonFromDBwithCookie, messageModalWindow, sleepingCat, invokeService, adjustFontSize,
	     setLocalCookieForOcaPreferences, koeffCalculator, isPhoneLayout  } from '../components/Utils.js';
import { DEFAULT, LIGHT, DARK, setThemePreference } from '../utils/ThemeUtils.js';
import  { getBaseFontSize, getFontSizeRange, setCssVarValue } from '../utils/CssUtils.js';
import { isDesktop } from '../utils/AppUtils.js';

export default class Preferences extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      modal: false,
      messageModal: false,
      loginUser : props.location.params ? this.props.location.params.loginUser : null,
      theme : null,
      latestPostsOnTop : null,
	  createPostWithAI : null,
	  fontSize : null,
	  currentFontSize : null,
	  koeff : null,
      //
      serviceInvocationStatus : Const.SERVICE_IS_NOT_INVOKED_YET,
      serviceStatusCode : Const.INIT_VALUE,
      serviceErrorLevel : Const.NO_ERROR_LEVEL,
      serviceInvocationError : false,
      flag : Const.INIT_VALUE
    }
	this.fontSizeRange = getFontSizeRange();
	this.MAX_FONT_SIZE = this.fontSizeRange.maxFontSize;
	this.MIN_FONT_SIZE = this.fontSizeRange.minFontSize;
	this.FONT_SIZE_DELTA = 1; 
	
	this.allowRadioButtonClick = true;
	this.allowFontSizeStoreInDB = true;
	this.storeFontSize = this.storeFontSize.bind(this);
  }

  async componentDidMount() {
    let loginUser = this.state.loginUser;
    let result;
    let theme;
    let latestPostsOnTop;
	let createPostWithAI;
	let fontSize;
	let currentFontSize;
  
    if (!loginUser) {
		result = await  getUserJsonFromDBwithCookie(this);
		if ((this.state.serviceErrorLevel === Const.NO_ERROR_LEVEL)) {
             loginUser = result;
		}
	}
    theme = loginUser.hasOwnProperty('theme') ? loginUser.theme : DEFAULT;
    latestPostsOnTop = loginUser.hasOwnProperty('latestPostsOnTop') ? loginUser.latestPostsOnTop : false;
    createPostWithAI = loginUser.hasOwnProperty('createPostWithAI') ? loginUser.createPostWithAI : false;
    fontSize = loginUser.hasOwnProperty('fontSize') ? loginUser.fontSize : 0;
    fontSize = adjustFontSize(this.fontSizeCalculator(fontSize));
    currentFontSize = loginUser.hasOwnProperty('currentFontSize') ? loginUser.currentFontSize : fontSize;
    this.setState(
		          { loginUser : loginUser, 
					theme : theme, 
					latestPostsOnTop : latestPostsOnTop, 
					createPostWithAI : createPostWithAI,
					fontSize : fontSize,
					currentFontSize : currentFontSize
				  });
  }

  fontSizeCalculator(fontSize) {
	let actualFontSize;

	if (fontSize === 0) {
	    actualFontSize = getBaseFontSize();
	}
	else {
		actualFontSize = fontSize;	
	}
	return actualFontSize;
  }

  async setThemeOnChange(themeMode) {
    let loginUser = this.state.loginUser;

	if (this.allowRadioButtonClick) {
		setThemePreference(themeMode);
		setLocalCookieForOcaPreferences(themeMode);
		loginUser.theme = themeMode;
		this.setState({ loginUser : loginUser, theme: themeMode });
		this.bodyEnableDesabler(true);
		await this.storeUserPreferencesInDB();
		this.bodyEnableDesabler(false);
	}
  }

  async showLatestPostsOnTop(cond) {
    let loginUser;

	if (this.allowRadioButtonClick) {
		loginUser = this.state.loginUser;
		loginUser.latestPostsOnTop = cond;
		this.setState({loginUser : loginUser, latestPostsOnTop :  cond});
		this.bodyEnableDesabler(true);
		await this.storeUserPreferencesInDB();
		this.bodyEnableDesabler(false);
	}
  }

  async startPostCreatingWithAI(cond) {
    let loginUser;

	if (this.allowRadioButtonClick) {
		loginUser = this.state.loginUser;
		loginUser.createPostWithAI = cond;
		this.setState({loginUser : loginUser, createPostWithAI :  cond});
		this.bodyEnableDesabler(true);
		await this.storeUserPreferencesInDB();
		this.bodyEnableDesabler(false);
	}
  }

  async storeUserPreferencesInDB() {
	let data = {
		mode : Const.STORE_USER_PREFERENCES,
		theme : this.state.loginUser.theme,
		latest_posts_on_top : this.state.loginUser.latestPostsOnTop ? 1 : 0,
		start_create_post_with_ai : this.state.loginUser.createPostWithAI ? 1 : 0,
		font_size : this.state.loginUser.fontSize
	};
		
	await invokeService(Const.OCA_SERVICES_URL, data, this);
  }

  bodyEnableDesabler(cond) {
	if (cond) {
		document.body.style.opacity = Const.REDUCED_OPACITY;
		this.allowRadioButtonClick = false;
		this.allowFontSizeStoreInDB = false;
	}
	else {
		document.body.style.opacity = Const.FULL_OPACITY;
		this.allowRadioButtonClick = true;
		this.allowFontSizeStoreInDB = true;
	}
  }

  handleFontSizeIncrease = (e) => {
	e.preventDefault();
	this.fontSizePorcessor(true);
  }

  handleFontSizeDecrease = (e) => {
	e.preventDefault();
	this.fontSizePorcessor(false);
  }

  fontSizePorcessor(cond) {
	let loginUser = this.state.loginUser;
	let currentFontSize = this.state.currentFontSize;
	// let koeff;
	let isfontSizeChanged = false;

	if (cond) {
		if (currentFontSize < this.MAX_FONT_SIZE) {
			currentFontSize += this.FONT_SIZE_DELTA;
			isfontSizeChanged = true;
		}
	}
	else {
		if (currentFontSize > this.MIN_FONT_SIZE) {
			currentFontSize -= this.FONT_SIZE_DELTA;
			isfontSizeChanged = true;
		}
	}
	if (isfontSizeChanged) {
		setCssVarValue('--koeff', koeffCalculator(currentFontSize));
		loginUser.currentFontSize = currentFontSize;
		this.setState({ loginUser : loginUser,  currentFontSize : currentFontSize });
	}
  }
  
  handleFontSizeReset = (e) => {
	let currentFontSize = getBaseFontSize();
	let loginUser = this.state.loginUser;

	e.preventDefault();
	setCssVarValue('--koeff', koeffCalculator(currentFontSize));
	loginUser.currentFontSize = currentFontSize;
	this.setState({loginUser : loginUser,  currentFontSize : currentFontSize });
  }

  async storeFontSize() {
    let loginUser = this.state.loginUser;
    let currentFontSize = this.state.currentFontSize;

	if (this.allowFontSizeStoreInDB) {
		loginUser.fontSize = currentFontSize;
		this.setState({ loginUser : loginUser, fontSize: currentFontSize });
		this.bodyEnableDesabler(true);
		await this.storeUserPreferencesInDB();
		this.bodyEnableDesabler(false);
	}
  } 

  render() {
    const { serviceErrorLevel, loginUser, header, text, theme, latestPostsOnTop, 
		    createPostWithAI, currentFontSize } = this.state;
 
	const fixedPosClassName = isDesktop() ? "posFixed " : "";
	const className = fixedPosClassName + "after-app-wrap-center-div-gotop fuulBgrnd";

    if (!loginUser || (theme === null) || (latestPostsOnTop === null)) {
			if (serviceErrorLevel !== Const.NO_ERROR_LEVEL) {
				return messageModalWindow(this, header, text);
			}
			else {
				return (
                <> 
                  {sleepingCat()}
                </>
               );
			}
		}
    else {
      loginUser.whereIam = Const.I_AM_IN_PREFERENCES;
      return (
        	<>
				<Navi loginUser = { loginUser } />			
				{
					loginUser &&	
					<div id="main" className={ className }>
						<FormGroup className="account-form">				
							<div className="radio-form">
								<h5 className="radio-title">Theme</h5>
								<div className="radio-container-parent">
									<div className="radio-container">
										<label className="form-control-radio">
											<input className="pointer-view"
													type="radio" 
													name="default theme" 
													checked={ theme === DEFAULT } 
													onChange={ () => this.setThemeOnChange(DEFAULT) }
											/>
											Default
										</label>

										<label className="form-control-radio">
											<input className="pointer-view"
													type="radio" 
													name="dark theme" 
													checked={ theme === DARK } 
													onChange={ () => this.setThemeOnChange(DARK) }
											/>
											Dark
										</label>

										<label className="form-control-radio">
											<input className="pointer-view"
													type="radio" 
													name="light theme" 
													checked={ theme === LIGHT } 
													onChange={ () => this.setThemeOnChange(LIGHT) }
											/>
											Light
										</label>
									</div>
								</div>

								{
									isPhoneLayout() &&
									<div>
										<h5 className="radio-title">Font Size</h5>
										<Row>
											<Col>
												<div >
												{ currentFontSize }
												</div>
											</Col>
											<Col>
												<div >
													<BiIcons.BiPlus
													className="pointer-view"
													onClick={ this.handleFontSizeIncrease }
													/>
												</div>
											</Col>
											<Col>
												<div >
													<BiIcons.BiMinus  
													className="pointer-view"
													onClick={ this.handleFontSizeDecrease }
													/>
												</div>
											</Col>
											<Col>
												<div >
													<BiIcons.BiReset  
													className="pointer-view"
													onClick={ this.handleFontSizeReset }
													/>
												</div>
											</Col>
											<Col>
												<div >
													<FiIcons.FiSave  
													className="pointer-view"
													onClick={ this.storeFontSize }
													/>
												</div>
											</Col>
										</Row>
									</div>
								}

								<h5 className="radio-title">Top Posts</h5>
								<div className="radio-container-parent">
									<div className="radio-container">
										<label className="form-control-radio">
											<input className="pointer-view"
													type="radio" 
													name="users posts" 
													checked={ !latestPostsOnTop } 
													onChange={ () => this.showLatestPostsOnTop(false) }
											/>
											From users I follow only
										</label>

										<label className="form-control-radio">
											<input className="pointer-view"
													type="radio" 
													name="latest posts" 
													checked={ latestPostsOnTop } 
													onChange={ () => this.showLatestPostsOnTop(true) }
											/>
											A few most recent first
										</label>
									</div>
								</div>

								<h5 className="radio-title">Create Post</h5>
								<div className="radio-container-parent">
									<div className="radio-container">
										<label className="form-control-radio">
											<input className="pointer-view"
													type="radio" 
													name="AI assistance" 
													checked={ createPostWithAI } 
													onChange={ () => this.startPostCreatingWithAI(true) }
											/>
											With AI at start
										</label>

										<label className="form-control-radio">
											<input className="pointer-view"
													type="radio" 
													name="my own post" 
													checked={ !createPostWithAI } 
													onChange={ () => this.startPostCreatingWithAI(false) }
											/>
											Starting on your own
										</label>
									</div>
								</div>
							</div>
						</FormGroup>	
					</div>				
				}				
        	</>
      );
    }
  }
}