import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import { MDBContainer, MDBModal, MDBModalBody } from 'mdbreact';
import * as MdIcons from 'react-icons/md';
import * as AiIcons from 'react-icons/ai';
import Const from '../Const';
import { invokeService, messageModalWindow, personImayFollow, processAsyncPostDeletion, 
	     getUserPictureClassName, replacePostInTopPosts, replacePostInAnyUserPosts,
		 toolTippy } from '../Utils.js';
import { modalPopUpScrollProcessor, ANY_USER } from '../ScrollUtils.js';

class GetLikes extends PureComponent {
	constructor(props) {
        super(props);
		const { getLikesParams }= this.props;
		const { clickedPost, viewOnly, loginUser, that, anyUser } = getLikesParams; 
		this.state = {
			modal: false,
			messageModal: false,
			clickedPost : clickedPost,
			loginUser : loginUser,
			viewOnly : (clickedPost.userId === loginUser.userId) ? true : viewOnly,
			parent : that,
			anyUser : anyUser, // needed only to process async post deletion
			header: '',
			text: '',
			HEART_OPACITY : Const.FULL_OPACITY
		};
		this.handleLike = this.handleLike.bind(this);
		this.allowClick = true;
		this.VIEW = 'view';
		this.LIKE = 'like';
		this.UNLIKE = 'unlike';
		this.overflowScroll = true;
	}

	toggle = () => {
		modalPopUpScrollProcessor(this);
		this.setState({
			modal: !this.state.modal
		});
	}

	async handleLike(e) {
		let lastLike;
		let heartId;
		let loginUser = this.state.loginUser;
		
        e.preventDefault();
		if (loginUser && !loginUser.testDrive) {
			heartId = e.currentTarget.id;
			if (heartId !== this.VIEW) {
				if (this.allowClick) {
					this.disableHeartIcon(true);
					await this.storeUserLikeInDB(heartId);
					if (this.state.serviceErrorLevel === Const.NO_ERROR_LEVEL) {
						if (heartId === this.LIKE) {
							lastLike = this.assembleLike();
							this.addToPostLikes(lastLike);
						}
						else {
							this.removeFromPostLikes();
						}
					}
					this.disableHeartIcon(false);
				}
			}
			else {
				this.toggle();
			}
		}
	}

	async storeUserLikeInDB(heartId) {
        let data = {
            mode : Const.STORE_USER_LIKE_MODE,
            post_id : this.state.clickedPost.postId,
			store_like : (heartId === this.LIKE ) ? true : false
        };
        
        await invokeService(Const.OCA_SERVICES_URL, data, this);
    }

	assembleLike() {
        let loginUser = this.state.loginUser;
		let clickedPost = this.state.clickedPost;
        let lastLike = {
          userName : loginUser.userName,
          userId : loginUser.userId,
          userPicture : this.state.loginUser.userPicture
        };
		let likes;

        // below is a "virtual" like id for mapping
        if (clickedPost.hasOwnProperty('likes')) {
            likes = clickedPost.likes;
            lastLike.likeId = likes[likes.length - 1].likesId + 1
        }
        else {
            lastLike.likeId = 1;
        }
        return lastLike;
    }

    addToPostLikes(lastLike) {
        let clikedPost = this.state.clickedPost;
        let parent = this.state.parent;
        let likes = [];

		if (clikedPost.hasOwnProperty('likes')) {
            likes = clikedPost.likes;
        }
		else {
			clikedPost.likes = likes;
		}
        likes.push(lastLike);
        clikedPost.likesCount++;
        this.setState({ clikedPost : clikedPost });
        parent.setState({ clikedPost : clikedPost });
		this.propogateLikedPost(clikedPost);
    }

	removeFromPostLikes() {
        let clikedPost = this.state.clickedPost;
        let parent = this.state.parent;

        clikedPost.likesCount--;
		delete clikedPost.likes;
        this.setState({ clikedPost : clikedPost });
        parent.setState({ clikedPost : clikedPost});
		this.propogateLikedPost(clikedPost);
    }

	propogateLikedPost(clikedPost) {
		let anyUser = this.state.anyUser;
        let loginUser = this.state.loginUser;
		let parent = this.state.parent;

		if (anyUser && (parent.className === ANY_USER)) {	
		  replacePostInTopPosts(clikedPost, loginUser);
		}	
		else if (anyUser) {
		  replacePostInAnyUserPosts(clikedPost, anyUser);
		}                
	}

	componentDidUpdate() {
		if (!this.state.messageModal && 
			((this.state.serviceStatusCode ===  Const.SYS_ERROR_STORING_USER_LIKE_NO_POST_FOUND) ||
		    (this.state.serviceStatusCode ===  Const.SYS_ERROR_DELETING_USER_LIKE_NO_POST_FOUND))) {
		  this.setState({ serviceStatusCode : Const.INIT_VALUE });
		  this.processAsyncPostDeletionError();
		}
    }

	processAsyncPostDeletionError() {
		let loginUser = this.state.loginUser;
		let clickedPost = this.state.clickedPost;
		let parent = this.state.parent;

        processAsyncPostDeletion(clickedPost, this);
	    this.setState({ loginUser : loginUser });
		parent.setState({ loginUser : loginUser });
	}

	didIalreadyLikeThisPost() {
		let ret = false;
		let clickedPost = this.state.clickedPost;
		let loginUser = this.state.loginUser;

		if (clickedPost.hasOwnProperty('likes')) {
			for (let like of clickedPost.likes) {
				if (loginUser.userName === like.userName) {
					ret = true;
					break;
				}
			}
		}
		return ret;
	}

	disableHeartIcon(disable) { 
        if (disable) {
			  this.setState({ HEART_OPACITY : Const.REDUCED_OPACITY });
              this.allowClick = false;
        }
        else {
				this.setState({ HEART_OPACITY : Const.FULL_OPACITY });
				this.allowClick = true;
        }
    }

	getLikeOption() {
		let option;

		if (this.didIalreadyLikeThisPost()) { 
			option = this.UNLIKE;
		} 
		else if (this.state.viewOnly) {
			option = this.VIEW;
		}
		else {
			option = this.LIKE;
		}
		return option;
	}

	getTheRightHeart() {
		let option = this.getLikeOption();

		if (option === this.LIKE) {
			return (
					<div id={ this.LIKE } className="left" onClick={this.handleLike} style={{opacity : this.state.HEART_OPACITY}}>
						{ toolTippy(AiIcons.AiOutlineHeart, "Like", "right", [Const.DELAY, 0], "home-icons-medium pointer-view", null) }
					</div>
			      )
		}
		else if (option === this.UNLIKE) { 
			return (
					<div id={ this.UNLIKE } className="left" onClick={this.handleLike} style={{opacity : this.state.HEART_OPACITY}}>
						{ toolTippy(AiIcons.AiFillHeart, "Unlike", "right", [Const.DELAY, 0], "home-icons-medium pointer-view", null) }
					</div>	
		           )
		} 
		else  {
			return (
					<div id={ this.VIEW } className="left" onClick={this.handleLike}>
						{ toolTippy(AiIcons.AiFillHeart, "Show Likes", "right", [Const.DELAY, 0], "home-icons-medium pointer-view", null) }
					</div>
			      )
		}
	}

   	render() {
		const { clickedPost, viewOnly, loginUser, modal } = this.state; 
		const likes = clickedPost.likes;

		return(
			<>
			{this.getTheRightHeart()}
			
			<div>              
				<p className="icon-count medium mb-0" > {clickedPost.likesCount} <span className="sams-fold"></span> </p>
			</div>
			{
				viewOnly && (clickedPost.likesCount > 0) && (clickedPost.likes) && modal &&
				<MDBContainer>
					<MDBModal className="likes" isOpen={this.state.modal} toggle={this.toggle} overflowScroll={this.overflowScroll}>
						<div id="header" className="modal-myheader"> 
							<MdIcons.MdClose id="X" className="modal-close" onClick={this.toggle} />
							<h6> Likes </h6>
						</div>
						<MDBModalBody className="scroll-likes">            
							{ 
								likes.map( like => 
									<React.Fragment key={like.userName}>
										<Link to={{ pathname: '/User', params: { personIFollow : personImayFollow(like.userName, like.userPicture), loginUser} }}>
											<Row className="suggested" > 
												<div className={getUserPictureClassName(like)}>
													<img src= {like.userPicture} alt="Avatar" />
												</div>
												<Col className="p-0 pl-3 center-vert-like">
													<p className="bold"> {like.userName} </p>
												</Col>
											</Row>
										</Link>
									</React.Fragment> 
								)
							}
						</MDBModalBody> 
					</MDBModal>
				</MDBContainer>
			}
			{
				messageModalWindow(this, this.state.header, this.state.text)
			}
        </>          
      );
    }
  }
  
export default GetLikes;