import { setThemePreference, DEFAULT } from '../utils/ThemeUtils.js';
import { getLocalCookieForOcaPreferencesSync } from '../components/Utils.js';

export const SECOND = 1000;
export const MINUTE = 60000;
export const HOUR = MINUTE * 60;
export const DAY = HOUR * 24;
export const WEEK = DAY * 7;
export const MONTH = WEEK * 4;
export const YEAR = MONTH * 12;


export function getCurrentTimeInMiliSec() {
  return new Date().getTime();
}

export async function setIOSCookie(key, value, expiration) {
}

export async function getIOSCookie(key) {
  return '';
}

export async function deleteIOSCookie(key) {
};

export function iOSstatusBarPadding() {
  // return null;
  return <div></div>;
}

export async function hideSplashScreen() {
}

export async function hideSplashScreenForApp() {
}

export async function isDeepLinkRequested() {
   return false;
}

export  function setThemeFromCookieSync() {
  let theme = getLocalCookieForOcaPreferencesSync();

  if (!theme) {
    theme = DEFAULT;
  }
  setThemePreference(theme);
}

export function setAppWasAlreadyInvoked(option) {
}

export function getAppWasAlreadyInvoked() {
  return false;
}