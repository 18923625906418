import React from 'react';
import * as FaIcons from 'react-icons/fa';
import * as IoIcons from 'react-icons/io';
import * as HiIcons from "react-icons/hi";

export const SidebarData = [

  {
    title: 'Home',
    path: '/home',
    icon: <IoIcons.IoIosPaper />,
    cName: 'nav-text'
  },
  // {
  //   title: 'Profile',
  //   path: '/Profile',
  //   icon: <IoIcons.IoIosPaper />,
  //   cName: 'nav-text'
  // },
  {
    title: 'Groups',
    path: '/groups-withleftnav',
    icon: <HiIcons.HiOutlineUserGroup />,
    cName: 'nav-text'
  },
  {
    title: 'Business',
    path: '/Products',
    icon: <FaIcons.FaCartPlus />,
    cName: 'nav-text'
  },
 
  {
    title: 'Bookmarks',
    path: '/Posts',
    icon: <FaIcons.FaEnvelopeOpenText />,
    cName: 'nav-text'
  },
  {
    title: 'Support',
    path: '/support',
    icon: <IoIcons.IoMdHelpCircle />,
    cName: 'nav-text'
  }
];
